import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { Component, createRef } from 'react'
import LoadingComponent from '../../loadingComponent';
import { DeleteIconClasset, FileAttachmentIcon, MailIcon } from '../../svgIcons';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import ClassetInputText from '../../../classetComponents/classetInputText';
import FileUpload from '../../fileUpload';
import Authentication from '../../session';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { cloneDeep } from 'lodash';
const createUserFields = require('./newCreateUser.json');
class newCreateUser extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(createUserFields);
    this.state = {
      // user: this.formFields.data || this.props.editUser,
      user: {},
      // formValidations: this.formFields.formValidations,
      uploadedFileName: null,
      uploadedFilesInfo: [],
      boards: [],
      classes: [],
      rolesOptions: [],
      academicYears: [],
      groupBranchesOptions: [],
      branchesOptions: [],
      boardsOptions: [],
      boardsForAssignOptions: [],
      // checked: this.props?.editUser?._id ? this.props?.editUser?.user_info?.allAcademicYears : false,
      checked: true,
      assignedSubjects: [
        {
          boardId: '',
          classId: '',
          subjectId: '',
          boardName: '',
          className: '',
          subjectName: '',
          gradeOptions: '',
          subjectOptions: ''
        }
      ],
      userTypeOptions: [
        { label: 'ACADEMIC', value: 'academic' },
        { label: 'NON-ACADEMIC', value: 'non-academic' }
      ],
      sections: [
        { value: '21759c65-7c60-4745-bdbc-bcb47a65cf9c', label: 'Testing Section' },
        { value: '21759c65-7c60-4745-bdbc-bcb47a65cf11', label: 'Testing Section 1' },
        { value: '21759c65-7c60-4745-bdbc-bcb47a65cf22', label: 'Testing Section 2' },
        { value: '21759c65-7c60-4745-bdbc-bcb47a65cf33', label: 'Testing Section 3' }
      ],
      isShowCreateSuccess: false,
      hideUpdateButton: false,
      sectionsData: {},
      religionData: [
        { label: 'Hindu', value: 'hindhu' },
        { label: 'Muslim', value: 'muslim' },
        { label: 'Christian', value: 'christian' },
        { label: 'Others', value: 'othera' }
      ],
      casteData: [
        { label: 'General', value: 'general' },
        { label: 'BC-A', value: 'bc-a' },
        { label: 'BC-B', value: 'bc-b' },
        { label: 'BC-C', value: 'bc-c' },
        { label: 'BC-D', value: 'bc-d' },
        { label: 'BC-E', value: 'bc-e' },
        { label: 'SC', value: 'sc' },
        { label: 'ST', value: 'st' }
      ],
      genderData: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' }
      ],
      qualificationData: [
        { label: 'Matriculate', value: 'matriculate' },
        { label: 'Graduate', value: 'graduate' },
        { label: 'Post Graduate', value: 'postGraduate' },
        { label: 'Doctorate', value: 'doctorate' },
        { label: 'Others', value: 'others' }
      ],
      classStudyingDrp: [
        { label: 'I', value: 'i' },
        { label: 'II', value: 'ii' },
        { label: 'III', value: 'iii' },
        { label: 'IV', value: 'iv' },
        { label: 'V', value: 'v' },
        { label: 'VI', value: 'vi' },
        { label: 'VII', value: 'vii' },
        { label: 'VIII', value: 'viii' },
        { label: 'IX', value: 'ix' },
        { label: 'X', value: 'x' },
        { label: 'XI', value: 'xi' },
        { label: 'XII', value: 'xii' }
      ],
      siblings: [{ name: '', gender: '', classStudying: '', schoolStudying: '' }],
      prevAcadamicDetails: [{ name: '', classStudied: '', schoolStudied: '', percentageOrGpa: '' }]
    };
    this.toast = createRef();
    // this.onUpload = this.onUpload.bind(this);
    // this.onSelect = this.onSelect.bind(this);
    this.service = new Service();
  }
  async componentDidMount() {
    // console.log(this.props);
    this.getRolesOptions();
    this.getBranchData();
    if (this.state.checked && !this.props.editUser._id) {
      this.getAcadmicYears();
      this.getGroupsData();
    }
    if (this.props?.editUser?._id) {
      this.setState({ isLoading: true });
      // this.getBranchData();
      // this.getBoardsBasedonBranches();
      const { user, formValidations } = this.state;
      const nameuser = this.props.editUser?.user_info?.name.split(' ');
      const filterData = {
        rolename: this.props.editUser?.user_info.roles[0] || '',
        branchGroup: this.props.editUser?.user_info?.branchgroups || [],
        branches: this.props.editUser?.user_info?.branchs || [],
        boards: this.props.editUser?.user_info?.boardId || [],
        userNameOrId: this.props.editUser?.user_info?.username || '',
        firstName: nameuser[0] || '',
        lastName: nameuser[1] || '',
        userType: this.props.editUser?.user_info?.userType || '',
        email: this.props.editUser?.user_info?.email || '',
        phone_number: this.props.editUser?.user_info?.phone_number.slice(3) || '',
        address: this.props.editUser?.user_info?.address || '',
        selectedAcademicYears: this.props.editUser?.user_info?.assignedAcademicYears || []
      };

      const filterAssignObjects = [];
      for (let i = 0; i < this.props.editUser?.user_info?.assignedSubjects?.length; i++) {
        const each = this.props.editUser.user_info.assignedSubjects[i];
        const _classes = [];
        const selectedBoard = this.props.boards?.find((board) => board.boardId === each?.boardId);
        if (selectedBoard && selectedBoard.classes?.length > 0) {
          selectedBoard.classes.forEach((item) =>
            _classes.push({
              label: item.className,
              value: item.classId,
              groupName: item?.groups?.[0]?.groupName || '',
              groupId: item?.groups?.[0]?.groupId || ''
            })
          );
        }
        const subjOptions = await this.getCurriculumData(i, 'fromEdit');

        filterAssignObjects.push({ ...each, gradeOptions: _classes, subjectOptions: subjOptions });
      }

      const filterboards = this.props.boards.filter((each) => {
        if (this.props.editUser?.user_info?.boardId?.includes(each.boardId)) {
          return each;
        }
      });
      this.setState({ isLoading: false, checked: this.props.editUser?.allAcademicYears, nonAcademic: this.props.editUser?.user_info?.userType === 'non-academic' ? true : false, tmpUser: filterData, user: { ...user, rolename: filterData.rolename, firstName: filterData.firstName, lastName: filterData.lastName, userType: filterData.userType, email: filterData.email, phone_number: filterData.phone_number, address: filterData.address, userNameOrId: filterData.userNameOrId }, assignedSubjects: filterAssignObjects, boardsForAssignOptions: filterboards, isLoading: false },
        () => this.getAcadmicYears()
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // if (this.props?.editUser?._id && prevState.user?.rolename !== this.state.user?.rolename) {
    //   this.getGroupsData();
    // }
  }
  onCreateUser = async () => {
    const { user, assignedSubjects } = this.state;
    let lastBoard = assignedSubjects.length - 1;
    let body = cloneDeep(user);
    const filterAssignObjects = this.state?.assignedSubjects?.map((each) => {
      return {
        subjectCode: each?.subjectCode,
        boardId: each?.boardId,
        classId: each?.classId,
        groupId: each?.groupId,
        subjectName: each?.subjectName,
        subjectId: each?.subjectId,
        boardName: each?.boardName,
        className: each?.className,
        groupName: each?.groupName,
      };
    });
    // const formStatus = isFormValid(createUserFields, this.formFields.formValidations, body);
    // let isCompleteFormValid = true;
    // if (!formStatus.formValidations.isFormValid) {
    // this.setState({ formValidations: formStatus.formValidations, dateError: true });
    // isCompleteFormValid = false;
    // }
    if (!this.state.nonAcademic) {
      if (
        assignedSubjects[lastBoard].boardId.length === 0 ||
        assignedSubjects[lastBoard].classId.length === 0 ||
        assignedSubjects[lastBoard].subjectId.length === 0 ||
        assignedSubjects[lastBoard].boardName.length === 0 ||
        assignedSubjects[lastBoard].className.length === 0 ||
        assignedSubjects[lastBoard].subjectName.length === 0 ||
        assignedSubjects[lastBoard].gradeOptions.length === 0 ||
        assignedSubjects[lastBoard].subjectOptions.length === 0
      ) {
        return this.toast.show({ severity: 'error', summary: 'Please Fill The Details' });
      }
    } else {
      if (
        assignedSubjects[lastBoard].boardId.length === 0 ||
        assignedSubjects[lastBoard].classId.length === 0 ||
        assignedSubjects[lastBoard].subjectId.length === 0 ||
        assignedSubjects[lastBoard].boardName.length === 0 ||
        assignedSubjects[lastBoard].className.length === 0 ||
        assignedSubjects[lastBoard].subjectName.length === 0 ||
        assignedSubjects[lastBoard].gradeOptions.length === 0 ||
        assignedSubjects[lastBoard].subjectOptions.length === 0
      ) filterAssignObjects.length = 0;
    }
    // if (this.state.groupBranchesOptions.length === 0) {
    //     return this.toast.show({ severity: 'error', summary: 'Please Select Academic Years' });
    // }
    // if (isCompleteFormValid) {
    if (user?.rolename?.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Select Role Name' });
    if (user?.branches.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Select Branches' });
    if (user?.boards.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Select Boards' });
    if (user?.userType.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Select User Type' });
    if (user?.userNameOrId?.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Enter User Name Or Id' });
    if (user?.firstName.length === 0) return this.toast.show({ severity: 'error', summary: 'Please Enter First Name' });
    if (user?.lastName.length === 0) return this.toast.show({ severity: 'error', summary: ' Please Enter Last Name' });
    if (user?.phone_number.length === 0 || user?.phone_number.length < 10 || user?.phone_number.length < 10) return this.toast.show({ severity: 'error', summary: 'Please Enter Valid Phone Number' });
    if (user?.email.length === 0) return this.toast.show({ severity: 'error', summary: ' Please Enter Email' });
    if (!this.props?.editUser?._id && user?.password.length === 0) return this.toast.show({ severity: 'error', summary: ' Please Enter Password' });
    if (!this.props?.editUser?._id && user?.confirmPassword?.length === 0) return this.toast.show({ severity: 'error', summary: ' Please Enter Confirm Password' });
    if (!this.props?.editUser?._id && (user?.confirmPassword?.length > 0 && user?.confirmPassword !== user?.password)) return this.toast.show({ severity: 'error', summary: ' Confirm Password Does Not Match With Password' });
    if (!this.props?.editUser?._id && (user?.confirmPassword?.length > 0 && user?.confirmPassword.length < 8)) return this.toast.show({ severity: 'error', summary: ' Confirm Password Should Be At Least 8 Characters' });
    if (!this.state.checked && user?.selectedAcademicYears?.length == 0) return this.toast.show({ severity: 'error', summary: 'Please Select Academic Years' });
    if (!user?.studnetPhoto || !Object.keys(user?.studnetPhoto).length === 0) return this.toast.show({ severity: 'error', summary: 'Please Upload Student Photo' });
    if (this.props.editUser?._id) {
      const payload = {
        _id: this.props.editUser?._id,
        roles: user?.rolename,
        username: user?.userNameOrId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        userType: user?.userType,
        branchgroups: user?.branchGroup,
        branchs: user?.branches,
        email: user?.email,
        address: user?.address,
        phone_number: `+91${user?.phone_number}`,
        boardId: user?.boards,
        studentPhoto: user?.studnetPhoto,
        assignedSubjects: filterAssignObjects,
        allAcademicYears: this.state.checked,
        assignedAcademicYears: user?.selectedAcademicYears || []
      };

      let url = `${baseUrlAdmin}/admin/updateAdminUser`;
      this.service
        .put(url, payload, true)
        .then((res) => {
          console.log(res, 'res');
          if (res.res?.status == 200 && res?.status) {
            this.setState({
              user: {},
              assignedSubjects: {
                boardId: '',
                classId: '',
                subjectId: '',
                boardName: '',
                className: '',
                subjectName: '',
                gradeOptions: '',
                subjectOptions: ''
              }
            });
            this.props.onTabChange(2, 'Edit');
            this.toast.show({ severity: 'success', summary: 'User has been updated succesfully', detail: '', life: 3000 });
          } else {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
          }
        })
        .catch((e) => {
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
        });
    } else {
      const payload = {
        roles: user?.rolename,
        username: user?.userNameOrId,
        password: user?.password,
        firstName: user?.firstName,
        lastName: user?.lastName,
        userType: user?.userType,
        branchgroups: user?.branchGroup,
        branchs: user?.branches,
        email: user?.email,
        address: user?.address,
        phone_number: `+91${user?.phone_number}`,
        boardId: user?.boards,
        studentPhoto: user?.studnetPhoto,
        assignedSubjects: filterAssignObjects || [],
        allAcademicYears: this.state.checked,
        assignedAcademicYears: user?.selectedAcademicYears || []
      };
      console.log(user, 'useruseruser', payload);
      let url = `${baseUrlAdmin}/admin/addAdminUser`;
      this.service
        .post(url, payload, true)
        .then((res) => {
          if (res.status && res?.res?.success) {
            this.setState({
              user: {},
              assignedSubjects: {
                boardId: '',
                classId: '',
                subjectId: '',
                boardName: '',
                className: '',
                subjectName: '',
                gradeOptions: '',
                subjectOptions: ''
              }
            });
            this.props.onTabChange(2, 'Create');
            this.toast.show({ severity: 'success', summary: 'User has been created succesfully', detail: '', life: 3000 });
          } else {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
          }
        })
        .catch((e) => {
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }
    // }
  };
  addBoards = () => {
    const { assignedSubjects } = this.state;
    let lastBoard = assignedSubjects.length - 1;
    if (
      assignedSubjects[lastBoard]?.boardId?.length === 0 ||
      assignedSubjects[lastBoard]?.classId?.length === 0 ||
      assignedSubjects[lastBoard]?.subjectId?.length === 0 ||
      assignedSubjects[lastBoard]?.boardName?.length === 0 ||
      assignedSubjects[lastBoard]?.className?.length === 0 ||
      assignedSubjects[lastBoard]?.subjectName?.length === 0 ||
      assignedSubjects[lastBoard]?.gradeOptions?.length === 0 ||
      assignedSubjects[lastBoard]?.subjectOptions?.length === 0
    ) {
      return this.toast.show({ severity: 'error', summary: 'Please Fill The Details' });
    }
    const newBoard = {
      boardId: '',
      classId: '',
      subjectId: '',
      boardName: '',
      className: '',
      subjectName: '',
      gradeOptions: '',
      subjectOptions: ''
    };
    this.setState((prevState) => ({
      assignedSubjects: [...prevState.assignedSubjects, newBoard]
    }));
  };
  removeBoards = (i) => {
    if (this.state.assignedSubjects.length > 1) {
      const filterAssignObjs = this.state.assignedSubjects.filter((each, index) => index != i);
      this.setState((prevState) => ({
        assignedSubjects: [...filterAssignObjs]
      }));
    }
  };

  _onProgress = (filesInfo) => { };
  _onFilsUploadError = (errMsg) => {
    this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
  };
  _onPhotoSuccess = (fileName, data) => {
    this.addAttachment('studnetPhoto', fileName, data);
  };
  _afterFilesUploaded = (files) => { };
  onUpload() {
    this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  }
  addAttachment = (fieldName, fileName, data) => {
    console.log(fileName)
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [fieldName]: {
          fileName,
          storageBucket: data.Bucket,
          storageKey: data.Key,
          storageLocation: data.Location
        }
      }
    }));
  };
  getAcadmicYears = async () => {
    console.log(this.state.branchesOptions)
    let url = `${baseUrlAdmin}/academic-years/`;
    this.setState({ isLoading: true })
    return await new Service()
      .get(url, true)
      .then((res) => {
        this.setState({
          academicYears: res.res?.data || [],
          isLoading: false
        }, () => {
          if (this.props?.editUser?._id) {
            if (this.state?.tmpUser?.selectedAcademicYears.length > 0) this.onChangeAcademicYear(this.state.tmpUser?.selectedAcademicYears, false)
            else this.onChangeCheckBox(true, false)
          }
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false })
        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
        console.log(e);
      });
  };
  getGroupsData = async (clicked) => {
    let url = '';
    let payload = {};
    // if (!this.state.checked && this.state.user?.selectedAcademicYears) {
    //     this.setState({ branchesOptions: [] });
    //     return;
    // }
    //EDIT ONE
    this.setState({ isLoading: true })
    if (this.props.editUser?.user_info?.assignedAcademicYears) {
      if (!clicked) {
        if (this.props.editUser?.user_info?.allAcademicYears) url = `${baseUrlAdmin}/group/noacademic`;
        else {
          url = `${baseUrlAdmin}/group/selectedAcademicYears`;
          payload = { years: this.props.editUser.user_info.assignedAcademicYears };
        }
      } else {
        if (this.state.checked) {
          url = `${baseUrlAdmin}/group/noacademic`;
        } else if (this.state.user.selectedAcademicYears?.length) {
          url = `${baseUrlAdmin}/group/selectedAcademicYears`;
          payload = { years: this.state.user.selectedAcademicYears };
        } else {
          this.setState({ isLoading: false, groupBranchesOptions: [], user: { ...this.state.user, boards: [], branches: [], branchGroup: [] } });
          return;
        }
      }
    } else {
      if (this.state.checked) {
        url = `${baseUrlAdmin}/group/noacademic`;
      } else if (this.state.user.selectedAcademicYears?.length) {
        url = `${baseUrlAdmin}/group/selectedAcademicYears`;
        payload = { years: this.state.user.selectedAcademicYears };
      } else {
        this.setState({ isLoading: false, groupBranchesOptions: [], user: { ...this.state.user, boards: [], branches: [], branchGroup: [] } });
        return;
      }
    }
    try {
      let res;
      if (this.props.editUser?.user_info?.assignedAcademicYears) {
        if (!clicked) res = this.props.editUser?.user_info?.allAcademicYears ? await this.service.get(url, true) : await this.service.post(url, payload, true);
        else {
          res = this.state.checked
            ? await this.service.get(url, true)
            : await this.service.post(url, payload, true);
        }
      } else {
        res = this.state.checked
          ? await this.service.get(url, true)
          : await this.service.post(url, payload, true);
      }
      if (res?.res?.status && res.status) {

        this.setState({
          groupBranchesOptions: res.res.data,
          isLoading: false,
          user: { ...this.state.user, boards: [], branches: [], branchGroup: [] },
        }, () => {
          if (!clicked) {
            if (this.props?.editUser?.user_info?.branchgroups.length > 0) this.onChangeBranchGroup(this.state?.tmpUser?.branchGroup, false);
            else this.getBranchData();
          }
          else {
            this.getBranchData(clicked)
          }
        });
      } else {
        this.setState({ isLoading: false })
        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
      }
    } catch (e) {
      this.setState({ isLoading: false })
      this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
    }
  };
  getBranchData = async (clicked) => {
    let url = `${baseUrlAdmin}/branch/selectedAcademicYears`;
    let payload = {};
    if (this.props.editUser._id) {
      if (!clicked) {
        if (this.props.editUser?.user_info?.allAcademicYears) {
          payload = {};
        } else if (this.props.editUser?.user_info?.assignedAcademicYears) {
          payload = { academicYears: this.props.editUser.user_info.assignedAcademicYears };
        }
      } else {
        if (this.state.checked) {
          payload = {};
        } else if (this.state.user.selectedAcademicYears?.length) {
          payload = { academicYears: this.state.user.selectedAcademicYears };
        } else {
          this.setState({ branchesOptions: [], defaultBranches: [], user: { ...this.state.user, boards: [], branches: [] } });
          return;
        }
      }
    } else {
      if (this.state.checked) {
        payload = {};
      } else if (this.state.user.selectedAcademicYears?.length) {
        payload = { academicYears: this.state.user.selectedAcademicYears };
      } else {
        this.setState({ branchesOptions: [], defaultBranches: [], user: { ...this.state.user, boards: [], branches: [] } });
        return;
      }
    }
    this.setState({ isLoading: true })
    try {
      const res = await this.service.post(url, payload, true);
      if (res?.res?.status && res.status) {
        this.setState({
          branchesOptions: res.res.data,
          defaultBranches: res.res.data,
          user: { ...this.state.user, boards: [], branches: [] },
          isLoading: false,
        },
          () => { if (this.props?.editUser?._id && !clicked) this.onChangeBranches(this.state.tmpUser?.branches, false) }
        );
      } else {
        this.setState({ isLoading: false })
        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
      }
    } catch (e) {
      this.setState({ isLoading: false })
      this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
    }
  };
  getRolesOptions = async () => {
    const academicYear = localStorage.getItem('userAcademicYear');
    let url = `${baseUrlAdmin}/authorize/getRoles?limit=${10000}&page=${1}`;
    this.setState({ isLoading: true })
    this.service
      .get(url, true)
      .then((res) => {
        if (res && res?.res) {
          this.setState({
            rolesOptions: res.res?.data,
            isLoading: false
          });
        } else {
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        }
      })
      .catch((e) => {
        this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
      });
  };
  getBranchesBasedonGroups = async (selectedGroups, clicked) => {
    let { groupBranchesOptions, branchesOptions } = this.state;
    if (selectedGroups?.length > 0) {
      const filteredBranches = [];

      for (const groupId of selectedGroups) {
        const group = groupBranchesOptions.find((group) => group._id === groupId);
        if (group) {
          const branchesInGroup = group.branches;
          const filtered = branchesOptions.filter((branch) => branchesInGroup.includes(branch.key));
          filteredBranches.push(...filtered);
        }
      }
      const uniqueBranches = Array.from(new Set(filteredBranches.map((branch) => branch.key))).map((key) => filteredBranches.find((branch) => branch.key === key));

      this.setState({
        branchesOptions: uniqueBranches,
      }, () => { if (this?.props?.editUser?._id && !clicked) this.onChangeBranches(this.state?.tmpUser?.branches, false); else this.setBranches(uniqueBranches, clicked); });
    } else {
      this.setState({
        branchesOptions: this.state.defaultBranches
      }, () => { if (this?.props?.editUser?._id && !clicked) this.onChangeBranches(this.state?.tmpUser?.branches, false) });
    }
  };
  setBranches = (branches, clicked) => {
    this.setState({
      user: { ...this.state.user, boards: [], }
    },
      () => { if (this?.props?.editUser?._id && !clicked) this.onChangeBranches(this.state?.tmpUser?.branches) }
    );
  }
  getBoardsBasedonBranches = async (selectedBranches, clicked) => {
    if (selectedBranches?.length) {
      let url = `${baseUrlAdmin}/organization/getboards`;
      let payload = {
        branchIds: selectedBranches
      };
      try {
        const res = await this.service.post(url, payload, true);
        if (res?.res?.status && res.status) {
          this.setState({ boardsOptions: res?.res?.data, user: { ...this.state.user, boards: [] }, isLoading: false },
            () => { if (this?.props?.editUser?._id && !clicked) this.onChangeBoard(this.state?.tmpUser?.boards, clicked) }
          );
        } else {
          this.setState({ isLoading: false })
          this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
        }
      } catch (e) {
        this.setState({ isLoading: false })
        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
      }
    } else {
      this.setState({ boardsOptions: [], isLoading: false });
    }
  };
  onSelectingBoards = (selectedBoards) => {
    const filterboards = this.props?.boards.filter((each) => {
      if (selectedBoards?.includes(each.boardId)) {
        return each;
      }
    });
    this.setState({ boardsForAssignOptions: filterboards }, () => this.updateAssignSubjectsByBoards(filterboards));
  }
  updateAssignSubjectsByBoards = (boards) => {
    const { assignedSubjects } = this.state;
    const filteredData = assignedSubjects.filter((each) => boards.some((board) => board.boardId === each.boardId));
    if (filteredData.length > 0) {
      this.setState({
        assignedSubjects: filteredData
      });
    } else {
      this.setState({
        assignedSubjects: [
          {
            boardId: '',
            classId: '',
            subjectId: '',
            boardName: '',
            className: '',
            subjectName: '',
            gradeOptions: '',
            subjectOptions: ''
          }
        ]
      });
    }
  };
  onChangeBoards = (boardId, indexPosition) => {
    const _classes = [];
    const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
    if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
      selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupName: item?.groups ? item?.groups[0]?.groupName : '', groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
    }
    let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId)?.boardName;

    const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
      if (indexPosition == index) {
        return { ...each, boardId: boardId, boardName: selectedBoardName, gradeOptions: _classes };
      } else {
        return each;
      }
    });

    this.setState({
      assignedSubjects: filterAssignObjects
    });
  };
  onChangeSubject = (subjectId, indexPosition) => {
    let existingSubjectCheck = this.state.assignedSubjects.filter(el => el.subjectId == subjectId)

    if (existingSubjectCheck.length) {
      this.toast.show({ severity: 'error', summary: 'Subject already exists', detail: '', life: 3000 });
      return
    }
    let selectedSubject = this.state.assignedSubjects[indexPosition]?.subjectOptions.subjects?.find((classd) => classd.subjectId == subjectId);
    const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
      if (indexPosition == index) {
        let subCode = each?.subjectOptions?.subjects?.find(el => el.subjectId == subjectId)?.subjectCode;

        return { ...each, subjectId: subjectId, subjectName: selectedSubject?.subjectName, subjectCode: subCode };
      } else {
        return each;
      }
    });
    this.setState({
      assignedSubjects: filterAssignObjects
    });
  };
  onChangeClass = (classId, indexPosition) => {
    let selectedClass = this.state.assignedSubjects[indexPosition]?.gradeOptions?.find((classd) => classd?.value == classId);

    const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
      if (indexPosition == index) {
        return { ...each, classId: classId, className: selectedClass?.label, groupId: selectedClass?.groupId, groupName: selectedClass?.groupName };
      } else {
        return each;
      }
    });
    this.setState(
      {
        assignedSubjects: filterAssignObjects
      },
      () => this.getCurriculumData(indexPosition, 'onChange')
    );
  };
  getCurriculumData = async (indexPosition, type) => {
    try {
      const obj = this.props.editUser?._id ? this.props.editUser?.user_info?.assignedSubjects : this.state.assignedSubjects;
      const filterObj = obj?.filter((each, index) => index == indexPosition);
      const boardId = filterObj[0]?.boardId;
      const classId = filterObj[0]?.classId;
      const groupId = filterObj[0]?.groupId;
      if (boardId && classId) {
        this.setState({
          isLoading: true,
          curriculumInfo: {}
        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
        const data = await this.service.get(url, true);
        if (data && data.res && data.res.Item) {
          if (this.props.editUser?._id && type == 'fromEdit') {
            this.setState({ isLoading: false });
            return data.res?.Item;
          } else {
            console.log(this.state.assignedSubjects, 'this.state.assignedSubjects');
            const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
              if (indexPosition == index) {
                return { ...each, subjectOptions: data.res.Item };
              } else {
                return each;
              }
            });
            console.log(filterAssignObjects, 'filterAssignObjects');

            this.setState({
              assignedSubjects: filterAssignObjects,
              isLoading: false
            });
          }
        } else {
          this.setState({
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        isLoading: false
      });
    }
  };

  onChangeCheckBox = (checked, clicked) => {
    if (clicked) {
      if (!checked) this.setState({ branchesOptions: [], boardsOptions: [] })
      this.setState({

        checked: checked, assignedSubjects: [
          {
            boardId: '',
            classId: '',
            subjectId: '',
            boardName: '',
            className: '',
            subjectName: '',
            gradeOptions: '',
            subjectOptions: ''
          }
        ], user: {
          ...this.state.user, branchGroup: [], branches: [], boards: [],
        }
      },
        () => this.getGroupsData(clicked)
      )
    } else {
      this.setState({ checked: checked }, () =>
        this.getGroupsData(clicked))
    }
  }
  onChangeAcademicYear = (years, clicked) => {
    if (clicked) {
      this.setState({
        assignedSubjects: [
          {
            boardId: '',
            classId: '',
            subjectId: '',
            boardName: '',
            className: '',
            subjectName: '',
            gradeOptions: '',
            subjectOptions: ''
          }
        ],
        user: {
          ...this.state.user, selectedAcademicYears: years, branchGroup: [], branches: [], boards: [],
        }
      }, () => this.getGroupsData(clicked));
    } else {
      this.setState({ user: { ...this.state.user, selectedAcademicYears: years } }, () => this.getGroupsData(clicked));
    }
  }
  onChangeRole = (role) => {
    this.setState({ user: { ...this.state.user, rolename: role } })
  }
  onChangeBranchGroup = (branchGroups, clicked) => {
    if (clicked) {
      this.setState({
        assignedSubjects: [
          {
            boardId: '',
            classId: '',
            subjectId: '',
            boardName: '',
            className: '',
            subjectName: '',
            gradeOptions: '',
            subjectOptions: ''
          }
        ],
        user: {
          ...this.state.user, branchGroup: branchGroups, branches: [], boards: [],
        }
      }, () => this.getBranchesBasedonGroups(branchGroups, clicked))
    } else {
      this.setState({ user: { ...this.state.user, branchGroup: branchGroups } }, () => this.getBranchesBasedonGroups(branchGroups, clicked))
    }

  }
  onChangeBranches = (branches, clicked) => {
    // console.log(this.state.branchesOptions)
    if (clicked) {
      this.setState({
        assignedSubjects: [
          {
            boardId: '',
            classId: '',
            subjectId: '',
            boardName: '',
            className: '',
            subjectName: '',
            gradeOptions: '',
            subjectOptions: ''
          }
        ],
        user: {
          ...this.state.user, branches: branches, boards: [],
        }
      }, () => this.getBoardsBasedonBranches(branches, clicked))
    } else {
      this.setState({ user: { ...this.state.user, branches: branches } }, () => this.getBoardsBasedonBranches(branches, clicked))
    }
  }
  onChangeBoard = (boards, clicked) => {
    if (clicked) {
      this.setState({
        user: {
          ...this.state.user, boards: boards, assignedSubjects: [
            {
              boardId: '',
              classId: '',
              subjectId: '',
              boardName: '',
              className: '',
              subjectName: '',
              gradeOptions: '',
              subjectOptions: ''
            }
          ],
        }
      }, () => this.onSelectingBoards(boards, clicked))

    } else {
      this.setState({ user: { ...this.state.user, boards: boards } }, () => this.onSelectingBoards(boards, clicked))
    }
  }
  render() {
    const { user, formStatus, assignedSubjects, formValidations, rolesOptions, groupBranchesOptions, sectionsData, academicYears } = this.state;

    return (
      <>
        <div className="grid flex flex-column justify-content-center mt-2 ml-6">
          <div className="grid">
            <div className="col-11  mt-4 flex justify-content-end align-items-center ">
              <Button label={this.props.editUser._id ? "Update" : "Save"} icon={<i className="pi pi-plus-circle text-xl ml-2 -mr-3"></i>} className="ageCriteriaSaveBtn" onClick={() => this.onCreateUser()} />
            </div>
          </div>

          <div className="grid">
            <div className="col-3 flex flex-column justify-content-start align-items-start">
              <ClassetDropdown
                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                options={this.state?.rolesOptions}
                label="Select Role"
                required={true}
                optionLabel="roleName"
                optionValue="_id"
                // formHelperError={formValidations && !formValidations.fields['rolename'].isValid && formStatus}
                className=""
                value={user.rolename}
                onChange={(e) => {
                  this.onChangeRole(e.target.value);
                  // onTextChange(e.target.value, 'rolename', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder={
                  <div className="flex justify-content-start  align-items-center">
                    <span className=" ml-2 ">Select Role</span>
                  </div>
                }
              />
              {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error text-lg ">{formValidations.fields['rolename'].errorMsg}</p>} */}
            </div>

            <div className="col-3 mt-3 flex justify-content-center align-items-center ">
              <Checkbox inputId="binary" checked={this.state.checked}
                onChange={(e) => this.onChangeCheckBox(e.checked, true)}
              />
              <label htmlFor="binary" className="ml-3">
                Assign All Academic Years
              </label>
            </div>

            {!this.state.checked && (
              <div className="col-3 flex flex-column justify-content-start align-items-start">
                <ClassetMultiSelect
                  icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                  options={academicYears}
                  label="Select Academic Years"
                  optionLabel="academicCode"
                  optionValue="_id"
                  value={user.selectedAcademicYears}
                  // onChange={(e) => {
                  //     onDropDownChange(e.target.value, 'selectedAcademicYears', this, createUserFields, user, formValidations, 'user', 'formValidations');
                  //     this.getGroupsData();
                  // }}
                  onChange={(e) => {
                    this.onChangeAcademicYear(e.target.value, true);
                    // this.setState({ user: { ...user, selectedAcademicYears: e.target.value } }, () => {
                    // this.getGroupsData();
                    //  this.getBranchData()
                    // });
                  }}
                  disabled={this.state.checked}
                  placeholder={
                    <div className="flex justify-content-start  align-items-center">
                      <span className=" ml-2 ">Select Academic Years</span>
                    </div>
                  }
                />
              </div>
            )}

            <div className="col-12 mt-5 flex justify-content-start align-items-center">
              <h3 className="font-bold">Access Level</h3>
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetMultiSelect
                value={user?.branchGroup}
                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                onChange={(e) => {
                  this.onChangeBranchGroup(e.target.value, true);
                  // onDropDownChange(e.target.value, 'branchGroup', this, createUserFields, user, formValidations, 'user', 'formValidations');
                  // this.getBranchesBasedonGroups(e.target.value);
                }}
                options={groupBranchesOptions}
                label="Branch Groups"
                optionLabel="name"
                optionValue="_id"
                width={'343px'}
                className=""
                maxSelectedLabels={3}
                placeholder="Select Branch Group"
              />
              {/* {formValidations && !formValidations.fields['branchGroup'].isValid && <p className="p-error">{formValidations.fields['branchGroup'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetMultiSelect
                value={user?.branches}
                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                onChange={(e) => {
                  this.onChangeBranches(e.target.value, true)
                  // onDropDownChange(e.target.value, 'branches', this, createUserFields, user, formValidations, 'user', 'formValidations');
                  // this.getBoardsBasedonBranches(e.target.value);
                }}
                options={this.state?.branchesOptions}
                label="Branches"
                optionLabel="name"
                required={true}
                width={'343px'}
                optionValue="key"
                className=""
                maxSelectedLabels={3}
                placeholder="Select Branches"
              />

              {/* {formValidations && !formValidations.fields['branches'].isValid && <p className="p-error">{formValidations.fields['branches'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetMultiSelect
                value={user?.boards}
                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                onChange={(e) => {
                  this.onChangeBoard(e.target.value, true)
                  // onDropDownChange(e.target.value, 'boards', this, createUserFields, user, formValidations, 'user', 'formValidations');
                  // this.onSelectingBoards(e.target.value);
                }}
                // options={this.props?.editUser?._id ?this.props.boards?.filter(option => user?.boards.includes(option.boardId)):this.state?.boardsOptions}
                options={this.state?.boardsOptions}
                label="Boards"
                optionLabel="boardName"
                required={true}
                width={'343px'}
                optionValue="boardId"
                className="text-lg shadow-2 pl-2 h-3rem"
                maxSelectedLabels={3}
                placeholder="Select Boards"
              />

              {/* {formValidations && !formValidations.fields['boards'].isValid && <p className="p-error">{formValidations.fields['boards'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetInputText
                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                className="border-round-md  border-none"
                label="UserName / ID"
                required={true}
                formHelperError={formValidations && !formValidations.fields['userNameOrId']?.isValid && formStatus}
                value={user.userNameOrId}
                onChange={(e) => {
                  this.setState({ user: { ...user, userNameOrId: e.target.value } });
                  // onTextChange(e.target.value, 'userNameOrId', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder="Enter UserName"
              />
              {/* {formValidations && !formValidations.fields['userNameOrId'].isValid && <p className="p-error">{formValidations.fields['userNameOrId'].errorMsg}</p>} */}
            </div>
            {!this.props.editUser?._id && (
              <>
                <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                  <ClassetInputText
                    icon={<MailIcon width={24} height={24} color={'#000000'} />}
                    className="border-round-md  border-none"
                    label="Password"
                    isPassword={true}
                    required={true}
                    formHelperError={formValidations && !formValidations.fields['password']?.isValid && formStatus}
                    value={user.password}
                    onChange={(e) => {
                      this.setState({ user: { ...user, password: e.target.value } });
                      // onTextChange(e.target.value, 'password', this, createUserFields, user, formValidations, 'user', 'formValidations');
                    }}
                    placeholder="Enter Password"
                  />
                  {formValidations && !formValidations.fields['password']?.isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                </div>
                <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                  <ClassetInputText
                    icon={<MailIcon width={24} height={24} color={'#000000'} />}
                    className="border-round-md  border-none"
                    label="Confirm Password"
                    isPassword={true}
                    required={true}
                    formHelperError={formValidations && !formValidations.fields['confirmPassword']?.isValid && formStatus}
                    value={user.confirmPassword}
                    onChange={(e) => {
                      this.setState({ user: { ...user, confirmPassword: e.target.value } });
                      // onTextChange(e.target.value, 'confirmPassword', this, createUserFields, user, formValidations, 'user', 'formValidations');
                    }}
                    placeholder="Enter Confirm Password"
                  />
                  {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                </div>
              </>
            )}
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetInputText
                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                className="border-round-md  border-none"
                label="First Name"
                required={true}
                formHelperError={formValidations && !formValidations.fields['firstName'].isValid && formStatus}
                value={user.firstName}
                onChange={(e) => {
                  this.setState({ user: { ...user, firstName: e.target.value } });
                  // onTextChange(e.target.value, 'firstName', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder="Enter First Name"
              />
              {/* {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error">{formValidations.fields['firstName'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetInputText
                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                className="border-round-md  border-none"
                label="Last Name"
                required={true}
                formHelperError={formValidations && !formValidations.fields['lastName'].isValid && formStatus}
                value={user.lastName}
                onChange={(e) => {
                  this.setState({ user: { ...user, lastName: e.target.value } });
                  // onTextChange(e.target.value, 'lastName', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder="Enter Last Name"
              />
              {/* {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error">{formValidations.fields['lastName'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetDropdown
                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                options={this.state.userTypeOptions}
                optionLabel="label"
                optionValue="value"
                label={'User Type'}
                required={true}
                // formHelperError={formValidations && !formValidations.fields['userType'].isValid && formStatus}
                className=""
                value={user.userType}
                onChange={(e) => {
                  if (e.target.value === 'non-academic') {
                    this.setState({
                      nonAcademic: true, assignedSubjects: [
                        {
                          boardId: '',
                          classId: '',
                          subjectId: '',
                          boardName: '',
                          className: '',
                          subjectName: '',
                          gradeOptions: '',
                          subjectOptions: ''
                        }
                      ], user: { ...user, userType: e.target.value }
                    })
                  } else {
                    this.setState({
                      nonAcademic: false, assignedSubjects: [
                        {
                          boardId: '',
                          classId: '',
                          subjectId: '',
                          boardName: '',
                          className: '',
                          subjectName: '',
                          gradeOptions: '',
                          subjectOptions: ''
                        }
                      ], user: { ...user, userType: e.target.value }
                    })
                  }
                  // onDropDownChange(e.target.value, 'userType', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder={
                  <div className="flex justify-content-start  align-items-center">
                    <span className="  ">Select User Type</span>
                  </div>
                }
              />
              {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-8 lg:col-8 md:col-12 col-12">
              <ClassetInputText
                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                className="border-round-md  border-none"
                label="Email Address"
                width={'600px'}
                required={true}
                // formHelperError={formValidations && !formValidations.fields['email'].isValid && formStatus}
                value={user.email}
                onChange={(e) => {
                  this.setState({ user: { ...user, email: e.target.value } });
                  // onTextChange(e.target.value, 'email', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder="Email Address"
              />
              {/* {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>} */}
            </div>
            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <ClassetInputText
                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                className="border-round-md  border-none"
                label="Contact Number"
                required={true}
                // formHelperError={formValidations && !formValidations.fields['phone_number'].isValid && formStatus}
                value={user.phone_number}
                onChange={(e) => {
                  this.setState({ user: { ...user, phone_number: e.target.value } });
                  // onNumberChange(e.target.value, 'phone_number', this, createUserFields, user, formValidations, 'user', 'formValidations');
                }}
                placeholder="Contact Number(Mobile)"
              />
              {/* {formValidations && !formValidations.fields['phone_number'].isValid && <p className="p-error">{formValidations.fields['phone_number'].errorMsg}</p>} */}
            </div>

            {/* <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                  <ClassetInputText
                      icon={<MailIcon width={24} height={24} color={'#000000'} />}
                      className='border-round-md  border-none '
                      label="Photo"
                      required={true}
                      formHelperError={formValidations && !formValidations.fields['studnetPhoto'].isValid && formStatus}
                      value={user.studnetPhoto}
                      // onChange={(e) => { onTextChange(e.target.value, 'studnetPhoto', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                      placeholder="File Name" />
                  <div className=" flex justify-content-start mt-5">
                      <Toast ref={this.toast}></Toast>
                      <FileUpload
                          mode="basic"
                          name="demo[]"
                          url="/api/upload"
                          accept="image/png"
                          maxFileSize={1000000}
                          onUpload={this.onUpload}
                          onSelect={this.onSelect}
                          auto
                          chooseLabel="Browse"
                      />
                      {this.state.selectedFileName && <p>Selected file: {this.state.selectedFileName}</p>}


                  </div>

              </div> */}

            <div className="xl:col-4 lg:col-4 md:col-6 col-12">
              <p className="transport-fee-form-label">
                Photo<span className="ma-required">*</span>
              </p>
              <FileUpload
                id={'studnetPhoto'}
                multiple={false}
                onProgress={this._onProgress}
                onSucess={this._onPhotoSuccess}
                //  Reset={this.state.Reset}
                maxFileSize={307200}
                onError={this._onFilsUploadError}
                accept={'images'}
                title={'Images'}
                afterFilesUploaded={this._afterFilesUploaded}
                className="border-solid"
              >
                {' '}
                <div className="flex items-center">
                  <ClassetInputText
                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                    className="text-xl add-vehicle-field-input w-full"
                    value={user.studnetPhoto?.fileName || this.props.editUser?.user_info?.studentPhoto?.fileName || ''}
                    placeholder="Upload Photo"
                    disabled={true}
                  />

                  {(user.studnetPhoto?.storageLocation || this.props.editUser?.user_info?.studentPhoto?.storageLocation) && (
                    <div className="ml-2" >
                      <img src={user.studnetPhoto?.storageLocation || this.props.editUser?.user_info?.studentPhoto?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                    </div>
                  )}

                  {/* {this.props.editUser?.user_info?.studentPhoto && (
                              <div className="ml-2">
                                  <img
                                      src={this.props.editUser?.user_info?.studentPhoto?.storageLocation }
                                      alt="Uploaded Photo"
                                      width={50}
                                      height={50}
                                  />
                              </div>
                          )} */}
                </div>
              </FileUpload>
            </div>

            <Divider type="solid" className="custom-divider" align="center" />
            <div className="col-12">
              {Array.isArray(assignedSubjects) &&
                assignedSubjects.map((obj, index) => (
                  <div className="grid py-0 -mt-2" key={index}>
                    <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                      <ClassetDropdown
                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                        options={this.state.boardsForAssignOptions}
                        optionLabel="boardName"
                        optionValue="boardId"
                        label={'Board'}
                        required={true}
                        className=" no-border border-round-md shadow-2 "
                        onChange={(e) => this.onChangeBoards(e.value, index)}
                        value={obj?.boardId}
                        placeholder={
                          <div className="flex justify-content-start  align-items-center">
                            <span className="">Select Board</span>
                          </div>
                        }
                      />
                    </div>
                    <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                      <ClassetDropdown
                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                        options={obj?.gradeOptions}
                        optionLabel="label"
                        optionValue="value"
                        label={'Grade'}
                        required={true}
                        onChange={(e) => this.onChangeClass(e.value, index)}
                        className=" no-border border-round-md shadow-2 "
                        value={obj?.classId}
                        placeholder={
                          <div className="flex justify-content-start  align-items-center">
                            <span className=" ">Select Grade</span>
                          </div>
                        }
                      />
                    </div>
                    <div className="grid xl:col-4 lg:col-4 md:col-4 col-12">
                      <div className="col-10">
                        <ClassetDropdown
                          icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                          value={obj?.subjectId}
                          options={obj?.subjectOptions?.subjects}
                          label="Subjects"
                          optionLabel="subjectName"
                          optionValue="subjectId"
                          required={true}
                          width={'343px'}
                          onChange={(e) => this.onChangeSubject(e.value, index)}
                          className=""
                          maxSelectedLabels={3}
                          placeholder={
                            <div className="flex justify-content-start  align-items-center">
                              <span className="">Select Subject</span>
                            </div>
                          }
                        />
                      </div>

                      <div className="col-2 pl-4 pt-5 flex justify-content-start align-items-center  cursor-pointer" onClick={() => this.removeBoards(index)} style={{ cursor: 'pointer' }}>
                        <DeleteIconClasset width={22} height={22} />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="mt-2 flex justify-content-between align-content-center">
                <Button className="mt-2" style={{ borderRadius: 10, backgroundColor: '#F4F5F6', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'black' }} onClick={this.addBoards}>
                  <p className="text-xl">Add More</p>
                </Button>
              </div>
            </div>
          </div>
          <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
        </div>
        {this.state.isLoading && <LoadingComponent />}
      </>
    )
  }
}

const mapStatesToProps = (state) => ({
  // boards: userAssignedBoards(state, 'activeBoards'),
  boards: (state.boardsData && state.boardsData.boards) || [],
  permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
  boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
  userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
  // branchData:
  //     (state.branchDataLatest &&
  //         state.branchDataLatest.data &&
  //         state.branchDataLatest.data?.data?.filter((each) => {
  //             if (each.level == 1) {
  //                 return { ...each };
  //             }
  //         })) ||
  //     []
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(newCreateUser)));