import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react';
import './styles.scss';
import { Button } from 'primereact/button';
import { AddIcon2, CalenderIcon, CodeLinkIcon, DownloadIcon, InfoIcon2, MailIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BasicLazyParams, convertUtc } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { baseUrlAdmin } from '../../store/apiConstants';
import { FinanceStudentProfile } from '../svgIcons';
import Service from '../../services';
import ClassetStudentSearch from '../../classetComponents/classetStudentSearch';
import { RadioButton } from 'primereact/radiobutton';
import LoadingComponent from '../loadingComponent';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { debounce } from 'lodash';
import moment from 'moment';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../utile/formHelper';
import ClassetCalendar from '../../classetComponents/classetCalender';
import { paymentModes } from '../../constants';
import { Toast } from 'primereact/toast';
import NewFeeReciept from '../finance/newFeeReciept';
import BulkFeeReciepts from './bulkFeeReciepts';
import Axios from 'axios';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD';
const createFields = require('./collectFee.json');

const options = [
    { value: 1, label: 'Term Based' },
    { value: 2, label: 'Student Profile' }
];


const searchTypeOptions = [
    { label: 'Student Name', value: 'studentName' },
    { label: 'Enrollment Number', value: 'enrollmentNumber' }
];
export class CollectFee extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFields, this.props.editData);
        this.state = {
            tab: 1,
            lazyParams: BasicLazyParams,
            termBasedData: [],
            formValidations: this.formFields?.formValidations,
            openCreateFeeDialog: false,
            searchType: searchTypeOptions[0].value,
            searchInput: '',
            isLoading: false,
            searchLevel: 1,
            searchValue: '',
            students: [],
            studentId: '',
            totalRecords: '',
            payFeeFormData: {}, //form data
            chequeIssuedTo: '',
            chequeNo: '',
            chequeDate: new Date(),
            bankName: '',
            bankBranchName: '',
            destinationBank: '',
            transctionId: '',
            partialPayment: '',
            bankOptions: [],
            isLoading1: false,
            isEnableRecipt: false,
            installmentInfo: [],
            isInstallmentInfo: false
        };
        this.debouncedApiCall = debounce(this.getTermBasedData, 500);
        this.service = new Service();
    }

    onHandlePayFee = (data) => {
        const { payFeeFormData } = this.state;
        const updatedFormData = { ...payFeeFormData, installmentId: data.installmentId, dueAmount: data.dueAmount, feeinstallmentTitle: data?.feeinstallmentTitle };
        this.setState({
            openCreateFeeDialog: true,
            feeInfoToPayFee: data,
            payFeeFormData: updatedFormData
        });
    };

    onHandleCustomFee = () => {
        this.setState({
            openCreateFeeDialog: true
            // feeInfoToPayFee: data ,
            //    payFeeFormData:updatedFormData
        });
    };

    calculateFine = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ loading: true });
        let url = `${baseUrlAdmin}/fine/fine-apply?academicYear=${academicYear}`;
        this.service
            .post(url, { user_id: this.state.studentId, academicYear: academicYear }, true)
            .then((data) => {
                if (data.status) {
                    this.setState({ loading: false });
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: data?.res?.message, life: 3000 });
                    this.getTermBasedData();
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', detail: 'Some error occurred', summary: 'Error', life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', detail: 'Some error occurred', summary: 'Error', life: 3000 });
            });
    };

    OnPayFee = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let data = this.state.payFeeFormData;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/fee-collect`;
        data.amountPaying = this.state.partialPayment ? data.amountPaying : data?.dueAmount
        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            paymentType: data?.paymentType,
            amountPaying: Math.floor(Number(data?.amountPaying) * 100) / 100,
            remarks: data?.remarks,
            academicYear: academicYear,
            // term: this.state.partialPayment ? '' : Number(data?.term[5])

        };

        if (this.state.partialPayment) {
            delete payload?.term;
            payload.feeinstallmentTitle = data.feeinstallmentTitle
            payload.installmentId = data.installmentId
        }

        //cash
        if (data?.paymentType === '74b95cc8-9736-44a0-b2df-325595783c3b') {
            payload.destinationBank = this.state.destinationBank;
        } else if (data?.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f') {
            //cheque
            payload.chequeIssuedTo = this.state.chequeIssuedTo;
            payload.chequeNo = this.state.chequeNo;
            payload.chequeDate = convertUtc(this.state.chequeDate);
            payload.bankName = this.state.bankName;
            payload.bankBranchName = this.state.bankBranchName;
            payload.destinationBank = this.state.destinationBank;
        } else if (data?.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1') {
            //dd
            payload.ddIssuedTo = this.state.chequeIssuedTo;
            payload.ddNo = this.state.chequeNo;
            payload.ddDate = convertUtc(this.state.chequeDate);
            payload.bankName = this.state.bankName;
            payload.bankBranchName = this.state.bankBranchName;
            payload.destinationBank = this.state.destinationBank;
        } else if (
            data?.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
            data?.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
            data?.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
            data?.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489'
        ) {
            payload.transctionId = this.state?.transctionId;
        }

        const formStatus = isFormValid(createFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }
        let chequeFlag =
            data?.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' || data?.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1'
                ? this.state.bankName !== '' &&
                this.state.bankName !== undefined &&
                this.state.chequeIssuedTo !== '' &&
                this.state.chequeIssuedTo !== undefined &&
                this.state.chequeNo !== '' &&
                this.state.chequeNo !== undefined &&
                this.state.chequeDate !== '' &&
                this.state.chequeDate !== undefined &&
                this.state.bankBranchName !== '' &&
                this.state.bankBranchName !== undefined
                : true;
        let flag = true
        let flag2 = /^\d*\.?\d*$/.test(data?.amountPaying);
        if (isCompleteFormValid && chequeFlag && flag && flag2) {
            this.service
                .post(url, payload, true)
                .then((data) => {
                    if (data.status) {
                        this.setState({
                            openCreateFeeDialog: false,
                            // feeInfoToPayFee: {},
                            chequeIssuedTo: '',
                            chequeNo: '',
                            ddDate: '',
                            bankName: '',
                            bankBranchName: '',
                            destinationBank: '',
                            transctionId: '',
                            payFeeFormData: {},
                            isLoading: false,
                            isEnableRecipt: true,
                            receiptData: data?.res?.data,
                            chequeDate: new Date()
                        });
                        this.toast.show({ severity: 'success', summary: 'Successful', detail: data?.res?.message, life: 3000 });
                        this.getTermBasedData();
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', detail: 'Some error occurred', summary: 'Error', life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', detail: 'Some error occurred', summary: 'Error', life: 3000 });
                });
        } else {
            if (!chequeFlag) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter All Require Fields', life: 3000 });
            } else if (!flag) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Transaction Id ', life: 3000 });
            } else if (!flag2) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Please Check Amount', detail: 'Please enter valid amount', life: 3000 });
            } else {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Enter all Required Fields', life: 3000 });
            }
        }
    };
    loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };
    handlePayment = async () => {
        let payType = this.state.payFeeFormData?.paymentType;
        let a = this.state.partialPayment ? this.state.payFeeFormData?.amountPaying : this.state.payFeeFormData?.dueAmount

        if (payType?.length && a > 0) {

            if (payType === '74b95cc8-9736-44a0-b2df-325595783c3b' || payType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' || payType === "9d08ebab-2d3e-4a30-8460-4e4092fe30a1" || payType === 'b9e46415-1b11-4377-9105-58b98c230935' || payType === "274a4416-2454-4f3a-b741-89c67361ae1f") {
                this.OnPayFee()
            } else {
                const res = await this.loadRazorpayScript();
                if (!res) {
                    alert("Failed to load Razorpay SDK. Check your internet connection.");
                    return;
                }
                let url = `${baseUrlAdmin}/create-order`;
                let vurl = `${baseUrlAdmin}/verify-payment`;

                let payload = {
                    amount: this.state.partialPayment ? this.state.payFeeFormData?.amountPaying * 100 : this.state.payFeeFormData?.dueAmount * 100,
                    currency: "INR"
                }
                // Get order details from your backend
                const { data: order } = await Axios.post(url, payload);

                const options = {
                    key: "rzp_test_rJgczs9D0fmDjk", // Replace with your Razorpay key
                    amount: order.amount,
                    currency: order.currency,
                    name: "Classet",
                    description: "Purchase Description",
                    image: "https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/17358829538761712311939159IMG-20231127-WA0017%20-%20Copy.jpg",
                    order_id: order.id,
                    handler: async (response) => {
                        // Send response to backend for verification
                        const result = await Axios.post(vurl, {
                            order_id: order.id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                        });

                        // alert(result.data);
                        this.OnPayFee()
                    },
                    prefill: {
                        name: "Monangi Kiran",
                        email: "kiranm@classet.in",
                        contact: "7799279701",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Fill Payment Type And Amount', life: 3000 });

        }
    };

    onHandleCancelPayFee = () => {
        this.setState({
            openCreateFeeDialog: false,
            // paymentTypeOption: null,
            // feeInfoToPayFee: {},
            chequeIssuedTo: '',
            chequeNo: '',
            ddDate: '',
            bankName: '',
            bankBranchName: '',
            destinationBank: '',
            transctionId: '',
            payFeeFormData: {}
        });
    };
    componentDidMount = () => {
        this.getBankDetails();
        // this.submitFee();
        // this.getStudents();
    };
    handleSearchLevel = (value) => {
        this.setState({
            searchLevel: value,
            username: '',
            studentId: '',
            termBasedData: [],
            dropdownsData: {
                boardId: '',
                branchId: '',
                classId: '',
                sectionId: '',
                studentId: ''
            }
        });
    };

    getTermBasedData = () => {
        this.setState({ isLoading: true, termBasedData: [] });
        let academicYear = localStorage.getItem('userAcademicYear');
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-collect/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo
        };
        console.log();
        this.service
            ?.post(url, payload, true)
            .then((data) => {
                console.log(data);
                if (data?.status && data?.res?.data?.length) {
                    // const totalStudentDue=
                    this.setState({
                        termBasedData: data?.res?.data,
                        isLoading: false,
                        totalRecords: data?.res?.totalRecords,
                        partialPayment: data?.res?.partialPayment,
                        // partialPayment:false,
                        studentId: data?.res?.studentId,
                        username: data?.res?.username,
                        user_info: data?.res?.user_info,
                        totalStudentDue: Number(data?.res?.totalStudentDue)
                    });
                } else if (data?.status && !data?.res?.data?.length) {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'No Fee Structure Assigned to Student', life: 3000 });
                } else {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Error Occurred', life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };
    getStudents = () => {
        this.setState({ isLoading1: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        this.service
            .post(url, payload, true)
            .then((data) => {
                const studentsData = data?.res?.data.map((each) => {
                    return {
                        label: each?.name,
                        value: each?.user_id
                    };
                });

                if (data?.status) {
                    this.setState({
                        students: studentsData,
                        isLoading1: false
                    });
                } else {
                    this.setState({ isLoading1: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading1: false });
            });
    };

    getBankDetails = () => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/bank?academicYear=${AcademicYear}`;
        this.service.get(url, true).then((data) => {
            const bankOptions = data?.res?.data.map((each) => {
                return {
                    label: each?.name,
                    value: each?._id
                };
            });

            if (data.status) {
                if (data?.res?.status && data?.res?.data) {
                    this.setState({
                        bankOptions: bankOptions,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    };
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                termBasedData: [],
                studentId: '',
                username: '',
                user_info: {}
            },
            () => {
                this.getStudents();
            }
        );
    };

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == 'studentName') {
                this.setState({ isUserNameSearch: true });
            } else {
                this.setState({ isUserEnrollSearch: true });
            }
        });
    };

    handleSetSearchValue = (value) => {
        this.setState(
            {
                searchValue: value,
                dropdownsData: {
                    boardId: '',
                    branchId: '',
                    classId: '',
                    sectionId: '',
                    studentId: ''
                }
            },
            () => {
                this.debouncedApiCall();
            }
        );
    };

    onStudentChange = (e) => {
        this.setState({ studentId: e.value, termBasedData: [] }, () => {
            this.getTermBasedData();
        });
    };
    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTermBasedData);
    };
    hideFeeReciept = () => {
        this.setState({
            isEnableRecipt: false
        });
    };
    downloadBulkReciepts = (installmentId) => {
        this.setState({ isLoading: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-collect/receipts`;

        let payload = {
            academicYear: academicYear,
            installmentId: installmentId,
            user_id: this.state.studentId
        };

        this.service
            ?.post(url, payload, true)
            .then((data) => {
                if (data?.status && data?.res?.data?.length) {
                    // const totalStudentDue=
                    this.setState({
                        termWiseReceiptInfo: data?.res?.data,
                        isBulkfeeReceipts: true,
                        isLoading: false
                    });
                } else if (data?.status && !data?.res?.data?.length) {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'No Fee Paid By Student', life: 3000 });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };

    installmentInfo = (data) => {
        this.setState({ isLoading: true });

        let payload = {
            installmentId: data?.installmentId,
            user_id: this.state.studentId,
            academicYear: localStorage.getItem('userAcademicYear')
        }
        let url = `${baseUrlAdmin}/fee-collect/installmentInfo`;
        this.service.post(url, payload, true).then(res => {
            if (res && res?.status) {
                this.setState({
                    installmentInfoData: res?.res?.data,
                    isLoading: false
                })

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: data.errMessage, life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: data.errMessage, life: 3000 });
        })
    }

    isshowInstallmentInfo = async (data) => {
        await this.installmentInfo(data)
        this.setState({ isInstallmentInfo: true })
    }
    onHandleInstallmentInfo = () => {
        this.setState({ isInstallmentInfo: false, installmentInfoData: [] })
    }



    render() {
        const { payFeeFormData, formValidations, username } = this.state;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h5>{username?.slice(3)?.toUpperCase()}</h5>
                </div>
            </div>
        );
        return (
            <div className="ma-main">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BranchToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>
                    </div>
                    <div className="col-12  ml-5">
                        <label className="label">
                            Student<span className="ma-required">*</span>
                        </label>
                        <div className="flex">
                            <div className="mt-2">
                                <ClassetDropdown
                                    icon={<MailIcon />}
                                    id="paymentMode"
                                    className=""
                                    height="48px"
                                    width="328px"
                                    options={this.state?.students}
                                    value={this.state.studentId}
                                    disabled={this.state?.searchLevel === 2}
                                    onChange={this.onStudentChange}
                                    placeholder={
                                        <div>
                                            <span className="">Select Student</span>
                                        </div>
                                    }
                                />
                            </div>
                            {this.state.isLoading1 && <LoadingComponent />} {/* Show loading indicator */}
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-between">
                    <div className="col-12 sm:col-8  m-2 ">
                        <SelectButton
                            className="button-group"
                            value={this.state.tab}
                            options={options}
                            onChange={(e) => {
                                console.log(e);
                                this.setState({ tab: e.value == null ? this.state.tab : e.value });
                            }}
                        />
                    </div>
                    {/* <div className="termBasedHeading">Term Based</div> */}
                    {this.state.tab == 1 && (
                        <div className="col-12  sm:col-3  flex justify-content-end align-items-center custom-fee">
                            <img style={{ width: 40, height: 40, marginRight: 40 }} src="./Refresh_button_1.svg" onClick={this.calculateFine} className="refresh-i ma-pointer" />

                            <Button disabled={!this.state.partialPayment} onClick={this.onHandleCustomFee}>
                                <p className="custom-fee-add">Custom Fee Payment</p>
                            </Button>
                        </div>
                    )}
                </div>

                <div className="card datatable-crud-demo ma-m30  m-2">
                    {/* <div className="col-12 flex justify-content-between align-items-center custom-fee">
                        <div className="flex border-2">
                            <div className="cursor-pointer p-6">TermBased</div>
                            <div className="cursor-pointer p-6">Student Profile</div>
                        </div>
                        <div>
                            <img style={{ width: 40, height: 40, marginRight: 40 }} src="./Refresh_button_1.svg" onClick={this.calculateFine} className="refresh-i ma-pointer" />
                            <Button disabled={!this.state.partialPayment} onClick={this.onHandleCustomFee}>
                                <p className="custom-fee-add">Custom Fee Payment</p>
                            </Button>
                        </div>
                    </div> */}
                    {this.state.tab == 1 && (
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.termBasedData}
                            dataKey="id"
                            paginator
                            lazy
                            responsiveLayout="scroll"
                            className="dataTableValuesText"
                            showGridlines
                            selectionMode={'checkbox'}
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}
                        >
                            <Column
                                alignHeader={'center'}

                                bodyClassName="text-center"
                                header="Installment"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{` ${rowData.feeinstallmentTitle}`}</div>;
                                }}
                            ></Column>
                            <Column filterField="totalFee" field="totalAmount" bodyClassName="text-center" header="Total Fee" alignHeader={'center'} />
                            <Column filterField="concession" field="concession" bodyClassName="text-center" header="Concession" alignHeader={'center'} />
                            <Column filterField="gst" field="actualGst" bodyClassName="text-center" header="Gst" alignHeader={'center'} />
                            <Column filterField="fineAmt" field="fineAmt" bodyClassName="text-center" header="Fine Amount" alignHeader={'center'} />
                            <Column filterField="amountPaid" field="amountPaid" bodyClassName="text-center" header="Paid Fee" alignHeader={'center'}
                            />
                            <Column filterField="totalDue" field="dueAmount" bodyClassName="text-center" header="Total Due" alignHeader={'center'} body={(rowData, { rowIndex }) => {
                                return <div className="flex justify-content-center"> {(rowData?.dueAmount - rowData?.refundAmt).toFixed(2)}</div>;
                            }} />
                            <Column
                                alignHeader={'center'}

                                bodyClassName="text-center"
                                header="Due Date"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{moment(rowData.dueDate).format('YYYY-MM-DD')}</div>;
                                }}
                            ></Column>
                            {/*  <Column
                                alignHeader={'center'}
                                
                                bodyClassName="text-center"
                                header="Actual Fee"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{rowData.totalAmount + rowData?.actualGst}</div>;
                                }}
                            ></Column> */}
                            <Column

                                bodyClassName="text-center"
                                header="Pay Now"
                                alignHeader={'center'}
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className="custom-fee-pay">
                                            <Button
                                                // disabled={this.state?.partialPayment }
                                                disabled={!rowData.isPayNow}
                                                style={{ backgroundColor: rowData.totalDue !== 0 ? '#076EFF' : '#E6E8EA', border: rowData.totalDue !== 0 ? '1px solid #076EFF' : '1px solid #E6E8EA' }}
                                                onClick={() => rowData.dueAmount !== 0 && this.onHandlePayFee(rowData)}
                                            >
                                                <p className="payButton" style={{ color: rowData.totalDue !== 0 ? '#ffffff' : '#C6CED2' }}>
                                                    Pay Now
                                                </p>
                                            </Button>
                                        </div>
                                    );
                                }}
                            />
                            <Column
                                alignHeader={'center'}

                                bodyClassName="text-center"
                                header="Actions"
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className="flex justify-content-center">
                                            <div className='mx-2'
                                                onClick={() => {
                                                    this.downloadBulkReciepts(rowData?.installmentId);
                                                }}
                                            >
                                                <DownloadIcon width={20} height={20} color={'#000000'} />
                                            </div>
                                            <div
                                                onClick={() => {
                                                    this.isshowInstallmentInfo(rowData);
                                                }}
                                            >
                                                <InfoIcon2 width={20} height={20} color={'#000000'} />
                                            </div>
                                        </div>
                                    );
                                }}

                            ></Column>
                        </DataTable>
                    )}
                    {this.state.tab == 2 && (
                        <div className="flex justify-content-between align-items-start p-5">
                            <div style={{ fontWeight: 'bolder', fontSize: '17px', flex: 0.3, color: 'black' }}>
                                <div>
                                    <p style={{ fontWeight: 'bolder', fontSize: '25px', marginBottom: '20px' }}>Student Information</p>
                                    <div className="mt-1" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div className="studentProfileAlignment">
                                            <p>Student Name</p>
                                            <p>:</p>
                                            <p>{this.state.user_info?.firstName} {this.state.user_info?.lastName}</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Father's Name</p>
                                            <p>:</p>
                                            <p>{this.state.user_info?.contactPersonName}</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Mother's Name</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>DOB</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>

                                        <div className="studentProfileAlignment">
                                            <p>Contact No</p>
                                            <p>:</p>
                                            <p>{this.state.user_info?.parentContactNo}</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Email Id</p>
                                            <p>:</p>
                                            <p>{this.state.user_info?.email}</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Address</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                    </div>
                                </div>
                                {/*      <div className="mt-6">
                                    <p style={{ fontWeight: 'bolder', fontSize: '25px', marginBottom: '20px' }}>Assigned Fee</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div className="studentProfileAlignment">
                                            <p>Fee Type</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Actual Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Concession</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Total Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Paid Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Due Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                    </div>
                                </div> */}
                                {/*     <div className="mt-6">
                                    <p style={{ fontWeight: 'bolder', fontSize: '25px', marginBottom: '20px' }}>Fee History</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div className="studentProfileAlignment">
                                            <p>Fee Type</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Tution Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Concession Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Transport Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Mess Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                        <div className="studentProfileAlignment">
                                            <p>Library Fee</p>
                                            <p>:</p>
                                            <p>----</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <FinanceStudentProfile width={300} />
                        </div>
                    )}



                    <Dialog
                        visible={this.state.openCreateFeeDialog}
                        className="pay-fee"
                        style={{ position: 'fixed', right: '0' }}
                        draggable={false}
                        onHide={this.onHandleCancelPayFee}
                        closable={false}
                        header={() => {
                            return (
                                <div className="mt-2 mb-4">
                                    <h3 className="formhead text-center ">Pay Fee</h3>
                                </div>
                            );
                        }}
                    ><>
                            <div className="mb-5 ml-5 mr-5">
                                <div>
                                    {!this.state.partialPayment && (
                                        <div className="col-12">
                                            <label className="label mb-2 ">
                                                Installement<span className="ma-required">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <ClassetInputText
                                                    id="term"
                                                    icon={<CodeLinkIcon height={22} />}
                                                    className=""
                                                    height="48px"
                                                    width="368px"
                                                    placeholder="Enter Installment"
                                                    value={`${payFeeFormData.feeinstallmentTitle}`}
                                                    disabled={true}
                                                    onChange={(e) => onTextChange(e.target.value, 'feeinstallmentTitle', this, createFields, payFeeFormData, formValidations, 'payFeeFormData', formValidations)}
                                                />
                                            </div>
                                            {formValidations && !formValidations.fields['feeinstallmentTitle'].isValid && <p className="p-error">{formValidations.fields['feeinstallmentTitle'].errorMsg}</p>}
                                        </div>
                                    )}
                                    {!this.state.partialPayment && (
                                        <div className="col-12">
                                            <label className="label mb-2 ">
                                                Due Amount<span className="ma-required">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <ClassetInputText
                                                    id="dueAmount"
                                                    icon={<CodeLinkIcon height={22} />}
                                                    className=""
                                                    height="48px"
                                                    width="368px"
                                                    placeholder="Enter Due Amount"
                                                    value={payFeeFormData.dueAmount}
                                                    disabled={true}
                                                    onChange={(e) => onTextChange(e.target.value, 'dueAmount', this, createFields, payFeeFormData, formValidations, 'payFeeFormData', formValidations)}
                                                />
                                            </div>
                                            {formValidations && !formValidations.fields['dueAmount'].isValid && <p className="p-error">{formValidations.fields['dueAmount'].errorMsg}</p>}
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <label className="label mb-2 ">
                                            Payment Type<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                id="paymentType"
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={paymentModes?.filter(el => el.label !== "Cheque")}
                                                value={payFeeFormData.paymentType}
                                                onChange={(e) => {
                                                    onDropDownChange(e.target.value, 'paymentType', this, createFields, payFeeFormData, formValidations, 'payFeeFormData', formValidations);
                                                }}
                                                placeholder={
                                                    <div>
                                                        <span className="">Select Payment Type</span>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        {formValidations && !formValidations.fields['paymentType'].isValid && <p className="p-error">{formValidations.fields['paymentType'].errorMsg}</p>}
                                    </div>
                                    {payFeeFormData.paymentType && this.state.partialPayment && (
                                        <div className="col-12">
                                            <label className="label mb-2 ">
                                                Paid Amount<span className="ma-required">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <ClassetInputText
                                                    id="amountPaying"
                                                    icon={<CodeLinkIcon height={22} />}
                                                    className=""
                                                    height="48px"
                                                    width="368px"
                                                    keyfilter="int"
                                                    placeholder="Enter Paid Amount"
                                                    value={payFeeFormData.amount}
                                                    onChange={(e) => onTextChange(e.target.value, 'amountPaying', this, createFields, payFeeFormData, formValidations, 'payFeeFormData', formValidations)}
                                                />
                                            </div>
                                            {formValidations && !formValidations.fields['amountPaying'].isValid && <p className="p-error">{formValidations.fields['amountPaying'].errorMsg}</p>}
                                        </div>
                                    )}
                                    {(payFeeFormData.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' || payFeeFormData.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1') && (
                                        <>
                                            <div className="col-12">
                                                {payFeeFormData.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' && (
                                                    <label className="label mb-2 ">
                                                        Cheque Issued To<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                {payFeeFormData.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1' && (
                                                    <label className="label mb-2 ">
                                                        DD Issued To<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                <div className="mt-2">
                                                    <ClassetInputText
                                                        // id='feeType'
                                                        icon={<CodeLinkIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Enter Check Issued To"
                                                        value={this.state.chequeIssuedTo}
                                                        onChange={(e) => this.setState({ chequeIssuedTo: e.target.value })}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                            <div className="col-12">
                                                {payFeeFormData.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' && (
                                                    <label className="label mb-2 ">
                                                        Cheque No<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                {payFeeFormData.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1' && (
                                                    <label className="label mb-2 ">
                                                        DD No<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                <div className="mt-2">
                                                    <ClassetInputText
                                                        // id='feeType'
                                                        icon={<CodeLinkIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Enter Cheque No"
                                                        value={this.state.chequeNo}
                                                        onChange={(e) => this.setState({ chequeNo: e.target.value })}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                            <div className="col-12">
                                                {payFeeFormData.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' && (
                                                    <label className="label mb-2 ">
                                                        Cheque Date<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                {payFeeFormData.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1' && (
                                                    <label className="label mb-2 ">
                                                        DD Date<span className="ma-required">*</span>
                                                    </label>
                                                )}
                                                <div className="mt-2">
                                                    <ClassetCalendar
                                                        icon={<CalenderIcon height={20} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Select Date"
                                                        calendarMode="single"
                                                        value={this.state.chequeDate}
                                                        onChange={(e) => this.setState({ chequeDate: e.value })}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                            <div className="col-12">
                                                <label className="label mb-2 ">
                                                    Bank Name<span className="ma-required">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <ClassetInputText
                                                        // id='feeType'
                                                        icon={<CodeLinkIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Enter Bank Name"
                                                        value={this.state.bankName}
                                                        onChange={(e) => this.setState({ bankName: e.target.value })}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                            <div className="col-12">
                                                <label className="label mb-2 ">
                                                    Bank Branch Name<span className="ma-required">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <ClassetInputText
                                                        id="feeType"
                                                        icon={<CodeLinkIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Bank Branch Name"
                                                        value={this.state.bankBranchName}
                                                        onChange={(e) => this.setState({ bankBranchName: e.target.value })}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                        </>
                                    )}
                                    {(payFeeFormData.paymentType === '74b95cc8-9736-44a0-b2df-325595783c3b' ||
                                        payFeeFormData.paymentType === '1c98862e-46dc-4454-a120-7d15e2e56e2f' ||
                                        payFeeFormData.paymentType === '9d08ebab-2d3e-4a30-8460-4e4092fe30a1') && (
                                            <div className="col-12">
                                                <label className="label mb-2 ">Destination Bank</label>
                                                <div className="mt-2">
                                                    <ClassetDropdown
                                                        // id='feeType'
                                                        icon={<MailIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Enter Destination Bank Name"
                                                        options={this.state?.bankOptions}
                                                        value={this.state.destinationBank}
                                                        onChange={(e) => this.setState({ destinationBank: e.target.value })}
                                                    />
                                                </div>
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                        )}

                                    {(payFeeFormData.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
                                        payFeeFormData.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
                                        payFeeFormData.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
                                        payFeeFormData.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') && (
                                            <div className="col-12">
                                                {/* <label className="label mb-2 ">
                                                    Transaction Id<span className="ma-required">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <ClassetInputText
                                                        // id='feeType'
                                                        icon={<CodeLinkIcon height={22} />}
                                                        className=""
                                                        height="48px"
                                                        width="368px"
                                                        placeholder="Enter Transaction Id"
                                                        value={this.state.transctionId}
                                                        onChange={(e) => this.setState({ transctionId: e.target.value })}
                                                    />
                                                </div> */}
                                                {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                            </div>
                                        )}

                                    {payFeeFormData.paymentType && (
                                        <div className="col-12">
                                            <label className="label mb-2 ">
                                                Remarks<span className="ma-required"></span>
                                            </label>
                                            <div className="mt-2">
                                                <ClassetTextArea
                                                    id="feeType"
                                                    // icon={<CodeLinkIcon height={22}/>}
                                                    className=""
                                                    height="48px"
                                                    width="368px"
                                                    placeholder="Enter Remarks"
                                                    value={payFeeFormData.remarks}
                                                    onChange={(e) => onTextChange(e.target.value, 'remarks', this, createFields, payFeeFormData, formValidations, 'payFeeFormData', formValidations)}
                                                />
                                            </div>
                                            {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                        </div>
                                    )}

                                    <div className="flex justify-content-center mb-4 mt-4">
                                        <Button className="formSubmitButtonn mr-2" style={{ borderRadius: '12px', fontSize: '16px', backgroundColor: '#076EFF' }}

                                            // onClick={this.OnPayFee}
                                            onClick={this.handlePayment}

                                        >
                                            <AddIcon2 />
                                            <p className="formSubmitButtonnText">Pay Now</p>
                                        </Button>

                                        {/* <Button className='submitButtonn p-4 ' 
                                              onClick={this.OnPayFee}
                                            >
                                             <AddIcon2 />
                                                <p className='submitButtonnText'>Pay Now</p>
                                            </Button> */}
                                        <Button className="cancelButtonn w-4" onClick={this.onHandleCancelPayFee}>
                                            <p className="cancelButtonnText">Cancel</p>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingComponent />}
                        </>
                    </Dialog>
                    {this.state.isEnableRecipt && (
                        <NewFeeReciept
                            receiptData={this.state?.receiptData}
                            hideFeeReciept={() => {
                                this.setState({
                                    isEnableRecipt: false,
                                    receiptData: []
                                });
                            }}
                        />
                    )}
                    {this.state.isBulkfeeReceipts && (
                        <BulkFeeReciepts
                            receiptData={this.state?.termWiseReceiptInfo}
                            hideFeeReciept={() => {
                                this.setState({
                                    isBulkfeeReceipts: false,
                                    termWiseReceiptInfo: []
                                });
                            }}
                        />
                    )}
                    <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                </div>

                <Dialog
                    isopen={this.state.isInstallmentInfo}
                    visible={this.state.isInstallmentInfo}
                    width={"80vw"}
                    height={"80vh"}
                    draggable={false}
                    onHide={this.onHandleInstallmentInfo}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">Fee Installment Details</h3>
                            </div>
                        );
                    }}
                >
                    <>
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.installmentInfoData}
                            dataKey="id" lazy
                            responsiveLayout="scroll"
                            className="dataTableValuesText"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            rows={this.state.lazyParams.rows}
                        >
                            <Column header='Sl.No' alignHeader={'center'} className='col-1' body={(rowData, index) => {
                                return (<span>{index.rowIndex + 1}</span>)
                            }} />
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Fee Type"
                                filterField="feeType" field="feeType"
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Term Amount"
                                filterField="termWiseActualValue"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{`${rowData?.termAmt ? (Math.round((rowData?.termAmt) * 100) / 100) : Math.round((rowData?.termWiseActualValue) * 100) / 100}`}</div>;

                                }}
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Gst(%)"
                                filterField="gstPer" field="gstPer"
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Refund"
                                filterField="" field=""
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{`${rowData?.refundAmt ? (Math.round((rowData?.refundAmt) * 100) / 100) : 0}`}</div>;

                                }}
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Total Amount"
                                body={(rowData, { rowIndex }) => {
                                    const value = rowData.termWiseActualValue * (rowData.gstPer / 100) + rowData.termWiseActualValue;
                                    const roundedValue = Math.round(value * 100) / 100; // Round to nearest integer
                                    return <div className="flex justify-content-center">{roundedValue}</div>;
                                }}
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Paid Amount"
                                filterField="amountPaid" field="amountPaid"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">{` ${Math.round((rowData.amountPaid + ((rowData?.gstPaid || 0) - (rowData?.gstForRefundAmt || 0))) * 100) / 100}`}</div>;

                                }}
                            ></Column>
                            <Column
                                alignHeader={'center'}
                                sortable
                                bodyClassName="text-center"
                                header="Due Amount"
                                body={(rowData, { rowIndex }) => {
                                    return <div className="flex justify-content-center">
                                        {` ${Math.round(((rowData.termWiseActualValue * (rowData.gstPer / 100) + rowData.termWiseActualValue) - (rowData.amountPaid + ((rowData?.gstPaid || 0) - (rowData?.gstForRefundAmt || 0)))) * 100) / 100}`}
                                    </div>;
                                }}
                            ></Column>

                        </DataTable>
                        {this.state.isLoading && <LoadingComponent />}
                    </>
                </Dialog>
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

export default CollectFee;
