import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import LoadingComponent from '../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { MELUHA_BOARD_ID, PDF_FILE_SIZE, PPT_FILE_SIZE, VIDEO_FILE_SIZE } from './../../store/actionTypes';
import { contentSizeCheck, trimObj } from './../../utile';
import { ContetOrigin, ContentOrigins, getFileTypes, getContentResourseType } from '../../utile/index';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../utile/formHelper';
import ContentPlayers from './contentPlayers';
import {

    getContentPreSignedUrl,

    uploadContent
} from './../../store/actions';
import InputTextB from './../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import withRouter from '../lib/withRouter';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetDropdown from '../../classetComponents/classetDropDown';


const formFields = [
    {
        "Type": "Text",
        "Label": "Content Language",
        "FieldName": "contentLanguage",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "File Type",
        "FieldName": "contentFileType",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Origin",
        "FieldName": "contentOrigin",
        "Required": true,
    },
    {
        "Type": "Text",

        "Label": "Title",
        "FieldName": "contentName",
        "Required": true,
        // "MinLength": 8,
    },
    {
        "Type": "Text",
        "Label": "Target URL or Key",
        "FieldName": "contentFile",
        "Required": true,
        // "MinLength": 8,
    },
]

class AddFromLocal extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(formFields, {});
        this.state = {
            fileSelected: null,
            contentInfo: this.formFields.data,
            formValidations: this.formFields.formValidations,
            resourceTitle: '',
            showTestDailogModel: false,
            contentLanguage: this?.props?.parentId ? '' : 'en'
        }
    }


    showTestDialog = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, this.state.contentInfo);
        if (!formStatus.formValidations.isFormValid) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
        } else {
            this.setState({ showTestDailogModel: true });
        }
    }


    hideTestDialog = () => {
        this.setState({ showTestDailogModel: false });
    }

    onSaveResource = () => {
        let { contentInfo } = this.state;
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(contentInfo));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
        } else {
            let cn = {
                ...contentInfo,
                contentId: uuidv4(),
                contentResourceType: getContentResourseType(contentInfo.contentFileType),
                source: 1
            };

            cn = trimObj(cn)

            this.props.addContent(cn);
        }
    };



    filterContent = (resourceType) => {
        return new Promise((res, rej) => {
            const filteredContent =
                Array.isArray(this.props.contentInfo) &&
                this.props.contentInfo.filter((content) => content.contentResourceType === resourceType);
            this.setState(
                {
                    filteredContent
                },
                () => res()
            );
        });
    };

    validateNameNPriorityOfContent = (key, compare) => {
        let isDuplicate;

        if (isDuplicate) {
            if (key === 'priority') {
                this.setState(
                    {
                        duplicatePriority: true
                    },
                    () => {
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Change priority', life: 3000 });

                    }

                );
            }
            if (key === 'contentName') {
                this.setState(
                    {
                        duplicateName: true
                    },
                    () => this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'File name already exist', life: 3000 })
                );
            }
        } else {
            if (key === 'priority') {
                this.setState({
                    duplicatePriority: false
                });
            }
            if (key === 'contentName') {
                this.setState({
                    duplicateName: false
                });
            }
        }

        return isDuplicate;
    };


    uploadFile = () => {
        let {
            uploadedFileName,
            fileActualFileName,
            selectedFileType,
            fileExtension,
            resourceTitle,
            contentLanguage
        } = this.state;
        const userGivenFileName = uploadedFileName.slice(0, uploadedFileName.lastIndexOf('.'));



        resourceTitle = resourceTitle.trim();
        if (resourceTitle == '') {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter title', life: 3000 });
            return
        }
        if (contentLanguage == '') {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter language', life: 3000 });
            return
        }
        // let duplicatePriority = false;
        let duplicateName = false;


        if (this.props.contentInfo && this.props.contentInfo.length > 0) {
            this.props.contentInfo.forEach((x) => {
                // if (
                //     x.priority === this.state.uploadedFilePriority &&
                //     selectedFileType.toLowerCase().includes(x.contentResourceType.toLowerCase())
                // ) {
                //     duplicatePriority = true;
                // }
                if (
                    x.contentName.toLowerCase() === resourceTitle.toLowerCase() &&
                    selectedFileType.toLowerCase().includes(x.contentResourceType.toLowerCase())
                ) {
                    duplicateName = true;
                }
            });
        }
        // if (this.state.uploadedFilePriority == "") {
        //     this.snackBarOpen('Please enter priority.');
        // } else 


        // else if (duplicatePriority) {
        //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Priority matches with another resource.. Kindly change it.', life: 3000 });
        // }

        if (duplicateName) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Name matches with another resource.. Kindly change it.', life: 3000 });
        } else {
            if (userGivenFileName !== fileActualFileName && fileExtension !== '') {
                const selectedFile = new File([...this.state.file], userGivenFileName + '.' + fileExtension, {
                    type: selectedFileType
                });

                this.setState({ file: selectedFile }, () => {
                    this.props.getContentPreSignedUrl(userGivenFileName + '.' + fileExtension);
                });
            } else {
                this.props.getContentPreSignedUrl(userGivenFileName + '.' + fileExtension);
            }
        }
    };

    getContentType = (type) => {

        switch (type) {
            case 'mp4':
            case 'mpg':
            case 'avi':
            case 'wmv':
            case 'mov':
            case 'flv':
                return 'mp4';

            case 'ppt':
                return 'ppt';

            case 'pdf':
                return 'pdf';

        }

    }

    onlyUpdateContent = () => {

        const { file, uploadedFileName, fileExtension, fileType } = this.state;
        const name = uploadedFileName;
        let type = fileType;
        let resourceType = '';
        type.toLowerCase();

        if (type.includes('ppt') || type.includes('powerpoint')) {
            type = 'ppt';
            resourceType = 'ppt';
        }
        if (
            type.includes('mp4') ||
            type.includes('mpg') ||
            type.includes('avi') ||
            type.includes('wmv') ||
            type.includes('mov') ||
            type.includes('flv')
        ) {
            resourceType = 'video';
            type = type.split('/')[1];
        }
        if (type.includes('pdf')) {
            type = 'pdf';
            resourceType = 'pdf';
        }
        let saveoBJ = {
            contentFileType: this.getContentType(type),
            contentStorageKey: this.props.contentPreSignedUrl.contentStorageKey,
            contentId: uuidv4(),
            contentResourceType: resourceType,
            contentStorageBucketName: this.props.contentPreSignedUrl.contentStorageBucketName,
            contentFileName: name,
            contentName: this.state.resourceTitle,
            priority: this.state.uploadedFilePriority || '',
            contentLanguage: this.state.contentLanguage,
            source: 1,

        };
        if (this.props.parentId) {
            saveoBJ.parentId = this.props.parentId
        }
        saveoBJ = trimObj(saveoBJ);
        this.props.addContent(saveoBJ);
    }


    uploadFileNUpdateContent = () => {
        if (this.props.contentPreSignedUrl) {
            if (this.props.contentPreSignedUrl.presignedURL) {

                this.props.uploadContent(this.props.contentPreSignedUrl.presignedURL, this.state.file);
                // const { file, uploadedFileName, fileExtension, fileType } = this.state;
                // const name = uploadedFileName;
                // let type = fileType;
                // let resourceType = '';
                // type.toLowerCase();

                // if (type.includes('ppt')) {
                //     type = 'ppt';
                //     resourceType = 'ppt';
                // }
                // if (
                //     type.includes('mp4') ||
                //     type.includes('mpg') ||
                //     type.includes('avi') ||
                //     type.includes('wmv') ||
                //     type.includes('mov') ||
                //     type.includes('flv')
                // ) {
                //     resourceType = 'video';
                //     type = type.split('/')[1];
                // }
                // if (type.includes('pdf')) {
                //     type = 'pdf';
                //     resourceType = 'pdf';
                // }

                // let saveoBJ = {
                //     contentFileType: this.getContentType(type),
                //     contentStorageKey: this.props.contentPreSignedUrl.contentStorageKey,
                //     contentId: uuidv4(),
                //     contentResourceType: resourceType,
                //     contentStorageBucketName: this.props.contentPreSignedUrl.contentStorageBucketName,
                //     contentFileName: name,
                //     contentName: this.state.resourceTitle,
                //     priority: this.state.uploadedFilePriority || '',

                //     source: 1
                // };

                // saveoBJ = trimObj(saveoBJ);

                // this.props.addContent(saveoBJ);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contentPreSignedUrl !== this.props.contentPreSignedUrl) {
            if (this.props.contentPreSignedUrl) {
                this.uploadFileNUpdateContent();
            }
        }

        if (prevProps.isUploading != this.props.isUploading && this.props.isUploading == false) {

            this.onlyUpdateContent()
        }
    }

    render() {
        console.log(this.props, 'props')
        const { contentInfo, formValidations, contentLanguage } = this.state;
        let contentLang = this?.props?.contentLanguagesList?.find((vl) => vl?.code === contentLanguage)

        return <div className='upload-resource '>
            <p className='title'>Upload Resources</p>
            <div className="grid col-12 ma-w100p -mx-5 " >
                <div className="col-3 lg:col-3 sm:col-5">
                    <ClassetInputText required={true} label={'Title'} placeholder={'Title '} onChange={(e) => {
                        this.setState({
                            resourceTitle: e.target.value
                        });
                    }} />
                </div>
                <div className="col-3 lg:col-3 sm:col-5">
                    <ClassetInputText width={'100%'} label={'File'} placeholder={'File '} value={this.state.uploadedFileName}
                        disabled
                    />
                    {/* <p className='ma-label-s2'>File<span className='ma-required'>*</span></p>
                    <InputTextB info={FIELDS_INFO.RESOURCE_FILE} id="rtrff">
                        <InputText
                            value={this.state.uploadedFileName}
                            disabled
                            className='p-inputtext-style1  w-full' />
                    </InputTextB> */}
                </div>
                <div className="col-3 lg:col-3 sm:col-5">
                    <ClassetDropdown label={'Select Content Language'} options={this?.props?.contentLanguagesList} value={contentLang?.code} placeholder="Select Content Language" onChange={(e) => {
                        this.setState({
                            contentLanguage: e.value
                        });
                        // onDropDownChange(e.value, 'contentLanguage', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                    }} optionLabel='name'
                        optionValue='code' />
                </div>
                <div className="mt-4 lg:col-3 grid sm:col-5">
                    <div className='mt-4'>
                        <label htmlFor="inputfile" className='select-file'>Select File</label>
                        <input
                            type="file"
                            className="form-control"
                            id="inputfile"
                            hidden={true}
                            accept={''}
                            //onChange={this.handleChange}

                            onChange={async (e) => {
                                const file = e.target.files;
                                const name = file[0] && file[0].name;
                                if (!name) {
                                    //this.snackBarOpen('Please select file');
                                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select file', life: 3000 });
                                    this.setState({
                                        uploadedFileName: '',
                                        uploadedFilePriority: ''
                                    });
                                    return;
                                }
                                const fileNameWithOutExtension = name && name.slice(0, name.lastIndexOf('.'));

                                const selectedFileType = file[0] && file[0].type;
                                let type = file[0] && file[0].type;
                                const fileSize = file[0].size;
                                const actualFileName = name.slice(0, name.lastIndexOf('.'));
                                const extension = name.slice(name.lastIndexOf('.')).split('.')[1];
                                let resourceType = '';
                                type.toLowerCase();
                                let isValidFile;

                                if (type.includes('ppt') || type.includes('powerpoint')) {
                                    type = 'ppt';
                                    resourceType = 'ppt';
                                    await this.filterContent(resourceType);
                                    isValidFile = true;
                                    if (contentSizeCheck(fileSize, PPT_FILE_SIZE)) {
                                        this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-10mb', life: 3000 });
                                        this.setState({
                                            uploadNameError: true,
                                            uploadedFileName: ''
                                        });
                                        return;
                                    }
                                }
                                if (
                                    type.includes('mp4') ||
                                    type.includes('mpg') ||
                                    type.includes('avi') ||
                                    type.includes('wmv') ||
                                    type.includes('mov') ||
                                    type.includes('flv')
                                ) {
                                    resourceType = 'video';
                                    await this.filterContent(resourceType);
                                    type = type.split('/')[1];
                                    isValidFile = true;
                                    if (contentSizeCheck(fileSize, VIDEO_FILE_SIZE)) {
                                        this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-200mb', life: 2000 });
                                        this.setState({
                                            uploadNameError: true,
                                            uploadedFileName: ''
                                        });
                                        return;
                                    }
                                }
                                if (type.includes('pdf')) {
                                    type = 'pdf';
                                    resourceType = 'pdf';
                                    await this.filterContent(resourceType);
                                    isValidFile = true;
                                    if (contentSizeCheck(fileSize, PDF_FILE_SIZE)) {
                                        this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-5mb', life: 2000 });
                                        this.setState({
                                            uploadNameError: true,
                                            uploadedFileName: ''
                                        });
                                        return;
                                    }
                                }

                                if (isValidFile) {
                                    this.validateNameNPriorityOfContent('contentName', actualFileName);

                                    this.setState(
                                        {
                                            file: file,
                                            fileLength: file[0].size,
                                            fileActualFileName: actualFileName,
                                            selectedFileType,
                                            fileExtension: extension,
                                            fileType: file[0] && file[0].type,
                                            uploadedFilePriority: '',
                                            uploadedFileName: fileNameWithOutExtension,
                                            uploadNameError: false
                                        });
                                } else {
                                    this.setState(
                                        {
                                            file: '',
                                            uploadedFileName: null,
                                            uploadedFilePriority: ''
                                        },
                                        () => (document.getElementById('inputfile').value = null)
                                    );
                                    this.toast.show({ severity: 'error', summary: 'Invalid File type', detail: 'Select A valid file', life: 3000 });
                                }
                            }}
                        //disabled={this.props.bulkUserUploadRequested}
                        />
                    </div>
                    <div className='mt-1'>
                        <Button label="Upload" className='p-button-success mt-2 ml-4 up-btn'
                            onClick={() => {
                                if (this.state.file && this.state.file.length) {
                                    this.uploadFile();
                                } else {
                                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please Select Pdf, Ppt, Video or Quiz', life: 3000 });
                                }
                            }
                            }
                            disabled={(this.state.resourceTitle == '' || !this.state.file || this.state.contentLanguage == '') ? true : false}
                            icon="pi pi-cloud-upload"
                        />
                    </div>
                </div>

            </div>

            <p className='upload-note'><span>Note:</span> Supported video formats mp4, mpg, avi, wmv, mov, flv and files format in pdf, ppt</p>
            <div className='third-party' style={{display: 'none'}}>
                <p className='title'>Upload 3rd Party Resources</p>
                <div className="grid ma-w100p mt-3">
                    <div className="col-2 p-md-2  md:col-3">
                        <ClassetDropdown required={true} label={'Select Content Language'} options={getFileTypes()} value={contentInfo.contentFileType} placeholder="Select FileType" onChange={(e) => {
                            onDropDownChange(e.target.value, 'contentFileType', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                        }} optionLabel='displayName'
                            optionValue='value' />
                        {/* <p className='ma-label-s2'>Resource Type<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.RESOURCE_TYPE} id="rftyr">
                            <Dropdown value={contentInfo.contentFileType}
                                options={getFileTypes()}
                                optionLabel='displayName'
                                optionValue='value'
                                className=' w-full'
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'contentFileType', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                                }}
                                placeholder="Select FileType" />
                        </InputTextB> */}
                        {formValidations && !formValidations.fields['contentFileType'].isValid && <p className="p-error">{formValidations.fields['contentFileType'].errorMsg}</p>}
                    </div>
                    <div className="col-2 p-md-2  md:col-3">
                        <ClassetDropdown required={true} label={'Select Content Language'} options={ContentOrigins} value={contentInfo.contentOrigin} placeholder="Select Origin" onChange={(e) => {
                            onDropDownChange(e.target.value, 'contentOrigin', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                        }} optionLabel='displayName'
                            optionValue='value' />
                        {/* <p className='ma-label-s2'>Origin<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.RESOURCE_ORIGIN} id="rzz">
                            <Dropdown value={contentInfo.contentOrigin}
                                options={ContentOrigins}
                                optionLabel='displayName'
                                optionValue='value'
                                className='w-full'
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'contentOrigin', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                                }}
                                placeholder="Select Origin" />
                        </InputTextB> */}
                        {formValidations && !formValidations.fields['contentOrigin'].isValid && <p className="p-error">{formValidations.fields['contentOrigin'].errorMsg}</p>}
                    </div>

                    <div className="col-4 p-md-4  md:col-3">
                        <ClassetInputText width={'100%'} required={true} label={'Title'} placeholder={'Title '} value={contentInfo.contentName} onChange={(e) => { onTextChange(e.target.value, 'contentName', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations') }}
                        />
                        {/* <p className='ma-label-s2'>Title <span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.RESOURCE_TITLE} id="aqs">
                            <InputText
                                value={contentInfo.contentName}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'contentName', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                                }}
                                className='p-inputtext-style1  w-full' />
                        </InputTextB> */}


                        {formValidations && !formValidations.fields['contentName'].isValid && <p className="p-error">{formValidations.fields['contentName'].errorMsg}</p>}
                    </div>
                    <div className="col-4 p-md-4  md:col-3">
                        <ClassetInputText width={'100%'} required={true} label={'Target URL or Key'} placeholder={'Target URL or Key '} value={contentInfo.contentFile} onChange={(e) => {
                            onTextChange(e.target.value, 'contentFile', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                        }}
                        />
                        {/* <p className='ma-label-s2'>Target URL or Key <span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.RESOURCE_URLKEY} id="asw">
                            <InputText
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'contentFile', this, formFields, contentInfo, formValidations, 'contentInfo', 'formValidations')
                                }}
                                value={contentInfo.contentFile} 
                                className='p-inputtext-style1  w-full' />
                        </InputTextB> */}
                        {formValidations && !formValidations.fields['contentFile'].isValid && <p className="p-error">{formValidations.fields['contentFile'].errorMsg}</p>}
                    </div>
                </div>

                <div className='flex gap-4'>
                    <Button label='Save Resource' className='' onClick={this.onSaveResource} />
                    <Button label="Test Resource" onClick={this.showTestDialog} className='ma-m-lr10 p-button-outlined' />
                </div>
            </div>
            {this.state.showTestDailogModel &&
                <ContentPlayers contentInfo={this.state.contentInfo}
                    onCloseDialog={this.hideTestDialog}
                />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.props.isUploading && <LoadingComponent />
            }
        </div>;
    }

}



const mapStateToProps = (state) => ({
    contentPreSignedUrl:
        state.contentPreSignedUrl && state.contentPreSignedUrl.response && state.contentPreSignedUrl.response,
    uploadContent: state.uploadContent && state.uploadContent,
    isUploading: state.uploadContent && state.uploadContent.isUploading,
    contentInfo:
        state.contentInfo &&
        state.contentInfo.response &&
        state.contentInfo.response.Item &&
        state.contentInfo.response.Item.contentDetails &&
        state.contentInfo.response.Item.contentDetails,
});




export default connect(mapStateToProps, {
    getContentPreSignedUrl,
    uploadContent,
})(withRouter(AddFromLocal));