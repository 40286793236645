import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';

export default class assignTransport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignTransportForm: false,
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            vehicleDropdown: [],
            selectedBuses: {},
            selectedStops: {},
            routeData: [],
            selectedStudentPickup: {},
            selectedStudentDrop: {},
            planId: '',
            busNo: null,
            boardId: null,
            classId: null,
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        };
        this.service = new Service();
    }
    getAssignTransportData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });

        let url = `${baseUrlAdmin}/transport-route/transport-receivables`;

        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData.boardId,
            gradeId: this.state.dropdownsData.classId,
            sectionId: this.state.dropdownsData.sectionId,
            academicYear: academicYear
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const transportReceivables = res?.res?.data;
                /*  const selectedBuses = {};
                 const selectedStops = {};
                 const selectedStudentPickup = {};
                 const selectedStudentDrop = {};
 
                 users.forEach((user, index) => {
                     selectedBuses[index] = user.transportRoute;
                     selectedStudentPickup[index] = user.pickStop;
                     selectedStudentDrop[index] = user.dropstop;
                     const route = this.state.routeData.find((route) => route._id === user.transportRoute);
                     if (route) {
                         selectedStops[index] = route.stops;
                     }
                 }); */

                this.setState({
                    transportReceivables,
                    /*   users,
                      selectedBuses,
                      selectedStops,
                      selectedStudentPickup,
                      selectedStudentDrop, */
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    setDropdownValues = (dropdownsData) => {

        this.setState(
            {
                dropdownsData
            },
            () => (dropdownsData?.boardId && dropdownsData?.branchId) && this.getAssignTransportData()
        );
    };
    render() {
        return (
            <>
                <div className="ml-5 mt-5 flex justify-content-start align-items-center">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                </div>
                <div className="card datatable-crud-demo mt-5">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.transportReceivables}
                        dataKey="id"
                        paginator
                        // lazy
                        responsiveLayout="scroll"
                        showGridlines
                        onPage={this.onPage}
                        rows={10}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}
                    >
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="S.No."
                            className="datatable-cell"
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                    </>
                                );
                            }}
                        />

                        {this.state.dropdownsData?.classId ? <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="sectionName" field="section" header="Section" body={(rowData, index) => {
                            return (
                                <>
                                    <p>{rowData?.sectionName}</p>
                                </>
                            );
                        }} /> : <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="gradeName" field="class" header="Grade" body={(rowData, index) => {
                            return (
                                <>
                                    <p>{rowData?.gradeName}</p>
                                </>
                            );
                        }} />}


                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="studentStrength"
                            field="studentStrength"
                            header="Transport assigned student strength"
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="totalFee"
                            field="totalFee"
                            header="Total Fee Amount"
                        />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="concession" field="concession" header="Concession" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="net_payble" field="net_payble" header="Net Payble"  body={(rowData, index) => {
                            return (
                                <>
                                    <p>{rowData?.totalFee - rowData?.concession}</p>
                                </>
                            );
                        }} />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="amountPaid" field="amountPaid" header="Paid" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="balance" field="balance" header="Balance"  body={(rowData, index) => {
                            return (
                                <>
                                    <p>{(rowData?.totalFee - rowData?.concession  - rowData?.amountPaid)}</p>
                                </>
                            );
                        }}/>
                    </DataTable>
                </div>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
