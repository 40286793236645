import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, baseUrlForLongRunningTask, primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { AddIconInGrade, AssignFormIcon, BoardsIconActive, BoardsIconInActive, ClassetEditIcon, CrossInBox, DeleteIconClasset, DownloadBoxIcon, EditBox, EyeIconClasset, FormatsIcon, GradesIcon, GradesIconActive, PlusInBoxIcon, SubjectIcon, TopicIcon, UploadBoxIcon } from '../../svgIcons';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { sortCurriculam, userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../../services';
import ContentPlayer from '../../contentMaster/contentPlayers';
import PDFViewer from 'mgr-pdf-viewer-react';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import AddFromRepo from '../../contentMaster/addFromRepo';
import AddFromLocal from '../../contentMaster/addFromLocal';
import { getBoardsData, selectChapter } from '../../../store/actions';
import _, { cloneDeep } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { ClassetVideoIcon } from '../../svgIcons';
import './styles.scss'
import { CircularProgressbar, buildStyles, } from 'react-circular-progressbar';
import ChangingProgressProvider from './ChangingProgressProvider';

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

let percentage = 50;

const chapters = [
    {
        id: '1',
        chapterName: 'Chapter 1 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '2',
        chapterName: 'Chapter 2 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '3',
        chapterName: 'Chapter 3 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '4',
        chapterName: 'Chapter 4',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '5',
        chapterName: 'Chapter 5',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '6',
        chapterName: 'Chapter 6 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '7',
        chapterName: 'Chapter 7',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '8',
        chapterName: 'Chapter 8',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '9',
        chapterName: 'Chapter 9 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '10',
        chapterName: 'Chapter 10',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '11',
        chapterName: 'Chapter 11',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '12',
        chapterName: 'Chapter 12',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },

]


const TabElements = [
    {
        tabName: 'Video', value: 'mp4'
    }, {
        tabName: 'Interactions', value: 'zip'
    }, {
        tabName: 'Ebooks', value: 'ebook'
    }, {
        tabName: 'PDF', value: 'pdf'
    }, {
        tabName: 'Quiz', value: 'quiz'
    },
]


let selectedTopicResourceTypes = [
    {
        "label": "VIDEO",
        "value": "mp4",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "Interactions",
        "value": "ppt",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "Ebooks",
        "value": "ebook",
        "isActive": true,
        "isSelected": false
    },

    {
        "label": "PDF",
        "value": "pdf",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "QUIZ",
        "value": "quiz",
        "isActive": true,
        "isSelected": false
    }
]

const contentResources = [
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
]
class DigitalLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddFormatDialogOpen: false,
            isEditResourceDailogOpen: false,
            isDeleteResourceOpen: false,
            selectedChapter: {

            },
            selectedTopic: {

            },
            selectedResource: {
            },
            assign3rdParty: false,
            curriculumInfo: {},
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            chapters: [],
            topics: [],
            resources: [],
            selectedChapter: '',
            selectedTab: 'mp4'

        };
        this.service = new Service()
    }

    componentDidMount() {
        this.props.getBoardsData(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.boardId == null && this.props.boards.length) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const _classes = [];
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.boards[0]?.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
            }
            this.setState({
                boardId: this.props.boards[0]?.boardId,
                selectedBoardName: this.props.boards[0]?.boardName,
                classes: _classes,
            }, () => this.onChangeClass(_classes[0]?.value))
        }
        console.log(this.state);
        if (this.state.content && this.state.content?.contentDetails.length > 0) {
            percentage = this.calculatePlayCountPercentage(this.state.content);
        }
    }

    calculatePlayCountPercentage(content) {
        const totalContentItems = content.contentDetails.length;
        const playedContentItems = content.contentDetails.filter(item => item.playCount > 0).length;
        const percentage = (playedContentItems / totalContentItems) * 100;
        const roundedPercentage = parseFloat(percentage.toFixed(2));
        return roundedPercentage;
    }

    OpenTopicsForChapter = (chapterId) => {
        if (this.state.selectedChapter == chapterId) {
            this.setState({
                selectedChapter: ''
            })
        } else {
            this.setState({
                selectedChapter: chapterId
            })
        }
    }

    getResourcesFromSelectedTopicOrChapter = (topic, type) => {
        this.getResourcesByTopic(topic, type)
    }
    addClick = (type) => {
        this.setState({
            selectedType: type,
            isShowAddDialog: true
        });
    }

    getResourcesByTopic = (topic, type) => {
        this.setState({
            videos: [],
            ppts: [],
            pdfs: [],
            quizes: [],
            selectedResource: {},
            resources: [],
            isLoading: true,
        })
        let url;
        if (type == 'topic') {
            url = `${baseUrlAdmin}/board/${this.state.boardId}/class/${this.state.classId}/group/${this.state.groupId}/subject/${this.state.subjectId}/chapter/${this.state.chapterId}/topic/${topic?.topicId}?etag=true`
        } else {
            url = `${baseUrlAdmin}/board/${this.state.boardId}/class/${this.state.classId}/group/${this.state.groupId}/subject/${this.state.subjectId}/chapter/${topic?.chapterId}/content`
        }
        
        let videos = [];
        let ppts = [];
        let pdfs = [];
        let quizes = [];

        this.service.get(url, true).then((data) => {
            const etag = data && data.etag;
            if (data && data.res && data.res.Item) {
                let resources = data.res.Item;
                resources?.contentDetails && resources?.contentDetails?.map((resource) => {
                    if (resource?.contentResourceType == 'ppt') {
                        ppts.push(resource)
                    }
                    else if (resource?.contentResourceType == 'pdf') {
                        pdfs.push(resource)
                    }
                    else if (resource?.contentResourceType == 'quiz') {
                        quizes.push(resource)
                    }
                    else if (resource?.contentResourceType == 'video') {
                        videos.push(resource)
                    }
                })
                this.setState({
                    selectedTopic: type == 'topic' ? topic : {},
                    selectedChapter: type == 'chapter' ? topic : {},
                    videos,
                    ppts,
                    pdfs,
                    quizes,
                    isLoading: false,
                    contentEtag: etag,
                    topicId: topic.topicId,
                    content: { ...data.res.Item },
                }, () => topic?.resources?.length ? this.getResourcesFromResourseType(topic?.resources[0]) : this.getResourcesFromResourseType({
                    "label": "VIDEO",
                    "value": "mp4",
                    "isActive": true
                }))
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }

    onSelectContent = (checkedContetForAssign, contentRepo) => {
        this.setState({
            checkedContetForAssign, contentRepo
        })
    };

    getResourcesFromResourseType = (resource) => {
        let content = [], selectedTopicResourceTypesIndex = 0
        if (resource?.value == 'ppt') {
            content = this.state.ppts || []
            selectedTopicResourceTypesIndex = 1
        }
        if (resource?.value == 'pdf') {
            content = this.state.pdfs || []
            selectedTopicResourceTypesIndex = 2
        }
        if (resource?.value == 'quiz') {
            content = this.state.quizes || []
            selectedTopicResourceTypesIndex = 3
        }
        if (resource?.value == 'mp4') {
            content = this.state.videos || []
            selectedTopicResourceTypesIndex = 0
        }

        if (this.state.selectedChapter?.chapterId || this.state.selectedTopic?.topicId)
            this.setState({
                selectedResource: resource,
                selectedTopicResourceTypesIndex: selectedTopicResourceTypesIndex,
                resources: content,
            })
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null, curriculumInfo: null,
            isShowSubjects: false, resources: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
        this.setState({
            classes: _classes,
            boardId,
            chapters: [],
            topics: [],
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            selectedTopic: {},
            selectedResource: {
            },
            selectedBoardName: selectedBoardName,
            resources: []
        });

    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.find((classd) => classd.value == classId)
        this.setState({
            classId: classId,
            selectedClassName: selectedClass?.label,
            groupId: selectedClass?.groupId,
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            selectedTopic: {},
            curriculumInfo: {},
            selectedResource: {
            },
            resources: []
        }, () => this.getCurriculumData());
    };

    onChangeSubject = (subjectId) => {
        let chaptersBySubject = this.state.curriculumInfo?.subjects?.find((subject) => subject.subjectId == subjectId).chapters
        let chapterIdBySubject = chaptersBySubject && chaptersBySubject.length > 0 ? chaptersBySubject[0].chapterId : null
        this.setState({
            chapters: chaptersBySubject,
            subjectId: subjectId,
            chapterId: chapterIdBySubject,
            topics: chaptersBySubject?.find((chapter) => chapter.chapterId == chapterIdBySubject)?.topics,
            selectedTopic: {},
            selectedChapter: {},
            selectedResource: {},
            resources: []
        });
    };

    onChangeChapter = (chapterId) => {
        let topicsByChapter = this.state.chapters?.find((chapter) => chapter.chapterId == chapterId).topics
        this.setState({
            topics: topicsByChapter,
            chapterId: chapterId,
            selectedResource: {},
            resources: [],
            selectedTopic: {},
            selectedChapter: {},
        });
    };

    getCurriculumData = () => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service.get(url, true).then((data) => {
                const etag = data && data.etag;
                if (data && data.res && data.res.Item) {
                    let sortedData = sortCurriculam(data.res.Item)
                    this.setState({
                        curriculumInfo: sortedData,
                        isLoading: false,
                        subjectId: sortedData?.subjects[0]?.subjectId,
                        chapters: sortedData?.subjects[0]?.chapters,
                        chapterId: sortedData?.subjects[0]?.chapters[0]?.chapterId,
                        topics: sortedData?.subjects[0]?.chapters[0]?.topics,
                        etag
                    }, () => this.getResourcesFromSelectedTopicOrChapter(sortedData?.subjects[0]?.chapters[0]?.topics[0], 'topic'));
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                isLoading: false,

            })
        }
    };

    updateContent = (content) => {

        this.setState({
            isLoading: true
        });
        content.chapterId = (content.chapterId == null) ? this.state.selectedChapter?.chapterId : content.chapterId

        if (content && content.contents && content.contents.length) {
            content.contents.forEach(ct => {
                if (ct.childContent) {
                    delete ct.childContent
                }
            })
        }
        const url = `${baseUrlForLongRunningTask}/long/content`;
        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {
            if (data && data.res && data.res.success) {
                this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully modified, modified file may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: this.state.isBaseBoard ? 'upload' : null,
                    selectedParentData: null, contentParentId: null
                }, () => {
                    if (this.state.selectedTopic?.topicId) {
                        this.getResourcesFromSelectedTopicOrChapter(this.state.selectedTopic, 'topic')
                    } else {
                        this.getResourcesFromSelectedTopicOrChapter(this.state.selectedChapter, 'chapter')
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    assignContentTo = () => {
        const { checkedContetForAssign, } = this.state;
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId, selectedChapter, } = this.state;
        if (!checkedContetForAssign || !checkedContetForAssign.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
            return;
        }
        if (boardId && classId && groupId && subjectId && (chapterId || selectedChapter.chapterId) && (topicId || true)) {
            const previousContentInfo = _.cloneDeep(this.state.content.contentDetails) || [];
            if (checkedContetForAssign && checkedContetForAssign.length) {
                checkedContetForAssign.forEach((contentToAssign) => {
                    let record = previousContentInfo.find((a) => a.contentId == contentToAssign.contentId);
                    if (!record) {
                        let cont = _.cloneDeep(contentToAssign);
                        cont.source = 2;
                        cont.childCurriculumContentKeys = undefined;
                        let filtered = previousContentInfo.filter((conent) => conent.contentFileType.toLowerCase() == cont.contentFileType.toLowerCase());
                        cont.priority = filtered.length + 1;
                        previousContentInfo.push(cont);
                    }
                });

                const content = {
                    boardId,
                    classId,
                    groupId,
                    subjectId,
                    chapterId,
                    topicId,
                    contents: previousContentInfo
                };

                this.updateContent({ ...content });
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
                return;
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class', life: 3000 });
            return;
        }

    };

    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button className='formCancelButton' onClick={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null, selectedParentData: null, contentParentId: null, border: 1 }) }} >
                <p className='formCancelButtonText'>Cancel</p>
            </Button>
            {
                this.state.resourseOrigin == 'repo' && <>
                    <Button label={`Assign`} className='formSubmitButton' onClick={this.assignContentTo} >

                    </Button>
                </>
            }
        </div>)
    };

    displayDurationOrPages = (content) => {
        switch (content.contentResourceType) {
          case 'video':
            return `Run Time: ${Math.floor(content.duration / 60)}m:${Math.floor(content.duration % 60)}s`;
          case 'pdf':
            return `Pages: ${content.pages}`;
          case 'ppt':
            return `Slides: -`;
          default:
            return 'Run Time: -';
        }
    };

    handleVideoIconClick = (resource) => {
        const updatedResource = { ...resource };
        let updatedContentInfo = [];
        updatedResource.playCount = (updatedResource.playCount || 0) + 1;
        this.setState({ contentData: updatedResource, isShowPreview: true });
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId, selectedChapter, } = this.state;
        if (boardId && classId && groupId && subjectId && (chapterId || selectedChapter.chapterId) && (topicId || true)) {
            const previousContentInfo = _.cloneDeep(this.state.content.contentDetails) || [];
            if (updatedResource) {
                let record = previousContentInfo.find((a) => a.contentId == resource.contentId);
                if (!record) {
                    let cont = _.cloneDeep(resource);
                    let filtered = previousContentInfo.filter((content) => content.contentFileType.toLowerCase() == cont.contentFileType.toLowerCase());
                    cont.priority = filtered.length + 1;
                    previousContentInfo.push(cont);
                }
                const index = previousContentInfo.findIndex(item => item.contentId === updatedResource.contentId);
                if (index !== -1) {
                    updatedContentInfo = previousContentInfo.map((item, i) => {
                        if (i === index) {
                            return { ...item, playCount: updatedResource.playCount };
                        }
                        return item;
                    });
                    console.log("Updated previousContentInfo:", updatedContentInfo);
                    this.setState({ previousContentInfo: updatedContentInfo });
                } else {
                    console.log("Resource not found in previousContentInfo.");
                }
            }
            const content = {
                boardId,
                classId,
                groupId,
                subjectId,
                chapterId,
                topicId,
                contents: updatedContentInfo
            };
            this.updateContent({ ...content });
        }
    };

    render() {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        return (
            <div className=''>

                <div className='grid m-2'>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props.boards || []} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                            optionValue="boardId" />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={this.state?.classes || []}
                            value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Subject'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Subject'} options={this.state?.curriculumInfo?.subjects || []}
                            value={this.state.subjectId} onChange={(e) => this.onChangeSubject(e.value)} optionLabel="subjectName" optionValue="subjectId" />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown label={'Chapter'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Chapter'} options={this.state?.chapters || []}
                            value={this.state.chapterId} onChange={(e) => this.onChangeChapter(e.value)} optionLabel="chapterName" optionValue="chapterId" />
                    </div>
                </div>


                <div className='grid mt-3 ml-1'>

                    <div className='col-2 card mr-5'>

                        <p className='text-sm	mb-3' style={{ color: '#6C757D' }}>{this.state?.selectedClassName}</p>

                        <p className='font-bold	mb-3' style={{ color: '#495057', }}> <span className='pi pi-home font text-base'></span>{this.state.chapterId ? ' Select Topic' : 'Select Chapter'}</p>
                        <div style={{ backgroundColor: '#DEE2E6', height: 0.5, width: '100%', padding: 0 }}></div>

                        <div className='mt-3 ma-scroll-vr-smoke' style={{ height: 700 }}>
                            {
                                this.state.chapterId && this.state.topics?.length ? this.state.topics?.map((topic, index) => {
                                    return <div className='my-3 cursor-pointer' style={topic.topicId == this.state.selectedTopic?.topicId ? { backgroundColor: '#F8F9FA', padding: '4px', paddingLeft: 10 } : { paddingLeft: 5 }} onClick={() => this.getResourcesFromSelectedTopicOrChapter(topic, 'topic')}>
                                        <p className='topicName interSemibold' style={{ color: topic.topicId == this.state.selectedTopic?.topicId ? '#076EFF' : '' }}>{topic.topicName?.toUpperCase()}</p>
                                    </div>
                                }) : this.state.chapterId && <p>Topics Not Found</p>
                            }
                            {
                                !this.state.chapterId && this.state?.chapters?.length ? this.state?.chapters?.map((chapter, index) => {
                                    return <div className='my-3 cursor-pointer' style={chapter.chapterId == this.state.selectedChapter.chapterId ? { backgroundColor: '#F8F9FA', padding: '4px', paddingLeft: 10 } : { paddingLeft: 5 }} onClick={() => this.getResourcesFromSelectedTopicOrChapter(chapter, 'chapter')}>
                                        <p className='topicName interSemibold' style={{ color: chapter.chapterId == this.state.selectedChapter?.chapterId ? '#076EFF' : '' }}>{chapter.chapterName?.toUpperCase()}</p>
                                    </div>
                                }) : !this.state.chapterId && <p>Chapters Not Found</p>
                            }
                        </div>
                    </div>

                    <div className='col-9 card'>
                        <div className='m-3 flex flex-row justify-content-between'>
                            <div className=''>
                                <p className='font-bold text-3xl mb-2' style={{ color: 'black' }}></p>
                                <p className='font-medium text-xl	' style={{ color: 'black' }}></p>
                            </div>
                            <div style={{ width: '80px', height: '80px', }}>
                                {/* <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={{
                                        root: {},
                                        path: {
                                            stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                                            strokeLinecap: 'butt',
                                            transition: 'stroke-dashoffset 0.5s ease 0s',
                                            transform: 'rotate(0.25turn)',
                                            transformOrigin: 'center center',
                                        },
                                        trail: {
                                            stroke: '#d6d6d6',
                                            strokeLinecap: 'butt',
                                            transform: 'rotate(0.25turn)',
                                            transformOrigin: 'center center',
                                        },
                                        text: {
                                            fill: 'black',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            textAlign:'center'
                                        },
                                        background: {
                                            fill: '#076EFF',
                                        },
                                    }}
                                /> */}
                                {/* <ChangingProgressProvider values={[66]}>
                                    {(percentage) => (
                                        <CircularProgressbar
                                            value={percentage}
                                            text={`${percentage}%`}
                                            styles={buildStyles({
                                                pathTransitionDuration: 0.15,
                                                textColor: "red",
                                                pathColor: "turquoise",
                                                trailColor: "gold",
                                            })}
                                        />
                                    )}
                                </ChangingProgressProvider> */}
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={
                                        {
                                            ...buildStyles({
                                                pathColor: "#076EFF",
                                                trailColor: "lightgrey",
                                                textColor: 'black',
                                            }), text: {
                                                fill: 'black',
                                                fontSize: '20px',
                                                fontWeight: 'bolder',
                                                textAnchor: 'middle'
                                            },
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className='shadow-1 px-2' style={{ backgroundColor: '#F4F5F6', borderRadius: 10, width: '100%', height: 55, display: 'flex', alignItems: 'center', overflowX: 'auto', }}>
                            {selectedTopicResourceTypes.map((tab, index) => (
                                <div key={index} className={` cursor-pointer ${this.state.selectedTab === tab.value ? 'shadow-1' : ''}`} style={{ backgroundColor: this.state.selectedTab === tab.value ? 'white' : '#F4F5F6', borderRadius: 5, minWidth: 100, marginRight: 5, overflow: 'auto', whiteSpace: 'nowrap' }} onClick={() => { this.setState({ selectedTab: tab.value }); this.getResourcesFromResourseType(tab) }}  >
                                    <p className='px-8 py-3 inter500semibold' style={{ margin: 0, }}>{tab.label}</p>
                                </div>
                            ))}
                        </div>


                        <div className='pt-3 ma-scroll-vr-smoke' style={{ height: '70%' }}>

                            {


                                this.state?.resources?.length ? this.state?.resources?.map((resource, index) => {
                                    return (
                                        <div className='mt-2' >
                                            <div className='flex justify-content-between px-4'>

                                                <div className='flex pb-2'>
                                                    <div className='border-3 mr-4' style={{ height: 30, width: 30, borderColor: '#076EFF', borderRadius: 25, justifyContent: 'center', alignItems: 'center', display: 'flex', alignSelf: "center" }}>
                                                        <p className='font-bold' style={{ color: '#076EFF', fontSize: 20, }}>{index + 1}</p>
                                                    </div>
                                                    <div>
                                                        <p className='text-xl mb-2 text-black-alpha-90' style={{ fontWeight: 'bolder', }}>{resource.contentName}</p>
                                                        <p className='interRegular14'>Medium : English</p>
                                                        <p className='interRegular14'>{this.displayDurationOrPages(resource)}</p>
                                                    </div>
                                                </div>
                                                <div style={{ alignSelf: 'center' }} onClick={() => this.handleVideoIconClick(resource)}>
                                                    <ClassetVideoIcon  />
                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: '#DEE2E6', height: 0.25, width: '100%', padding: 0 }}></div>
                                        </div>
                                    )
                                }): <p className='interRegular14'>Resources Not Found</p>
                            }

                        </div>
                    </div>
                </div>

                {
                    this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                        this.setState({
                            isShowPreview: false
                        })
                    }} contentInfo={this.state.contentData} />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

            </div>

        );
    }
}


const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(DigitalLibrary)));


