import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import '../style.scss';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon, CodeLinkIcon, CalendarIcon, ArrowLeftRight, Plus } from '../../svgIcons';
import { BasicLazyParams } from '../../../utile';
import { MultiSelect } from 'primereact/multiselect';
import BoardToSectionDDCol from '../../BaseDropdownComponents/BoardToSectionDDCol';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { getBoardsData } from '../../../store/actions';
import withRouter from '../../lib/withRouter';
import Authentication from "../../../session"
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../services';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const options = [
    { value: 1, label: 'Fee Type Based' },
    { value: 2, label: 'Term Based' }];




const feeTypeData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];
const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]

const paymentType = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Cheque' },
    { value: 3, label: 'DD' },
    { value: 4, label: 'Online' },
    { value: 5, label: 'Credit Card' },
    { value: 6, label: 'Debit Card' },
    { value: 7, label: 'QR Code' },
]
export class RequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            feeTypeData: feeTypeData,
            paymentType: paymentType,
            openCreateSubstituionDialog: false,
            searchType: 1,
            searchInput: "",
            periodSubstitutionData: {},
            Reason: ''
        }
        this.service = new Service();
    }
    setDropdownValues = (dropdownsData) => {
        // console.log(dropdownsData);
        this.setState(
            {
                dropdownsData
            },
            () => {
                // this.getStaffData();
                if (dropdownsData?.boardId?.length > 0 && dropdownsData?.classId?.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                            // () => this.getCurriculumData()
                        );
                    }
                }
            }
        );
        // if (this.state.dropdownsData) {
        // const { branchId, boardId, classId, sectionId } = dropdownsData;
        // if ((branchId !== '' && boardId !== '' && classId !== '' && sectionId !== '')) {
        //     // if (prevState?.dropdownsData?.branchId !== branchId || prevState?.dropdownsData?.boardId !== boardId || prevState?.dropdownsData?.classId !== classId || prevState?.dropdownsData?.sectionId !== sectionId) {
        //     this.getTimeTableIfExists(dropdownsData);
        //     // }
        // } else {
        //     this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
        // }
        // }
    };
    onChangeTeacher = (value) => {
        console.log(this.state.users);
        const selectedTeacher = this.state?.users?.find((teacher) => teacher.user_id === value);

        if (selectedTeacher) {
            console.log(selectedTeacher);

            this.setState({
                periodSubstitutionData: {
                    ...this.state.periodSubstitutionData,
                    substitutionTeacher: { user_id: selectedTeacher.user_id, username: selectedTeacher.username, name: selectedTeacher.user_info.name },
                },
                subjects: selectedTeacher.user_info.assignedSubjects || []
            });
        } else {
            console.log("Teacher not found");
            this.setState({
                subjects: []
            });
        }
    };
    raiseRequestSubstitution = async () => {
        const raisedById = localStorage.getItem('userId');
        const raisedByusername = localStorage.getItem('username');
        const academicYear = localStorage.getItem('userAcademicYear');
        const { branchId, boardId, classId, sectionId, selectedBoardName, selectedbranchName, selectedClassName, selectedsectionName } = this.state.dropdownsData;
        const { _id } = this.state.timeTableData;
        console.log(this.state.periodSubstitutionData);

        if (this.state.Reason.length === 0) return this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Reason is Mandatory", life: 3000 });
        if (!this.state.periodSubstitutionData?.substitutionTeacher) return this.toast.show({ severity: 'error', summary: 'Teacher should not be empty', life: 3000 });
        if (!this.state.periodSubstitutionData?.substitutionSubject) return this.toast.show({ severity: 'error', summary: 'Subject should not be empty', life: 3000 });
        // console.log(this.state.Reason);
        const payload = {
            ...this.state.periodSubstitutionData,
            raisedById,
            raisedByusername,
            templateId: this.state.selectedTemplate,
            branchId,
            boardId,
            classId,
            sectionId,
            branchName: selectedbranchName,
            boardName: selectedBoardName,
            className: selectedClassName,
            sectionName: selectedsectionName,
            timeTableId: this.state?.timeTableId,
            substitutionDate: this.state.date,
            reason: this.state.Reason,
            academicYear,
            isApprove: false,
            isClosed: false,
        }
        try {
            const url = `${baseUrlAdmin}/timetable/raiserequest`
            const res = await this.service.post(url, payload, true);
            if (res.status) {
                this.toast.show({ severity: 'success', summary: 'SUCCESS', detail: res?.message, life: 3000 });
                this.setState({ openCreateSubstituionDialog: false })
            } else {
                this.toast.show({ severity: 'error', summary: 'ERROR', detail: res?.message, life: 3000 });
            }
        } catch (err) {
            this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Some error occurred', life: 3000 });
            console.log(err);
        }
    }
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        console.log(this.state.dropdownsData);
        // let branchIds = Array.isArray(this.state?.dropdownsData?.branchId) ? this.state.dropdownsData.branchId.join(',') : '';
        // let boardIds = Array.isArray(this.state?.dropdownsData?.boardId) ? this.state.dropdownsData.boardId.join(',') : '';
        // let gradeIds = Array.isArray(this.state?.dropdownsData?.classId) ? this.state.dropdownsData.classId.join(',') : '';
        // let sectionIds = Array.isArray(this.state?.dropdownsData?.sectionId) ? this.state.dropdownsData.sectionId.join(',') : '';
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;

        let url;
        if (branchIds || boardIds || gradeIds || sectionIds) {
            url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;
        } else {
            url = `${baseUrlAdmin}/staff?academicYear=${academicYear}&limit=${10000}&page=${1}`;
        }

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                // console.log(res?.res?.data, 'res?.res?.datares?.res?.data');

                const users = res?.res?.data?.records;
                console.log(users);
                this.setState({
                    users: users,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    getTimeTableIfExists = async (dropdownsData, date) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        if (!date) return this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Select Date", life: 3000 });
        let url = `${baseUrlAdmin}/timetable/getsubstimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}&date=${date}`;
        try {
            const res = await this.service.get(url, true);
            if (res && res.status && res.res.status) {
                if (res.res.data) {
                    const timeTableData = res.res.data;
                    this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true }, () => {
                        this.dynamicColumns(timeTableData.timeTableData)
                    });
                } else {
                    this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                }
            }
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    }
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable) return [];

        const columns = [];
        columns.push(<Column key="day" field="day" header={<div style={{ color: 'black' }}>Days/Periods</div>} align={'left'} body={(rowData) => <div style={{ color: 'black' }}>{rowData.day}</div>} />);

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={<div style={{ color: 'black' }}>{_.name}</div>}

                        body={(rowData) => {
                            const periodData = rowData.Periods[index];
                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="break-period" style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                            BREAK
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                    <div style={{ marginBottom: '20px' }} >
                                        <span
                                            data-pr-tooltip="Edit User"
                                            onClick={() => {
                                                this.openCreateSubstitution(periodData, rowData.day);
                                            }}
                                        >
                                            {periodData?.Teacher?.name ?
                                                <ArrowLeftRight width={16} height={16} color={'#000000'} />
                                                : <Plus width={16} height={16} color={'#000000'} />
                                            }
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal',
                                            color: periodData?.Teacher?.name ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Teacher?.name
                                            ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                            : ''}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal',
                                            color: periodData?.Subject?.subjectName ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Subject?.subjectName
                                            ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                            : 'Not Assigned'}
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };

    getSubstitutionTeachers = async (subjectInfo, teacherInfo) => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {
            "academicYear": academicYear,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            branchId: this.state?.dropdownsData?.branchId,
            sectionId: this.state?.dropdownsData?.sectionId,
            subjectInfo: subjectInfo,
            teacher: teacherInfo
        }
        let url = `${baseUrlAdmin}/staff/substitution-staff`;
        try {
            this.setState({ isLoading: true })
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.length > 0) {
                    this.setState({ isLoading: false , users: res.res.data,});
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                }
            }
            console.log(res);
        } catch (err) {
            console.log(err);
        }

    }
    openCreateSubstitution = async (periodData, day) => {
        const updated = { ...periodData, Day: day };
        await this.getSubstitutionTeachers(periodData?.Subject, periodData?.Teacher)
        this.setState({ openCreateSubstituionDialog: true, periodSubstitutionData: updated });
    };

    render() {
        const { timeTableData, dropdownsData } = this.state;
        return (
            <div className="m-2">




                <div className='flex flex-column m-2 mb-6'>
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    <div className="col-3 ml-1">
                        <ClassetCalendar
                            icon={<MailIcon height={16.5} width={19.5} />}
                            className=''
                            calendarMode={'single'}
                            placeholder={'Date'}
                            label={'Date'}
                            onChange={(e) => {
                                this.setState({ date: e.target.value })
                                const { branchId, boardId, classId, sectionId } = this.state.dropdownsData;
                                if ((branchId !== '' && boardId !== '' && classId !== '' && sectionId !== '')) {
                                    // if (prevState?.dropdownsData?.branchId !== branchId || prevState?.dropdownsData?.boardId !== boardId || prevState?.dropdownsData?.classId !== classId || prevState?.dropdownsData?.sectionId !== sectionId) {
                                    this.getTimeTableIfExists(dropdownsData, e.target.value);
                                    // }
                                } else {
                                    this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
                                }
                            }
                            }
                        />
                    </div>
                </div>


                {timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={timeTableData.TimeTable}
                        dataKey="id"
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        size='large'
                    >
                        {this.dynamicColumns(timeTableData)}
                    </DataTable>
                )}
                <Dialog
                    visible={this.state.openCreateSubstituionDialog}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box w-3 mr-0'
                    style={{ width: "300px", height: "600px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-5'>
                            <h2 className="headin text-center" style={{ color: 'black' }}>Request Substitution</h2>
                        </div>)
                    }}
                    footer={() => (
                        <div className="mt-3" style={{ display: 'flex' }}>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => this.raiseRequestSubstitution()} >
                                <p className='text-xl ml-2'>Request Substitution</p>
                            </Button>
                            <Button style={{ fontWeight: 'semibold', color: 'black', borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ openCreateSubstituionDialog: false }) }}>
                                <p className='text-xl'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ openCreateSubstituionDialog: false })}
                >
                    <div className='flex flex-column justify-content-center mx-3'>

                        <div className='mt-3'>
                            <ClassetTextArea
                                style={{ height: "119px" }}
                                label={'Reason for Substitution'}
                                required={true}
                                placeholder="Reason for Substitution"
                                onChange={(e) => this.setState({ Reason: e.target.value })}
                            />

                        </div>

                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Period Number'}
                                id='title'
                                icon={<MailIcon />}
                                placeholder="Period Number"
                                value={this.state?.periodSubstitutionData?.name}
                                disabled={true}
                            />
                        </div>

                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Day of the week '}
                                id='title'
                                value={this.state?.periodSubstitutionData?.Day}
                                icon={<MailIcon />}
                                disabled={true}
                                placeholder="Day of the week"
                            />
                        </div>
                        {/* <div className="mt-3">
                            <ClassetCalendar
                                icon={<MailIcon height={16.5} width={19.5} />}
                                className=''
                                calendarMode={'single'}
                                placeholder={'Date'}
                                label={'Date'}
                                onChange={(e) => this.setState({ date: e.target.value })}
                            />
                        </div> */}
                        <div className="flex flex-column">
                            <div className="mt-3">
                                <ClassetDropdown
                                    required={true}
                                    label={'Select Teacher'}
                                    icon={<MailIcon height={16.5} width={19.5} />}
                                    placeholder={'Select Teacher'}
                                    options={this.state.users}
                                    value={this.state?.periodSubstitutionData?.substitutionTeacher?.user_id}
                                    onChange={(e) =>
                                        this.onChangeTeacher(e.target.value)
                                    }
                                    optionLabel="user_info.name"
                                    optionValue="user_id"
                                />
                            </div>
                            <div className="mt-3">
                                <ClassetDropdown
                                    required={true}
                                    label={'Select Subject'}
                                    icon={<MailIcon height={16.5} width={19.5} />}
                                    placeholder={'Select Subject'}
                                    options={this.state.subjects}
                                    value={this.state?.periodSubstitutionData?.substitutionSubject?.subjectId}
                                    onChange={(e) => {
                                        const sub = this.state.subjects.find((sub) => sub.subjectId === e.target.value);
                                        this.setState({
                                            periodSubstitutionData: {
                                                ...this.state.periodSubstitutionData,
                                                substitutionSubject: { subjectId: sub.subjectId, subjectName: sub.subjectName }
                                            }
                                        })
                                    }

                                    }
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                />
                            </div>
                        </div>


                        {/* <div className="mt-3">

                            <BoardToSectionDDCol setValues={this.setDropdownValues} boardDisable={true} classDisable={true} branchDisable={true} sectionDisable={true} />
                        </div> */}
                    </div>
                </Dialog>



                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(RequestSubstitution)));