import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { connect } from 'react-redux';

import {
    getVideoUrl
} from '../../../store/actions';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';



class PPTPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scale: 1,
            videoInfo: {}
        };

        this.service = new Service();
    }


    getVideoUrl = (contentStorageKey) => {
        let url = `${baseUrlAdmin}/se3VideoFetch`;
        this.service.post(url, {
            preUrl: contentStorageKey
        }, true).then(res => {
            if (res && res.res) {

                this.setState((prevState) => {
                    return {
                        videoInfo: {
                            ...prevState.videoInfo,
                            [contentStorageKey]: res.res
                        },
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        //  if (this.props.contentInfo && this.props.contentInfo.contentStorageKey) {
        this.getVideoUrl(this.props.contentInfo.contentStorageKey)
        //  }
    }





    render() {
        //   console.log(this.props);
        return <>
            {this.state.videoInfo && this.state.videoInfo[this.props.contentInfo.contentStorageKey] && (
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.state.videoInfo[this.props.contentInfo.contentStorageKey])}`}
                    width="100%"
                    height="500px"
                ></iframe>
            )}
            
            
         {/*    <div style={{ height: '100vh', }}>
                <div className="mt-5">

                    {this.state.videoInfo && this.state.videoInfo[this.props.contentInfo.contentStorageKey] && <>
                        <PDFViewer
                            document={{
                                fileType: 'pdf',
                                // url: this.state.videoInfo && this.state.videoInfo[this.props.contentInfo.contentStorageKey],
                                url: "https://classetcontentrepository-old.s3.ap-south-1.amazonaws.com/815e1170-e1e9-11ef-9409-4dd910f6b510-Mobile%20feature.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA47CR3JCXQQ3LGQAD%2F20250203%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20250203T051306Z&X-Amz-Expires=3000&X-Amz-Signature=e57dc8882b85cccc3e3e3a7800a77ecd902f3910238a7d0b61c4def5ee4cab63&X-Amz-SignedHeaders=host"
                                // className="pdf_view"

                            }}

                            scale={this.state.scale}
                        // css="customViewer"

                        // navigation={{
                        //     elements: {
                        //         previousPageBtn: CustomPrevButton,
                        //         nextPageBtn: CustomNextButton,
                        //         pages: CustomPages
                        //     }
                        // }}


                        />
                    </>}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div> */}

        </>;
    }
}




const mapsStatesToProps = (state) => {
    return {
        videoInfo: state.getVideoUrlInfo && state.getVideoUrlInfo.allVideoUrls
    };
};


export default connect(mapsStatesToProps, {
    getVideoUrl
})(PPTPlayer);