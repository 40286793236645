import * as ActionTypes  from '../actionTypes';
import axios from 'axios';

const dispatchRequestUploadContent = data => ({
    type: ActionTypes.REQUEST_UPLOAD_CONTENT,
    data
})

const dispatchReceivedUploadContent = res => ({
    type: ActionTypes.RECEIVED_UPLOAD_CONTENT,
    res
})

const dispatchErrorUploadContent = err => ({
    type: ActionTypes.ERROR_UPLOAD_CONTENT,
    err
})

const dispatchClearUploadContent = () => ({
    type: ActionTypes.CLEAR_UPLOAD_CONTENT
})

export const clearContentData = () => dispatchClearUploadContent();


export const uploadContent = (url, content) => dispatch => {
    
    dispatch(dispatchRequestUploadContent({url, content})); 
    axios({
        method: 'put',      
        url: url,
        data: content,
        headers: {
            "Content-Type": content.type,
            "Access-Control-Allow-Origin": "*",        
        }    
    }).then((res) => {        
        
        dispatch(dispatchReceivedUploadContent(res.data));
    }).catch(e => {
        dispatch(dispatchErrorUploadContent(e));
    });
}


