import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, getServerTime } from '../../../utile';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import _ from 'lodash';
import { MailIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlForLongRunningTask } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import moment from 'moment';
import './styles.scss'
import ClassetCalendar from "../../../classetComponents/classetCalender";
import BranchToSectionDDPreFill from "../../BaseDropdownComponents/BranchToSectionDDPreFill";

let morning = false;
let afternoon = false;
let attendanceState = 'NA';

class StudentAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groups: [],
            curriculumInfo: {},
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true,
            isShowBoards: true,
            isShowClasses: false,
            editChapterData: null,
            isShowExportToOffline: false,
            globalSearch: '',
            attendance: [],
            lazyParams: BasicLazyParams,
            totalRecords: 0,
            date: getServerTime(),
            dropdownsData: {},
            morning: false,
            afternoon: false,

        };
        this.service = new Service();

    }

    componentDidMount() {

    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getStudentsAttendance);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getStudentsAttendance);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getStudentsAttendance);

    }


    setDropdownValues = (dropdownsData) => {

        this.setState({ dropdownsData: dropdownsData },
            () => {
                if (dropdownsData.branchId && dropdownsData.boardId && dropdownsData.classId && dropdownsData.sectionId) {
                    this.getStudentsAttendance(dropdownsData.sectionId, dropdownsData.selectedsectionName)
                }
            })
    }



    getStudentsAttendance = (sectionId, selectedsectionName) => {
        attendanceState = 'NA'
        this.setState({
            isLoading: true,
            attendance: [],

        })


        const payload = {
            sectionId: sectionId,
            sectionName: selectedsectionName,
            date: moment(this.state?.date).format('YYYY-MM-DD')
        };
        const url = `${baseUrlForLongRunningTask}/attendance/get?limit=100000&page=1`;
        this.service.post(url, payload, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    attendance: res.res.data,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    saveAttendance = () => {

        let { attendance } = this.state;

        if (attendance && attendance.length) {
            this.setState({
                isLoading: true
            })



            const url = `${baseUrlForLongRunningTask}/attendance/save`;
            this.service.post(url, attendance, true).then(res => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Successfully saved.', life: 3000 });
                    attendanceState = 'NA'
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })

        } else {
            this.toast.show({ severity: 'error', summary: 'No Attendance', detail: 'No Attendance to save', life: 3000 });
        }
    }


    studentAttendance = (row, value, index) => {

        // P,A,HD,NA

        if (value === 'morning') {
            morning = true;
            afternoon = true;
            attendanceState = 'P'
        } else if (value === 'afternoon') {
            morning = true;
            afternoon = false;
            attendanceState = 'HD'
        } else if (value === 'abscent') {
            morning = false;
            afternoon = false;
            attendanceState = 'A'

        } else {

            morning = false;
            afternoon = false;
            attendanceState = 'NA'
        }

        if (this.state.attendance?.length) {
            const updatedStudentsInfo = this.state.attendance.map(each => {
                if (each.user === row.user) {
                    return { ...each, morning, afternoon, attendanceState };
                }
                return each;
            });

            this.setState({
                morning,
                afternoon,
                attendanceState,
                attendance: updatedStudentsInfo
            });
        } else {
            this.setState({
                morning,
                afternoon,
                attendanceState
            });
        }
    };

    render() {

        console.log(attendanceState, "attendanceState")
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );

        return (
            <div>

                <BranchToSectionDDPreFill setValues={this.setDropdownValues} />

                <div className="grid  md:grid-cols-2 md:justify-content-between lg:grid-cols-3 mx-4 " >
                    <div className="col-span-1">
                        <ClassetCalendar
                            icon={<MailIcon width={24} height={24} color={"black"} />}
                            className='border-round-md add-vehicle-field-input text-2xl w-full mr-4'
                            calendarMode={'single'}
                            value={this.state.date}
                            onChange={(e) => this.setState({ date: e.value }, () => {
                                this.getStudentsAttendance(this.state?.dropdownsData?.sectionId, this.state?.dropdownsData?.selectedsectionName);
                            })}
                            disabled
                            placeholder="Select Date"
                            label={"Select Date"}
                        />
                    </div>
                    {/* // enabled={(attendanceState == 'P' ||  attendanceState == 'HD' || attendanceState == 'A') */}
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'><span className='ma-required'></span></p>
                        <Authorizer permId={PERMISSIONS.ATTENDANCE} >
                            <Button className='formSaveButton' onClick={this.saveAttendance} disabled={attendanceState === 'NA'}>
                                <p className='formSaveButtonText'>Save</p>
                            </Button>
                        </Authorizer>

                    </div>
                </div>

                <div className="card datatable-crud-demo mt-5 mx-4">

                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.attendance}
                        paginator
                        //lazy
                        className="dataTableValuesText"
                        responsiveLayout="scroll"
                        showGridlines
                        // selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        rows={5}
                        // onPage={this.onPage}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        // totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column> */}
                        <Column field="given_name" header="Student Name" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' />
                        <Column field="" header="Enrollment No" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                        return <>{rowData?.username.slice(3)}</>

                    }} />
                        <Column field="branchName" header="Branch Name" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' />
                        <Column field="boardName" header="Board Name" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' />

                        <Column field="className - sectionName" header="Grade - Section" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center'
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <div>
                                            {rowData.className}-{rowData.sectionName}
                                        </div>
                                    </>
                                )


                            }}

                        />
                        {/*<Column field="date" header="Date" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' />*/}

                        <Column field="" header="Mark Attendance" sortable style={{ width: "20rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center'


                            body={(rowData, index) => {
                                return (


                                    <>
                                        <Button type="button" style={{ backgroundColor: (rowData.morning === true && rowData.afternoon === true && rowData.attendanceState === 'P') ? "#7CDD56" : '#D9D9D9', border: 'none', borderRadius: '50PX', marginRight: '10PX', width: '50px', height: '50px', color: '#000000' }} rounded label="P" onClick={() => { this.studentAttendance(rowData, 'morning', index) }} />
                                        <Button type="button" style={{ backgroundColor: (rowData.morning === false && rowData.afternoon === false && rowData.attendanceState === 'A') ? "#C63737" : '#D9D9D9', border: 'none', borderRadius: '50PX', marginRight: '10PX', width: '50px', height: '50px', color: '#000000' }} rounded label="A" onClick={() => { this.studentAttendance(rowData, 'abscent', index) }} />
                                        <Button type="button" style={{ backgroundColor: (rowData.morning === true && rowData.afternoon === false && rowData.attendanceState === 'HD') ? "#FAB710" : '#D9D9D9', border: 'none', borderRadius: '50PX', width: '50px', height: '50px', color: '#000000' }} rounded label="HD" onClick={() => { this.studentAttendance(rowData, 'afternoon', index) }} />



                                    </>
                                )




                            }}

                        />
                        <Column field="phone_number" header="Phone Number" sortable style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' />
                    </DataTable>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                    {this.state.isLoading && <LoadingComponent />}
                </div >

            </div>
        );
    }
}

const mapStatesToProps = (state) => ({


    isLoading: state.boardsData.isLoading,
    isUpdated: state.boardsData.isUpdated,
    boardsData: state.boardsData,
    updateError: state.boardsData.error,
    updateErrorMessage: state.boardsData.errorMessage,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});
export default connect(null, {})(Authentication(withRouter(StudentAttendance)));