import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
// import './styles.scss'
import { Button } from 'primereact/button';
import { AddIcon2, ArrowLeftRight, DownloadIcon, MailIcon, Plus, TickBgIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import { baseUrlAdmin } from '../../store/apiConstants';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import { getBoardsData } from '../../store/actions';
import withRouter from '../lib/withRouter';
import Authentication from "../../session"
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Service from '../services';
import LoadingComponent from '../loadingComponent';

const timeTableData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];

export class MyTimeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            timeTableData: timeTableData,
            openCreateFeeDialog: false,
            searchType: 1,
            searchInput: ""
        }
        this.service = new Service();
    }

    setDropdownValues = (dropdownsData) => {
        // console.log(dropdownsData);
        this.setState(
            {
                dropdownsData
            },
            () => {
                this.getStaffData();
                if (dropdownsData.boardId.length > 0 && dropdownsData.classId.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                            // () => this.getCurriculumData()
                        );
                    }
                }
            }
        );
        // if (this.state.dropdownsData) {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        if ((branchId !== '' && boardId !== '' && classId !== '' && sectionId !== '')) {
            // if (prevState?.dropdownsData?.branchId !== branchId || prevState?.dropdownsData?.boardId !== boardId || prevState?.dropdownsData?.classId !== classId || prevState?.dropdownsData?.sectionId !== sectionId) {
            this.getTimeTableIfExists(dropdownsData);
            // }
        } else {
            this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
        }
        // }
    };
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        console.log(this.state.dropdownsData);
        // let branchIds = Array.isArray(this.state?.dropdownsData?.branchId) ? this.state.dropdownsData.branchId.join(',') : '';
        // let boardIds = Array.isArray(this.state?.dropdownsData?.boardId) ? this.state.dropdownsData.boardId.join(',') : '';
        // let gradeIds = Array.isArray(this.state?.dropdownsData?.classId) ? this.state.dropdownsData.classId.join(',') : '';
        // let sectionIds = Array.isArray(this.state?.dropdownsData?.sectionId) ? this.state.dropdownsData.sectionId.join(',') : '';
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;

        let url;
        if (branchIds || boardIds || gradeIds || sectionIds) {
            url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;
        } else {
            url = `${baseUrlAdmin}/staff?academicYear=${academicYear}&limit=${10000}&page=${1}`;
        }

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                // console.log(res?.res?.data, 'res?.res?.datares?.res?.data');

                const users = res?.res?.data?.records;
                console.log(users);
                this.setState({
                    users: users,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    getTimeTableIfExists = async (dropdownsData) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        let url = `${baseUrlAdmin}/timetable/getsubstimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res && res.status && res.res.status) {
                if (res.res.data) {
                    const timeTableData = res.res.data;
                    this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true }, () => {
                        this.dynamicColumns(timeTableData.timeTableData)
                    });
                } else {
                    this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                }
            }
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    }
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable) return [];

        const columns = [];
        columns.push(<Column key="day" field="day" header={<div style={{ color: 'black' }}>Days/Periods</div>} align={'left'} body={(rowData) => <div style={{ color: 'black' }}>{rowData.day}</div>} />);

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={<div style={{ color: 'black' }}>{_.name}</div>}

                        body={(rowData) => {
                            const periodData = rowData.Periods[index];
                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="break-period" style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                            BREAK
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                    {/* <div style={{ marginBottom: '20px' }} >
                                        <span
                                            data-pr-tooltip="Edit User"
                                            onClick={() => {
                                                this.openCreateSubstitution(periodData, rowData.day);
                                            }}
                                        >
                                            {periodD    ata?.Teacher?.name ?
                                                <ArrowLeftRight width={16} height={16} color={'#000000'} />
                                                : <Plus width={16} height={16} color={'#000000'} />
                                            }
                                        </span>
                                    </div> */}
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal',
                                            color: periodData?.Teacher?.name ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Teacher?.name
                                            ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                            : ''}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal',
                                            color: periodData?.Subject?.subjectName ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Subject?.subjectName
                                            ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                            : 'Not Assigned'}
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };

    render() {
        const { timeTableData, dropdownsData } = this.state;
        return (
            <div className="m-2">
                <div className='grid m-2 mb-6'>
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                </div>
                {timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={timeTableData.TimeTable}
                        dataKey="id"
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        size='large'
                    >
                        {this.dynamicColumns(timeTableData)}
                    </DataTable>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>

        )
    }
}


const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(MyTimeTable)));
