// organization master data
export const REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION';
export const RECEIVED_ORGANIZATION = 'RECEIVED_ORGANIZATION';
export const ERROR_ORGANIZATION = 'ERROR_ORGANIZATION';
export const CLEAR_ORGANIZATION_DATA = 'CLEAR_ORGANIZATION_DATA';


// board master data
export const REQUEST_BOARDS = 'REQUEST_BOARDS';
export const RECEIVED_BOARDS = 'RECEIVED_BOARDS';
export const ERROR_BOARDS = 'ERROR_BOARDS';
export const CLEAR_BOARDS_DATA = 'CLEAR_BOARDS_DATA';

export const REQUEST_CONTENT_BOARDS = 'REQUEST_CONTENT_BOARDS';
export const RECEIVED_CONTENT_BOARDS = 'RECEIVED_CONTENT_BOARDS';
export const ERROR_CONTENT_BOARDS = 'ERROR_CONTENT_BOARDS';
export const CLEAR_CONTENT_BOARDS_DATA = 'CLEAR_CONTENT_BOARDS_DATA';

// paths
export const SET_PATHS = 'SET_PATHS'
// organization master data
export const REQUEST_ORGANIZATION2 = 'REQUEST_ORGANIZATION2';
export const RECEIVED_ORGANIZATION2 = 'RECEIVED_ORGANIZATION2';
export const ERROR_ORGANIZATION2 = 'ERROR_ORGANIZATION2';
export const CLEAR_ORGANIZATION_DATA2 = 'CLEAR_ORGANIZATION_DATA2';


//update organization master data
export const REQUEST_UPDATE_ORGANIZATION = 'REQUEST_UPDATE_ORGANIZATION';
export const RECEIVED_UPDATE_ORGANIZATION = 'RECEIVED_UPDATE_ORGANIZATION';
export const ERROR_UPDATE_ORGANIZATION = 'ERROR_UPDATE_ORGANIZATION';
export const CLEAR_UPDATE_ORGANIZATION_DATA = 'CLEAR_UPDATE_ORGANIZATION_DATA';

//sign in
export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const RECEIVED_SIGN_IN = 'RECEIVED_SIGN_IN';
export const ERROR_SIGN_IN = 'ERROR_SIGN_IN';
export const CLEAR_SIGN_IN_DATA = 'CLEAR_SIGN_IN_DATA';

// curriculum master data
export const REQUEST_CURRICULUM = 'REQUEST_CURRICULUM';
export const RECEIVED_CURRICULUM = 'RECEIVED_CURRICULUM';
export const ERROR_CURRICULUM = 'ERROR_CURRICULUM';
export const CLEAR_CURRICULUM_DATA = 'CLEAR_CURRICULUM_DATA';
export const SELECTED_SUBJECT_DATA = 'SELECTED_SUBJECT_DATA';
export const SELECTED_SUBJECT_QUESTIONS_DATA = 'SELECTED_SUBJECT_QUESTIONS_DATA';
export const REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA = "REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA";
export const THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA = "THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA";
export const SELECTED_TOPIC_DATA = 'SELECTED_TOPIC_DATA';
export const THROW_ERROR_FOR_CURRICULUM = 'THROW_ERROR_FOR_CURRICULUM';
export const REQUEST_CURRICULUM_BRANCH = 'REQUEST_CURRICULUM_BRANCH';
export const RECEIVED_CURRICULUM_BRANCH = 'RECEIVED_CURRICULUM_BRANCH';
export const ERROR_CURRICULUM_BRANCH = 'ERROR_CURRICULUM_BRANCH';


// curriculum master data-- store2
export const REQUEST_CURRICULUM2 = 'REQUEST_CURRICULUM2';
export const RECEIVED_CURRICULUM2 = 'RECEIVED_CURRICULUM2';
export const ERROR_CURRICULUM2 = 'ERROR_CURRICULUM2';
export const CLEAR_CURRICULUM_DATA2 = 'CLEAR_CURRICULUM_DATA2';
export const SELECTED_SUBJECT_DATA2 = 'SELECTED_SUBJECT_DATA2';
export const SELECTED_SUBJECT_QUESTIONS_DATA2 = 'SELECTED_SUBJECT_QUESTIONS_DATA2';
export const REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA2 = "REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA2";
export const THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA2 = "THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA2";
export const SELECTED_TOPIC_DATA2 = 'SELECTED_TOPIC_DATA2';
export const THROW_ERROR_FOR_CURRICULUM2 = 'THROW_ERROR_FOR_CURRICULUM2';
export const REQUEST_CURRICULUM_BRANCH2 = 'REQUEST_CURRICULUM_BRANCH2';
export const RECEIVED_CURRICULUM_BRANCH2 = 'RECEIVED_CURRICULUM_BRANCH2';
export const ERROR_CURRICULUM_BRANCH2 = 'ERROR_CURRICULUM_BRANCH2';

//Academic Year Data 
export const REQUEST_ACADEMIC_YEARS = 'REQUEST_ACADEMIC_YEARS';
export const RECEIVED_ACADEMIC_YEARS = 'RECEIVED_ACADEMIC_YEARS';
export const ERROR_ACADEMIC_YEARS = 'ERROR_ACADEMIC_YEARS';
export const CLEAR_ACADEMIC_YEARS_DATA = 'CLEAR_ACADEMIC_YEARS_DATA';
export const UPDATE_CURRENT_ACADEMIC_YEAR = 'UPDATE_CURRENT_ACADEMIC_YEAR'

export const REQUEST_UPDATE_ACADEMIC_YEARS = 'REQUEST_UPDATE_ACADEMIC_YEARS';
export const RECEIVED_UPDATE_ACADEMIC_YEARS = 'RECEIVED_UPDATE_ACADEMIC_YEARS';
export const ERROR_UPDATE_ACADEMIC_YEARS = 'ERROR_UPDATE_ACADEMIC_YEARS';
export const CLEAR_UPDATE_ACADEMIC_YEARS_DATA = 'CLEAR_UPDATE_ACADEMIC_YEARS_DATA';
// export const REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS = 'REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS'

//Madhu implimented dropDowns Data 

export const REQUEST_BOARDS_DROPDOWN_DATA = 'REQUEST_BOARDS_DROPDOWN_DATA'
export const REQUEST_ACADEMIC_SESSIONS_DATA = 'REQUEST_ACADEMIC_SESSIONS_DATA'
export const REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS = 'REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS'



//Create Adhoc test 
export const REQUEST_CREATE_ADHOC_MOCK_TEST = "REQUEST_CREATE_ADHOC_MOCK_TEST";
export const RECIVED_CREATE_ADHOC_MOCK_TEST = "RECIVED_CREATE_ADHOC_MOCK_TEST";
export const THROW_ERROR_CREATE_ADHOC_MOCK_TEST = "THROW_ERROR_CREATE_ADHOC_MOCK_TEST";
export const REQUEST_QUESTIONS_BY_IDS = "REQUEST_QUESTIONS_BY_IDS";
export const RECIVED_QUESTIONS_BY_IDS = "RECIVED_QUESTIONS_BY_IDS";
export const REMOVE_QUESTIONS_BY_ID = "REMOVE_QUESTIONS_BY_ID";
export const THROW_ERROR_QUESTIONS_BY_IDS = "THROW_ERROR_QUESTIONS_BY_IDS";

//Create Adhoc test 
export const REQUEST_CREATE_ADHOC_MOCK_TEST2 = "REQUEST_CREATE_ADHOC_MOCK_TEST2";
export const RECIVED_CREATE_ADHOC_MOCK_TEST2 = "RECIVED_CREATE_ADHOC_MOCK_TEST2";
export const THROW_ERROR_CREATE_ADHOC_MOCK_TEST2 = "THROW_ERROR_CREATE_ADHOC_MOCK_TEST2";
export const REQUEST_QUESTIONS_BY_IDS2 = "REQUEST_QUESTIONS_BY_IDS2";
export const RECIVED_QUESTIONS_BY_IDS2 = "RECIVED_QUESTIONS_BY_IDS2";
export const REMOVE_QUESTIONS_BY_ID2 = "REMOVE_QUESTIONS_BY_ID2";
export const THROW_ERROR_QUESTIONS_BY_IDS2 = "THROW_ERROR_QUESTIONS_BY_IDS2";

//authentication
export const AUTH_USER = 'AUTH_USER';
export const UN_AUTH_USER = 'UN_AUTH_USER';

//sign out
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';

//user profile
export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
export const RECEIVED_USER_PROFILE = 'RECEIVED_USER_PROFILE';
export const ERROR_USER_PROFILE = 'ERROR_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';

//user data
export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const RECEIVED_USER_DATA = 'RECEIVED_USER_DATA';
export const ERROR_USER_DATA = 'ERROR_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const IS_VALID_USER_DATA = 'IS_VALID_USER_DATA';

//update subject master data
export const REQUEST_UPDATE_SUBJECT = 'REQUEST_UPDATE_SUBJECT';
export const RECEIVED_UPDATE_SUBJECT = 'RECEIVED_UPDATE_SUBJECT';
export const ERROR_UPDATE_SUBJECT = 'ERROR_UPDATE_SUBJECT';
export const CLEAR_UPDATE_SUBJECT_DATA = 'CLEAR_UPDATE_SUBJECT_DATA';

//update subject master data --store2
export const REQUEST_UPDATE_SUBJECT2 = 'REQUEST_UPDATE_SUBJECT2';
export const RECEIVED_UPDATE_SUBJECT2 = 'RECEIVED_UPDATE_SUBJECT2';
export const ERROR_UPDATE_SUBJECT2 = 'ERROR_UPDATE_SUBJECT2';
export const CLEAR_UPDATE_SUBJECT_DATA2 = 'CLEAR_UPDATE_SUBJECT_DATA2';

//update subject master data
export const REQUEST_UPDATE_CHAPTER = 'REQUEST_UPDATE_CHAPTER';
export const RECEIVED_UPDATE_CHAPTER = 'RECEIVED_UPDATE_CHAPTER';
export const ERROR_UPDATE_CHAPTER = 'ERROR_UPDATE_CHAPTER';
export const CLEAR_UPDATE_CHAPTER_DATA = 'CLEAR_UPDATE_CHAPTER_DATA';

//update topic master data
export const REQUEST_UPDATE_TOPIC = 'REQUEST_UPDATE_TOPIC';
export const RECEIVED_UPDATE_TOPIC = 'RECEIVED_UPDATE_TOPIC';
export const ERROR_UPDATE_TOPIC = 'ERROR_UPDATE_TOPIC';
export const CLEAR_UPDATE_TOPIC_DATA = 'CLEAR_UPDATE_TOPIC_DATA';

//get contetnt
export const REQUEST_CONTENT = 'REQUEST_CONTENT';
export const RECEIVED_CONTENT = 'RECEIVED_CONTENT';
export const ERROR_CONTENT = 'ERROR_CONTENT';
export const CLEAR_CONTENT_DATA = 'CLEAR_CONTENT_DATA';

//get contetnt
export const REQUEST_CONTENT_PRE_SIGNED_URL = 'REQUEST_CONTENT_PRE_SIGNED_URL';
export const RECEIVED_CONTENT_PRE_SIGNED_URL = 'RECEIVED_CONTENT_PRE_SIGNED_URL';
export const ERROR_CONTENT_PRE_SIGNED_URL = 'ERROR_CONTENT_PRE_SIGNED_URL';
export const CLEAR_CONTENT_PRE_SIGNED_URL_DATA = 'CLEAR_CONTENT_PRE_SIGNED_URL_DATA';

// select
export const SELECT_BOARD = 'SELECT_BOARD';
export const SELECT_CLASS = 'SELECT_CLASS';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_SUBJECT = 'SELECT_SUBJECT';
export const SELECT_MULTIPLE_SUBJECTS = 'SELECT_MULTIPLE_SUBJECTS';
export const SELECT_TOPIC = 'SELECT_TOPIC';
export const SELECT_MULTIPLE_TOPICS = 'SELECT_MULTIPLE_TOPICS';
export const SELECT_CHAPTER = 'SELECT_CHAPTER';
export const SELECT_QUESTION_TARGET = 'SELECT_QUESTION_TARGET';
export const SELECT_MULTIPLE_CHAPTERS = 'SELECT_MULTIPLE_CHAPTERS';
export const CLEAR_SELECTED_ORGANIZATION_DATA = 'CLEAR_SELECTED_ORGANIZATION_DATA';
export const CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS = 'CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS';


// select org 2
export const SELECT_BOARD2 = 'SELECT_BOARD2';
export const SELECT_CLASS2 = 'SELECT_CLASS2';
export const SELECT_GROUP2 = 'SELECT_GROUP2';
export const SELECT_SUBJECT2 = 'SELECT_SUBJECT2';
export const SELECT_MULTIPLE_SUBJECTS2 = 'SELECT_MULTIPLE_SUBJECTS2';
export const SELECT_TOPIC2 = 'SELECT_TOPIC2';
export const SELECT_MULTIPLE_TOPICS2 = 'SELECT_MULTIPLE_TOPICS2';
export const SELECT_CHAPTER2 = 'SELECT_CHAPTER2';
export const SELECT_QUESTION_TARGET2 = 'SELECT_QUESTION_TARGET2';
export const SELECT_MULTIPLE_CHAPTERS2 = 'SELECT_MULTIPLE_CHAPTERS2';
export const CLEAR_SELECTED_ORGANIZATION_DATA2 = 'CLEAR_SELECTED_ORGANIZATION_DATA2';
export const CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS2 = 'CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS2';


// organization
export const SELECTED_CLASS_FROM_BOARD = 'SELECTED_CLASS_FROM_BOARD';
export const SELECT_GROUP_FROM_CLASS = 'SELECT_GROUP_FROM_CLASS';

// organization
export const SELECTED_CLASS_FROM_BOARD2 = 'SELECTED_CLASS_FROM_BOARD2';
export const SELECT_GROUP_FROM_CLASS2 = 'SELECT_GROUP_FROM_CLASS2';



//upload content
export const REQUEST_UPLOAD_CONTENT = 'REQUEST_UPLOAD_CONTENT';
export const RECEIVED_UPLOAD_CONTENT = 'RECEIVED_UPLOAD_CONTENT';
export const ERROR_UPLOAD_CONTENT = 'ERROR_UPLOAD_CONTENT';
export const CLEAR_UPLOAD_CONTENT = 'CLEAR_UPLOAD_CONTENT';

// update Content
export const REQUEST_UPDATE_CONTENT = 'REQUEST_UPDATE_CONTENT';
export const RECEIVED_UPDATE_CONTENT = 'RECEIVED_UPDATE_CONTENT';
export const ERROR_UPDATE_CONTENT = 'ERROR_UPDATE_CONTENT';
export const CLEAR_UPDATE_CONTENT = 'CLEAR_UPDATE_CONTENT';

// update Content
export const REQUEST_DELETE_CONTENT = 'REQUEST_DELETE_CONTENT';
export const RECEIVED_DELETE_CONTENT = 'RECEIVED_DELETE_CONTENT';
export const ERROR_DELETE_CONTENT = 'ERROR_DELETE_CONTENT';
export const CLEAR_DELETE_CONTENT = 'CLEAR_DELETE_CONTENT';

//get package
export const REQUEST_PACKAGE = 'REQUEST_PACKAGE';
export const RECEIVED_PACKAGE = 'RECEIVED_PACKAGE';
export const ERROR_PACKAGE = 'ERROR_PACKAGE';
export const CLEAR_PACKAGE_DATA = 'CLEAR_PACKAGE_DATA';

//get package codes
export const REQUEST_PACKAGE_CODES = 'REQUEST_PACKAGE_CODES';
export const RECEIVED_PACKAGE_CODES = 'RECEIVED_PACKAGE_CODES';
export const ERROR_PACKAGE_CODES = 'ERROR_PACKAGE_CODES';

//add package
export const REQUEST_CREATE_PACKAGE = 'REQUEST_CREATE_PACKAGE';
export const RECEIVED_CREATE_PACKAGE = 'RECEIVED_CREATE_PACKAGE';
export const ERROR_CREATE_PACKAGE = 'ERROR_CREATE_PACKAGE';
export const CLEAR_CREATE_PACKAGE_DATA = 'CLEAR_CREATE_PACKAGE_DATA';

//update package
export const REQUEST_UPDATE_PACKAGE = 'REQUEST_UPDATE_PACKAGE';
export const RECEIVED_UPDATE_PACKAGE = 'RECEIVED_UPDATE_PACKAGE';
export const ERROR_UPDATE_PACKAGE = 'ERROR_UPDATE_PACKAGE';
export const CLEAR_UPDATE_PACKAGE_DATA = 'CLEAR_UPDATE_PACKAGE_DATA';

//delete package
export const REQUEST_DELETE_PACKAGE = 'REQUEST_DELETE_PACKAGE';
export const RECEIVED_DELETE_PACKAGE = 'RECEIVED_DELETE_PACKAGE';
export const ERROR_DELETE_PACKAGE = 'ERROR_DELETE_PACKAGE';
export const CLEAR_DELETE_PACKAGE_DATA = 'CLEAR_DELETE_PACKAGE_DATA';

//bulk upload
export const REQUEST_BULK_UPLOAD = 'REQUEST_BULK_UPLOAD';
export const RECEIVED_BULK_UPLOAD = 'RECEIVED_BULK_UPLOAD';
export const ERROR_BULK_UPLOAD = 'ERROR_BULK_UPLOAD';
export const CLEAR_BULK_UPLOAD = 'CLEAR_BULK_UPLOAD';

//assign content
export const SELECT_CONTENT_FOR_ASSIGN = 'SELECT_CONTENT_FOR_ASSIGN';
export const ADD_CONTENT_TO_ANOTHER_TOPIC = 'ADD_CONTENT_TO_ANOTHER_TOPIC';
export const REMOVE_CONTENT_FROM_ANOTHER_TOPIC = 'REMOVE_CONTENT_FROM_ANOTHER_TOPIC';
export const CHANGE_CONTENT_PRIORITY = 'CHANGE_CONTENT_PRIORITY';
export const CLEAR_SELECT_CONTENT_FOR_ASSIGN_DATA = 'CLEAR_SELECT_CONTENT_FOR_ASSIGN_DATA';
export const CLEAR_SELECTED_CONTENT = 'CLEAR_SELECTED_CONTENT';

//get all users data
export const REQUEST_ALL_USERS_DATA = 'REQUEST_ALL_USERS_DATA';
export const RECEIVED_ALL_USERS_DATA = 'RECEIVED_ALL_USERS_DATA';
export const ERROR_ALL_USERS_DATA = 'ERROR_ALL_USERS_DATA';
export const CLEAR_ALL_USERS_DATA = 'CLEAR_ALL_USERS_DATA';

// Bulk user upload
export const REQUEST_SAVE_BULK_USERS = 'REQUEST_SAVE_BULK_USERS';
export const RECEIVED_SAVE_BULK_USERS = 'RECEIVED_SAVE_BULK_USERS';
export const ERROR_SAVE_BULK_USERS = 'ERROR_SAVE_BULK_USERS';
export const REQUEST_BULK_UPLOAD_TRANSACTIONS = 'REQUEST_BULK_UPLOAD_TRANSACTIONS';
export const RECEIVED_BULK_UPLOAD_TRANSACTIONS = 'RECEIVED_BULK_UPLOAD_TRANSACTIONS';
export const ERROR_BULK_UPLOAD_TRANSACTIONS = 'ERROR_BULK_UPLOAD_TRANSACTIONS';
export const REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS = 'REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS';
export const RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS = 'RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS';
export const ERROR_BULK_UPLOAD_TRANSACTION_DETAILS = 'ERROR_BULK_UPLOAD_TRANSACTION_DETAILS';
export const CLEAR_BULK_USERS_TRANSACTION_DATA = "CLEAR_BULK_USERS_TRANSACTION_DATA";


// Bulk user Admin
export const REQUEST_SAVE_BULK_ADMINS = 'REQUEST_SAVE_BULK_ADMINS';
export const RECEIVED_SAVE_BULK_ADMINS = 'RECEIVED_SAVE_BULK_ADMINS';
export const ERROR_SAVE_BULK_ADMINS = 'ERROR_SAVE_BULK_ADMINS';
export const REQUEST_BULK_UPLOAD_TRANSACTIONS_ADMINS = 'REQUEST_BULK_UPLOAD_TRANSACTIONS_ADMINS';
export const RECEIVED_BULK_UPLOAD_TRANSACTIONS_ADMINS = 'RECEIVED_BULK_UPLOAD_TRANSACTIONS_ADMINS';
export const ERROR_BULK_UPLOAD_TRANSACTIONS_ADMINS = 'ERROR_BULK_UPLOAD_TRANSACTIONS_ADMINS';
export const REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS = 'REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS';
export const RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS = 'RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS';
export const ERROR_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS = 'ERROR_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS';
export const CLEAR_BULK_ADMINS_TRANSACTION_DATA = "CLEAR_BULK_ADMINS_TRANSACTION_DATA";



//get single user data
export const REQUEST_USER_BYID_DATA = 'REQUEST_USER_BYID_DATA';
export const RECEIVED_USER_BYID_DATA = 'RECEIVED_USER_BYID_DATA';
export const ERROR_USER_BYID_DATA = 'ERROR_USER_BYID_DATA';
export const CLEAR_USER_BYID_DATA = 'CLEAR_USER_BYID_DATA';

//add user
export const REQUEST_ADD_USER = 'REQUEST_ADD_USER';
export const RECEIVED_ADD_USER = 'RECEIVED_ADD_USER';
export const ERROR_ADD_USER = 'ERROR_ADD_USER';
export const CLEAR_ADD_USER = 'CLEAR_ADD_USER';

//delete user
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const RECEIVED_DELETE_USER = 'RECEIVED_DELETE_USER';
export const ERROR_DELETE_USER = 'ERROR_DELETE_USER';
export const CLEAR_DELETE_USER = 'CLEAR_DELETE_USER';

//update user
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const RECEIVED_UPDATE_USER = 'RECEIVED_UPDATE_USER';
export const ERROR_UPDATE_USER = 'ERROR_UPDATE_USER';
export const CLEAR_UPDATE_USER = 'CLEAR_UPDATE_USER';

//get transactins
export const REQUEST_TRANSACTIONS_INFO = 'REQUEST_TRANSACTIONS_INFO';
export const RECEIVED_TRANSACTIONS_INFO = 'RECEIVED_TRANSACTIONS_INFO';
export const ERROR_TRANSACTIONS_INFO = 'ERROR_TRANSACTIONS_INFO';
export const CLEAR_TRANSACTIONS_INFO = 'CLEAR_TRANSACTIONS_INFO';

//assign package to user
export const REQUEST_ASSIGN_PACKAGE_TO_USER = 'REQUEST_ASSIGN_PACKAGE_TO_USER';
export const RECEIVED_ASSIGN_PACKAGE_TO_USER = 'RECEIVED_ASSIGN_PACKAGE_TO_USER';
export const ERROR_ASSIGN_PACKAGE_TO_USER = 'ERROR_ASSIGN_PACKAGE_TO_USER';
export const CLEAR_ASSIGN_PACKAGE_TO_USER = 'CLEAR_ASSIGN_PACKAGE_TO_USER';

//update user package
export const REQUEST_UPDATE_USER_PACKAGE = 'REQUEST_UPDATE_USER_PACKAGE';
export const RECEIVED_UPDATE_USER_PACKAGE = 'RECEIVED_UPDATE_USER_PACKAGE';
export const ERROR_UPDATE_USER_PACKAGE = 'ERROR_UPDATE_USER_PACKAGE';
export const CLEAR_UPDATE_USER_PACKAGE = 'CLEAR_UPDATE_USER_PACKAGE';

//request package for assign
export const REQUEST_PACKAGES_FOR_ASSIGN = 'REQUEST_PACKAGES_FOR_ASSIGN';
export const RECEIVED_PACKAGES_FOR_ASSIGN = 'RECEIVED_PACKAGES_FOR_ASSIGN';
export const ERROR_PACKAGES_FOR_ASSIGN = 'ERROR_PACKAGES_FOR_ASSIGN';
export const CLEAR_PACKAGES_FOR_ASSIGN = 'CLEAR_PACKAGES_FOR_ASSIGN';

//request user assigned packages
export const REQUEST_USER_ASSIGNED_PACKAGES = 'REQUEST_USER_ASSIGNED_PACKAGES';
export const RECEIVED_USER_ASSIGNED_PACKAGES = 'RECEIVED_USER_ASSIGNED_PACKAGES';
export const ERROR_USER_ASSIGNED_PACKAGES = 'ERROR_USER_ASSIGNED_PACKAGES';
export const CLEAR_USER_ASSIGNED_PACKAGES = 'CLEAR_USER_ASSIGNED_PACKAGES';

// add question
export const REQUEST_ADD_QUESTION = 'REQUEST_ADD_QUESTION ';
export const RECIVED_ADD_QUESTION = ' RECIVED_ADD_QUESTION';
export const ERROR_ADD_QUESTION = 'ERROR_ADD_QUESTION';
export const CLEAR_ADD_QUESTION = 'CLEAR_ADD_QUESTION';

//fetch question
export const REQUEST_FETCH_QUESTION = 'REQUEST_FETCH_QUESTION';
export const RECIVED_FETCH_QUESTION = 'RECIVED_FETCH_QUESTION';
export const ERROR_FETCH_QUESTION = 'ERROR_FETCH_QUESTION';
export const CLEAR_FETCH_QUESTION = 'CLEAR_FETCH_QUESTION ';
export const CLEAR_SEARCH_QUESTION = 'CLEAR_SEARCH_QUESTION ';

// VIDEO fetch question
export const REQUEST_VIDEO_FETCH_QUESTION = 'REQUEST_VIDEO_FETCH_QUESTION';
export const RECIVED_VIDEO_FETCH_QUESTION = 'RECIVED_VIDEO_FETCH_QUESTION';
export const ACTIVE_VIDEO_LINKING = 'ACTIVE_VIDEO_LINKING';
export const SELECTED_VIDEO_LINKING_QUESTION = 'SELECTED_VIDEO_LINKING_QUESTION';
export const SAVE_VIDEO_LINKING_QUESTION = 'SAVE_VIDEO_LINKING_QUESTION';
export const FILTER_VIDEO_LINKING_QUESTION = 'FILTER_VIDEO_LINKING_QUESTION';

export const ERROR_VIDEO_FETCH_QUESTION = 'ERROR_VIDEO_FETCH_QUESTION';
export const CLEAR_VIDEO_FETCH_QUESTION = 'CLEAR_VIDEO_FETCH_QUESTION ';
export const CLEAR_VIDEO_SEARCH_QUESTION = 'CLEAR_VIDEO_SEARCH_QUESTION ';

//edit question

export const REQUEST_EDIT_QUESTION = 'REQUEST_EDIT_QUESTION';
export const RECEIVED_EDIT_QUESTION = 'RECEIVED_EDIT_QUESTION';
export const ERROR_EDIT_QUESTION = 'ERROR_EDIT_QUESTION';
export const CLEAR_EDIT_QUESTION = 'CLEAR_EDIT_QUESTION';
export const GET_EDIT_QUESTION_DETAILS = 'GET_EDIT_QUESTION_DETAILS';
export const CLEAR_GET_QUESTION = 'CLEAR_GET_QUESTION';

//tags for Boards
export const REQUEST_TAGS = 'REQUEST_TAGS';
export const RECEIVED_TAGS = 'RECEIVED_TAGS';
export const ERROR_TAGS = 'ERROR_TAGS';
export const CLEAR_TAGS = 'CLEAR_TAGS';

//alternate page

export const SET_LANGUAGE_DETAILS = 'SET_LANGUAGE_DETAILS';
export const REMOVE_LANGUAGE_DETAILS = 'REMOVE_LANGUAGE_DETAILS';
export const DELETE_LANGUAGE_COMPONENT = 'DELETE_LANGUAGE_COMPONENT';

//question bank grade Details

export const REQUEST_GET_GRADE_DETAILS = 'REQUEST_GET_GRADE_DETAILS';
export const RECEIVED_GET_GRADE_DETAILS = 'RECEIVED_GET_GRADE_DETAILS';
export const ERROR_GET_GRADE_DETAILS = 'ERROR_GET_GRADE_DETAILS';
export const CLEAR_GET_GRADE_DETAILS = 'CLEAR_GET_GRADE_DETAILS';

//delete question
export const REQUEST_DELETE_QUESTION = 'REQUEST_DELETE_QUESTION';
export const RECEIVED_DELETE_QUESTION = 'RECEIVED_DELETE_QUESTION';
export const ERROR_DELETE_QUESTION = 'ERROR_DELETE_QUESTION';
export const CLEAR_DELETE_QUESTION = 'CLEAR_DELETE_QUESTION';

export const SELECTED_QUESTION_TYPE = 'SELECTED_QUESTION_TYPE';
export const SELECTED_SKILL_TYPE = 'SELECTED_SKILL_TYPE';
export const SELECTED_DIFFICULTY_TYPE = 'SELECTED_DIFFICULTY_TYPE';
export const SELECTED_STATUS_TYPE = 'SELECTED_STATUS_TYPE';
export const SELECTED_TAGS_TYPE = 'SELECTED_TAGS_TYPE';
export const CLEAR_QUESTION_FILTERS = 'CLEAR_QUESTION_FILTERS';

//Checked Question Tag

export const SELECTED_QUESTION_FOR_TEST = 'SELECTED_QUESTION_FOR_TEST';
export const CLEAR_QUESTION_FOR_TEST = 'CLEAR_QUESTION_FOR_TEST';
export const CREATE_DATA_FOR_TEST = 'CREATE_DATA_FOR_TEST';
export const ERROR_DATA_FOR_TEST = 'ERROR_DATA_FOR_TEST';

//create quiz

export const REQUEST_CREATE_TEST = 'REQUEST_CREATE_TEST';
export const RECEIVED_CREATE_TEST = 'RECEIVED_CREATE_TEST';
export const ERROR_CREATE_TEST = 'ERROR_CREATE_TEST';
export const CLEAR_CREATE_TEST = 'CLEAR_CREATE_TEST';

//Image urls
export const REQUEST_GET_IMAGE_URL = 'REQUEST_GET_IMAGE_URL';
export const RECEIVED_GET_IMAGE_URL = 'RECEIVED_GET_IMAGE_URL';
export const ERROR_GET_IMAGE_URL = 'ERROR_GET_IMAGE_URL';
export const CLEAR_GET_IMAGE_URL = 'CLEAR_GET_IMAGE_URL';

//Video urls
export const REQUEST_GET_VIDEO_URL = 'REQUEST_GET_VIDEO_URL';
export const RECEIVED_GET__VIDEO_URL = 'RECEIVED_GET__VIDEO_URL';
export const ERROR_GET_VIDEO_URL = 'ERROR_GET_VIDEO_URL';
export const CLEAR_GET__VIDEO_URL = 'CLEAR_GET__VIDEO_URL';

//FetchTest

export const REQUEST_GET_EXAMINATION_DETAILS = 'REQUEST_GET_EXAMINATION_DETAILS';
export const RECEIVED_GET_EXAMINATION_DETAILS = 'RECEIVED_GET_EXAMINATION_DETAILS';
export const ERROR_GET_EXAMINATION_DETAILS = 'ERROR_GET_EXAMINATION_DETAILS';
export const CLEAR_GET_EXAMINATION_DETAILS = 'CLEAR_GET_FETCH_TEST_DETAILS';

//FetchTest ID & Name list

export const REQUEST_GET_EXAMS_ID_NAME_LIST = 'REQUEST_GET_EXAMS_ID_NAME_LIST';
export const RECEIVED_GET_EXAMS_ID_NAME_LIST = 'RECEIVED_GET_EXAMS_ID_NAME_LIST';
export const ERROR_GET_EXAMS_ID_NAME_LIST = 'ERROR_GET_EXAMS_ID_NAME_LIST';
export const CLEAR_GET_EXAMS_ID_NAME_LIST = 'CLEAR_GET_EXAMS_ID_NAME_LIST';

//Fetch Exam result report

export const REQUEST_GET_EXAM_RESULT_REPORT = 'REQUEST_GET_EXAM_RESULT_REPORT';
export const RECEIVED_GET_EXAM_RESULT_REPORT = 'RECEIVED_GET_EXAM_RESULT_REPORT';
export const ERROR_GET_EXAM_RESULT_REPORT = 'ERROR_GET_EXAM_RESULT_REPORT';
export const CLEAR_GET_EXAM_RESULT_REPORT = 'CLEAR_GET_EXAM_RESULT_REPORT';

//Fetch Exam result

export const REQUEST_GET_EXAM_RESULT = 'REQUEST_GET_EXAM_RESULT';
export const RECEIVED_GET_EXAM_RESULT = 'RECEIVED_GET_EXAM_RESULT';
export const ERROR_GET_EXAM_RESULT = 'ERROR_GET_EXAM_RESULT';
export const CLEAR_GET_EXAM_RESULT = 'CLEAR_GET_EXAM_RESULT';

//EDIT TEST
export const REQUEST_EDIT_EXAMINATION_DETAILS = 'REQUEST_EDIT_EXAMINATION_DETAILS';
export const RECEIVED_EDIT_EXAMINATION_DETAILS = 'RECEIVED_EDIT_EXAMINATION_DETAILS';
export const ERROR_EDIT_EXAMINATION_DETAILS = 'ERROR_EDIT_EXAMINATION_DETAILS';
export const CLEAR_EDIT_EXAMINATION_DETAILS = 'CLEAR_GET_EDIT_EXAMINATION_DETAILS';

//delete

export const REQUEST_DELETE_EXAMINATION_DETAILS = 'REQUEST_DELETE_EXAMINATION_DETAILS';
export const RECEIVED_DELETE_EXAMINATION_DETAILS = 'RECEIVED_DELETE_EXAMINATION_DETAILS';
export const ERROR_DELETE_EXAMINATION_DETAILS = 'ERROR_DELETE_EXAMINATION_DETAILS';
export const CLEAR_DELETE_EXAMINATION_DETAILS = 'CLEAR_DELETE_EXAMINATION_DETAILS';
//single
export const REQUEST_SINGLE_GET_EXAMINATION_DETAILS = 'REQUEST_SINGLE_GET_EXAMINATION_DETAILS';
export const RECEIVED_SINGLE_GET_EXAMINATION_DETAILS = 'RECEIVED_SINGLE_GET_EXAMINATION_DETAILS';
export const ERROR_SINGLE_GET_EXAMINATION_DETAILS = 'ERROR_SINGLE_GET_EXAMINATION_DETAILS';
export const CLEAR_SINGLE_GET_EXAMINATION_DETAILS = 'CLEAR_SINGLE_GET_FETCH_TEST_DETAILS';

//create Mock Test
export const REQUEST_CREATE_TEMPLATE_DATA = 'REQUEST_CREATE_TEMPLATE_DATA';
export const RECEIVED_CREATE_TEMPLATE_DATA = 'RECEIVED_CREATE_TEMPLATE_DATA';
export const ERROR_CREATE_TEMPLATE_DATA = 'ERROR_CREATE_TEMPLATE_DATA';
export const CLEAR_CREATE_TEMPLATE_DATA = 'CLEAR_CREATE_TEMPLATE_DATA';
export const ERROR_CREATE_TEMPLATE = 'ERROR_CREATE_TEMPLATE';

//

export const SELECT_QUESTION_TYPE_FOR_TEMPLATE = 'SELECT_QUESTION_TYPE_FOR_TEMPLATE';
export const SELECT_CHAPTER_DATA_FOR_TEMPLATE = 'SELECT_CHAPTER_DATA__FOR_TEMPLATE';
export const SELECT_SUBJECT_DATA_FOR_TEMPLATE = 'SELECT_SUBJECT_DATA__FOR_TEMPLATE';
export const SELECT_MOCK_TEMPLATE_DETAILS = 'SELECT_MOCK_TEMPLATE_DETAILS';

//get Template

export const REQUEST_GET_TEMPLATE_DETAILS = 'REQUEST_GET_TEMPLATE_DETAILS';
export const RECEIVED_GET_TEMPLATE_DETAILS = 'RECEIVED_GET_TEMPLATE_DETAILS';
export const ERROR_GET_TEMPLATE_DETAILS = 'ERROR_GET_TEMPLATE_DETAILS';
export const CLEAR_GET_TEMPLATE_DETAILS = 'CLEAR_GET_TEMPLATE_DETAILS';

//get SIngle Template

export const REQUEST_GET_SINGLE_TEMPLATE_DETAILS = 'REQUEST_GET_SINGLE_TEMPLATE_DETAILS';
export const RECEIVED_GET_SINGLE_TEMPLATE_DETAILS = 'RECEIVED_GET_SINGLE_TEMPLATE_DETAILS';
export const ERROR_GET_SINGLE_TEMPLATE_DETAILS = 'ERROR_GET_SINGLE_TEMPLATE_DETAILS';
export const CLEAR_GET_SINGLE_TEMPLATE_DETAILS = 'CLEAR_GET_SINGLE_TEMPLATE_DETAILS';

//

export const GET_TEMPLATE_TYPE_DETAILS = 'GET_TEMPLATE_TYPE_DETAILS';

//delete Template Details
export const REQUEST_DELETE_TEMPLATE_DETAILS = 'REQUEST_DELETE_TEMPLATE_DETAILS';
export const RECEIVED_DELETE_TEMPLATE_DETAILS = 'RECEIVED_DELETE_TEMPLATE_DETAILS';
export const ERROR_DELETE_TEMPLATE_DETAILS = 'ERROR_DELETE_TEMPLATE_DETAILS';
export const CLEAR_DELETE_TEMPLATE_DETAILS = 'CLEAR_DELETE_TEMPLATE_DETAILS';

//edit template

export const REQUEST_EDIT_TEMPLATE_DETAILS = 'REQUEST_EDIT_TEMPLATE_DETAILS';
export const RECEIVED_EDIT_TEMPLATE_DETAILS = 'RECEIVED_EDIT_TEMPLATE_DETAILS';
export const ERROR_EDIT_TEMPLATE_DETAILS = 'ERROR_EDIT_TEMPLATE_DETAILS';
export const CLEAR_EDIT_TEMPLATE_DETAILS = 'CLEAR_EDIT_TEMPLATE_DETAILS';

export const GET_EDIT_TEMPLATE_DETAILS = 'GET_EDIT_TEMPLATE_DETAILS';
export const CLEAR_GET_EDIT_TEMPLATE_DETAILS = 'CLEAR_GET_EDIT_TEMPLATE_DETAILS';
//edit quiz and Assessment details
export const REQUEST_EDIT_QUIZ_DETAILS = 'REQUEST_EDIT_QUIZ_DETAILS';
export const RECEIVED_EDIT_QUIZ_DETAILS = 'RECEIVED_EDIT_QUIZ_DETAILS';
export const ERROR_EDIT_QUIZ_DETAILS = 'ERROR_EDIT_QUIZ_DETAILS';
export const CLEAR_EDIT_QUIZ_DETAILS = 'CLEAR_EDIT_QUIZ_DETAILS';
export const GET_EDIT_QUIZ_DETAILS = 'GET_EDIT_QUIZ_DETAILS';
export const CLEAR_GET_EDIT_QUIZ_DETAILS = 'CLEAR_GET_EDIT_QUIZ_DETAILS';

export const ERROR_DUPLICATE_QUESTION = 'ERROR_DUPLICATE_QUESTION';
export const EDIT_QUIZ_CREATE_DATA = 'EDIT_QUIZ_CREATE_DATA';

export const CLEAR_DUPLICATE_ERROR_QUESTION = 'CLEAR_DUPLICATE_ERROR_QUESTION';

//edit mockTest

export const REQUEST_EDIT_MOCK_TEST_DETAILS = 'REQUEST_EDIT_MOCK_TEST_DETAILS';
export const RECEIVED_EDIT_MOCK_TEST_DETAILS = 'RECEIVED_EDIT_MOCK_TEST_DETAILS';
export const ERROR_EDIT_MOCK_TEST_DETAILS = 'ERROR_EDIT_MOCK_TEST_DETAILS';
export const CLEAR_EDIT_MOCK_TEST_DETAILS = 'CLEAR_EDIT_MOCK_TEST_DETAILS';
export const CLEAR_GET_MOCK_TEST_DETAILS = 'CLEAR_GET_MOCK_TEST_DETAILS';
export const GET_EDIT_MOCK_TEST_DETAILS = 'GET_EDIT_MOCK_TEST_DETAILS';

//contact us info

export const REQUEST_GET_CONTACT_US_INFO = 'REQUEST_GET_CONTACT_US_INFO';
export const RECEIVED_GET_CONTACT_US_INFO = 'RECEIVED_GET_CONTACT_US_INFO';
export const ERROR_GET_CONTACT_US_INFO = 'ERROR_GET_CONTACT_US_INFO';
export const CLEAR_GET_CONTACT_US_INFO = 'CLEAR_GET_CONTACT_US_INFO';

//templateView
export const GET_TEMPLATE_DETAILS_FOR_VIEW = 'GET_TEMPLATE_DETAILS_FOR_VIEW';
export const CLEAR_GET_TEMPLATE_DETAILS_FOR_VIEW = 'CLEAR_GET_TEMPLATE_DETAILS_FOR_VIEW';

//create new user

export const REQUEST_CREATE_NEW_USER = 'REQUEST_CREATE_NEW_USER';
export const RECEIVED_CREATE_NEW_USER = 'RECEIVED_CREATE_NEW_USER';
export const ERROR_CREATE_NEW_USER = 'ERROR_CREATE_NEW_USER';
export const CLEAR_CREATE_NEW_USER = 'CLEAR_CREATE_TEMPLATE_DATA';
export const THROW_ERROR_MESSAGE = 'THROW_ERROR_MESSAGE';

// //create new admin user
// export const REQUEST_CREATE_NEW_ADMIN_USER = 'REQUEST_CREATE_NEW_USER';
// export const RECEIVED_CREATE_NEW_ADMIN_USER = 'RECEIVED_CREATE_NEW_USER';
// export const ERROR_CREATE_NEW_ADMIN_USER = 'ERROR_CREATE_NEW_USER';
// export const CLEAR_CREATE_NEW_ADMIN_USER = 'CLEAR_CREATE_TEMPLATE_DATA';
// export const THROW_ADMIN_ERROR_MESSAGE = 'THROW_ERROR_MESSAGE';

//file size types

export const VIDEO_FILE_SIZE = 200000;
export const PDF_FILE_SIZE = 5000;
export const PPT_FILE_SIZE = 10000;
export const IMAGE_SIZE = 200;

//Meluha board id

export const MELUHA_BOARD_ID = '498fc658-d02f-4fd1-9181-0fb3912a0046';

// Role - permissions

export const GET_PERMISSIONS_REQUESTED = 'GET_PERMISSIONS_REQUESTED';
export const GET_PERMISSIONS_SUCCEEDED = 'GET_PERMISSIONS_SUCCEEDED';
export const GET_PERMISSIONS_FAILED = 'GET_PERMISSIONS_FAILED';

// Get Roles
export const GET_ROLES_REQUESTED = 'GET_ROLES_REQUESTED';
export const GET_ROLES_SUCCEEDED = 'GET_ROLES_SUCCEEDED';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const ADD_ROLES_REQUESTED = 'ADD_ROLES_REQUESTED';
export const ADD_ROLES_SUCCEEDED = 'ADD_ROLES_SUCCEEDED';
export const ADD_ROLES_FAILED = 'ADD_ROLES_FAILED';
export const UPDATE_ADD_ROLES = 'UPDATE_ADD_ROLES';

export const UPDATE_ROLES_REQUESTED = 'UPDATE_ROLES_REQUESTED';
export const UPDATE_ROLES_SUCCEEDED = 'UPDATE_ROLES_SUCCEEDED';
export const UPDATE_ROLES_FAILED = 'UPDATE_ROLES_FAILED';

// User Admin
export const ADD_ADMIN_USER_REQUESTED = 'ADD_ADMIN_USER_REQUESTED';
export const ADD_ADMIN_USER_SUCCEEDED = 'ADD_ADMIN_USER_SUCCEEDED';
export const ADD_ADMIN_USER_FAILED = 'ADD_ADMIN_USER_FAILED';
export const ERROR_CREATE_NEW_ADMIN_USER = 'ERROR_CREATE_NEW_ADMIN_USER';

// Update Admin User
export const UPDATE_ADMIN_USER_REQUESTED = 'UPDATE_ADMIN_USER_REQUESTED';
export const UPDATE_ADMIN_USER_SUCCEEDED = 'UPDATE_ADMIN_USER_SUCCEEDED';
export const UPDATE_ADMIN_USER_FAILED = 'UPDATE_ADMIN_USER_FAILED';
export const ERROR_UPDATE_ADMIN_USER = 'ERROR_UPDATE_ADMIN_USER';

// LoggedIn User Permission
export const GET_CURRENT_USER_PERMS_REQUESTED = 'GET_CURRENT_USER_PERMS_REQUESTED';
export const GET_CURRENT_USER_PERMS_SUCCEEDED = 'GET_CURRENT_USER_PERMS_SUCCEEDED';
export const GET_CURRENT_USER_PERMS_FAILED = 'GET_CURRENT_USER_PERMS_FAILED';

// Get all admin users
export const GET_ADMIN_USER_LISTS_REQUESTED = 'GET_ADMIN_USER_LISTS_REQUESTED';
export const GET_ADMIN_USER_LISTS_SUCCEEDED = 'GET_ADMIN_USER_LISTS_SUCCEEDED';
export const GET_ADMIN_USER_LISTS_FAILED = 'GET_ADMIN_USER_LISTS_FAILED';

// branch master data
export const REQUEST_BRANCH = 'REQUEST_BRANCH';
export const RECEIVED_BRANCH = 'RECEIVED_BRANCH';
export const ERROR_BRANCH = 'ERROR_BRANCH';
export const CLEAR_BRANCH = 'CLEAR_BRANCH';


export const REQUEST_BRANCH_DATA = 'REQUEST_BRANCH_DATA';
export const RECEIVED_BRANCH_DATA = 'RECEIVED_BRANCH_DATA';
export const ERROR_BRANCH_DATA = 'ERROR_BRANCH_DATA';
export const CLEAR_BRANCH_DATA = 'CLEAR_BRANCH_DATA';


export const SELECTED_COURSE_FROM_BRANCH = 'SELECTED_COURSE_FROM_BRANCH';
export const SELECT_SECTION_FROM_COURSE = 'SELECT_SECTION_FROM_COURSE';

//update branch master data
export const REQUEST_UPDATE_BRANCH_DATA = 'REQUEST_UPDATE_BRANCH_DATA';
export const RECEIVED_UPDATE_BRANCH_DATA = 'RECEIVED_UPDATE_BRANCH_DATA';
export const ERROR_UPDATE_BRANCH_DATA = 'ERROR_UPDATE_BRANCH_DATA';
export const CLEAR_UPDATE_BRANCH_DATA = 'CLEAR_UPDATE_BRANCH_DATA_DATA';


//Meluha board id
export const MELUHA_BRANCH_ID = '498fc658-d02f-4fd1-9181-0fb3912a0046';


export const REQUEST_ADMISSION_DATA = 'REQUEST_ADMISSION_DATA';
export const RECEIVED_ADMISSION_DATA = 'RECEIVED_ADMISSION_DATA';
export const ERROR_ADMISSION_DATA = 'ERROR_ADMISSION_DATA';
export const CLEAR_ADMISSION_DATA = 'CLEAR_ADMISSION_DATA';



//FetchTest

export const REQUEST_GET_EXAMINATION_BY_FILTER = 'REQUEST_GET_EXAMINATION_BY_FILTER';
export const RECEIVED_GET_EXAMINATION_BY_FILTER = 'RECEIVED_GET_EXAMINATION_BY_FILTER';
export const ERROR_GET_EXAMINATION_BY_FILTER = 'ERROR_GET_EXAMINATION_BY_FILTER';
export const CLEAR_GET_EXAMINATION_BY_FILTER = 'CLEAR_GET_FETCH_TEST_BY_FILTER';



export const EXPORT_CONTENT_OF_SECLECTED_GRADE = 'EXPORT_CONTENT_FOR_SECLECTED_GRADE';
// export const GET_ADMIN_USER_LISTS_SUCCEEDED = 'GET_ADMIN_USER_LISTS_SUCCEEDED';
// export const GET_ADMIN_USER_LISTS_FAILED = 'GET_ADMIN_USER_LISTS_FAILED';


export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'CLEAR_BEHAVIOUR_ANALYTICS';
export const ADD_SNACKBAR = 'ADD_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';



//video linking
export const REQUEST_LINKING_VIDEO = 'REQUEST_LINKING_VIDEO';
export const RECEIVED_LINKING_VIDEO = 'RECEIVED_LINKING_VIDEO';
export const ERROR_LINKING_VIDEO = 'ERROR_LINKING_VIDEO';
export const CLEAR_LINKING_VIDEO = 'CLEAR_LINKING_VIDEO';





//sssssssssssssssssssss
// Get Roles
export const SP_GET_ROLES_REQUESTED = 'SP_GET_ROLES_REQUESTED';
export const SP_GET_ROLES_SUCCEEDED = 'SP_GET_ROLES_SUCCEEDED';
export const SP_GET_ROLES_FAILED = 'SP_GET_ROLES_FAILED';


export const REQUEST_ORG = 'REQUEST_ORG';
export const RECEIVED_ORG = 'RECEIVED_ORG';
export const ERROR_ORG = 'ERROR_ORG';
export const CLEAR_ORG_DATA = 'CLEAR_ORG_DATA';


/* Yearly Planner */
export const REQUEST_PLANNER = "REQUEST_PLANNER";
export const RECEIVED_PLANNER = "RECEIVED_PLANNER";
export const ERROR_PLANNER = "ERROR_PLANNER";
export const CLEAR_PLANNER_DATA = "CLEAR_PLANNER_DATA";


/*Admission Course*/
export const REQUEST_GET_ALL_COURSE_DETAILS = "REQUEST_GET_ALL_COURSE_DETAILS"
export const RECIVED_GET_COURSE_DETAILS = "RECIVED_GET_COURSE_DETAILS"
export const ERROR_GET_COURSE_DETAILS = "ERROR_GET_COURSE_DETAILS"
export const CLEAR_ALL_COURSE_DETAILS = "CLEAR_ALL_COURSE_DETAILS"

export const GET_LANGUAGE = "GET_LANGUAGE"
export const SET_LANGUAGE = "SET_LANGUAGE"

