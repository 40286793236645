import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { BasicLazyParams } from '../../../utile';
import { AddIconInButtonFull, ArrowClockWise, ArrowLeftRight, MailIcon, Plus, RightMarkInCircle } from '../../svgIcons';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Button } from 'primereact/button';
import Service from '../../services';
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { getBoardsData } from '../../../store/actions';
import Authentication from '../../session';
import _ from 'lodash';
import LoadingComponent from '../../loadingComponent';


class CreateTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: BasicLazyParams,
            timeTableData: [],
            subjects: [],
            selectedTemplate: null,
            templates: this.props.templates || [],
            searchLevel: 0,
            isOpenCreateSubstitution: false,
            isOpenWarning: false,
            isTimeTableExistsInDB: false,
            timeTableId: null,
            warningTemplate: null,
            periodSubstitutionData: {}
        };
        this.service = new Service();
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                this.getStaffData();
                if (dropdownsData?.boardId?.length > 0 && dropdownsData?.classId?.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                        );
                    }
                }
            }
        );
        // if (this.state.dropdownsData) {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        if ((branchId !== '' && boardId !== '' && classId !== '' && sectionId !== '')) {
            this.getTimeTableIfExists(dropdownsData);
        } else {
            this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
        }
    };
    saveTimeTable = () => {
        const { timeTableData, selectedTemplate } = this.state;
        const { branchId, boardId, classId, sectionId, selectedbranchName, selectedBoardName, selectedClassName, selectedsectionName } = this.state.dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        const payload = {
            settings: this.state.settings,
            templateId: selectedTemplate,
            branchId,
            branchName: selectedbranchName,
            boardId,
            boardName: selectedBoardName,
            classId,
            className: selectedClassName,
            sectionId,
            sectionName: selectedsectionName,
            academicYear,
            timeTableData,
            users: this.state.users,
            orgId
        };
        this.service
            .post(`${baseUrlAdmin}/timetable/savetimetable`, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.toast.show({ severity: 'success', summary: 'Time Table has been created succesfully', detail: '', life: 3000 });
                    this.getTimeTableIfExists({ branchId, boardId, classId, sectionId });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            });
    };
    getTimeTableIfExists = async (dropdownsData) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        let url = `${baseUrlAdmin}/timetable/gettimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res && res.status && res.res.status) {
                if (res.res.data) {
                    const timeTableData = res.res.data;
                    this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true }, () => {
                        this.dynamicColumns(timeTableData.timeTableData)
                    });
                } else {
                    this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
                }
            }
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    }
    getSavedData = async () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/timetable/get-settings?academicYear=${academicYear}`;


        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false });
                const data = res?.res?.data;
                this.setState({ settings: data });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        console.log(this.state.dropdownsData);
        // let branchIds = Array.isArray(this.state?.dropdownsData?.branchId) ? this.state.dropdownsData.branchId.join(',') : '';
        // let boardIds = Array.isArray(this.state?.dropdownsData?.boardId) ? this.state.dropdownsData.boardId.join(',') : '';
        // let gradeIds = Array.isArray(this.state?.dropdownsData?.classId) ? this.state.dropdownsData.classId.join(',') : '';
        // let sectionIds = Array.isArray(this.state?.dropdownsData?.sectionId) ? this.state.dropdownsData.sectionId.join(',') : '';
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;

        let url;
        if (branchIds || boardIds || gradeIds || sectionIds) {
            url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;
        } else {
            url = `${baseUrlAdmin}/staff?academicYear=${academicYear}&limit=${10000}&page=${1}`;
        }

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                // console.log(res?.res?.data, 'res?.res?.datares?.res?.data');

                const users = res?.res?.data?.records;
                console.log(users);
                this.setState({
                    users: users,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    onChangeTemplate = (value) => {
        if (this.state.isTimeTableExistsInDB) {
            console.log(this.state.timeTableData);
            this.setState({ isOpenWarning: true, warningTemplate: value })
            return;
        }
        const selectedTemplateData = this.props.templates.filter((template) => template._id === value);
        if (!selectedTemplateData || !selectedTemplateData[0] || !selectedTemplateData[0].periods) return;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newTimeTableData = {
            ...this.state.timeTableData,
            TimeTable: daysOfWeek.map((day) => ({
                day: day,
                Periods: selectedTemplateData[0].periods
            }))
        };

        this.setState({
            timeTableData: newTimeTableData,
            selectedTemplate: value
        });
        this.dynamicColumns(newTimeTableData);
    };
    componentDidMount() {
        this.getSavedData();
    }
    openCreateSubstitution = (periodData, day) => {
        const updated = { ...periodData, Day: day, subjectName: periodData?.Subject?.subjectId, Teacher: periodData?.Teacher?.user_id };
        this.setState({ isOpenCreateSubstitution: true, periodSubstitutionData: updated }, () => this.onChangeTeacher(periodData?.Teacher?.user_id));
    };
    onChangeTeacher = (value) => {
        console.log(this.state.users);
        const selectedTeacher = this.state.users.find((teacher) => teacher.user_id === value);
        if (selectedTeacher) {
            console.log(selectedTeacher);

            this.setState({
                periodSubstitutionData: {
                    ...this.state.periodSubstitutionData,
                    Teacher: value
                },
                subjects: selectedTeacher.user_info.assignedSubjects || []
            });
        } else {
            console.log("Teacher not found");
            this.setState({
                subjects: []
            });
        }
    };

    getCurriculumData = () => {
        const { boardId, classId } = this.state.dropdownsData;
        const { groupId } = this.state;
        if (boardId && classId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service
                .get(url, true)
                .then((data) => {
                    console.log(data);
                    const etag = data && data.etag;
                    if (data && data.res && data.res.Item) {
                        // let sortedData = sortCurriculam(data.res.Item);
                        this.setState({
                            subjects: data.res.Item.subjects
                            // curriculumInfo: sortedData,
                            // selectedSubject: sortedData?.subjects[0],
                            // selectedSubjectIndex: 0,
                            // selectedChapter: sortedData?.subjects[0]?.chapters[0],
                            // selectedChapterIndex: 0,
                            // isLoading: false,
                            // etag
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable) return [];

        const columns = [];
        columns.push(<Column key="day" field="day" header={<div style={{ color: 'black' }}>Days/Periods</div>} align={'left'} body={(rowData) => <div style={{ color: 'black' }}>{rowData.day}</div>} />);

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={<div style={{ color: 'black' }}>{_.name}</div>}

                        body={(rowData) => {
                            const periodData = rowData.Periods[index];
                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="break-period" style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                            BREAK
                                        </div>
                                    </div>

                                );
                            }
                            return (
                                <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                    <div style={{ marginBottom: '20px' }} >
                                        <span
                                            data-pr-tooltip="Edit User"
                                            onClick={() => {
                                                this.openCreateSubstitution(periodData, rowData.day);
                                            }}
                                        >
                                            {periodData?.Teacher?.name ?
                                                <ArrowLeftRight width={16} height={16} color={'#000000'} />
                                                : <Plus width={16} height={16} color={'#000000'} />
                                            }
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal',
                                            color: periodData?.Teacher?.name ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Teacher?.name
                                            ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                            : ''}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal',
                                            color: periodData?.Subject?.subjectName ? 'black' : 'gray'
                                        }}
                                    >
                                        {periodData?.Subject?.subjectName
                                            ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                            : 'Not Assigned'}
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };
    handleTemplateChangeAcceptance = async () => {
        const url = `${baseUrlAdmin}/timetable/delTimeTable/${this.state.timeTableId}`
        try {
            const res = this.service.delete(url, true);
            if (res.status === 200) {
                this.toast.show({ severity: 'success', summary: 'Time Table has been deleted succesfully' });
            }
        } catch (err) {
            // console.log(err);
            this.toast.show({ severity: 'error', summary: 'Error while deleting timetable' });

        }

        this.setState({ isTimeTableExistsInDB: false, isOpenWarning: false }, () => this.onChangeTemplate(this.state.warningTemplate))
    }
    onAccept = () => {
        // const set = new Set();
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;
        const teacher = this.state.users.find((user) => user.user_id === this.state.periodSubstitutionData.Teacher);
        const subject = this.state.subjects.find((subject) => subject.subjectId === this.state.periodSubstitutionData.subjectName);
        if (!teacher || !subject) return this.toast.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Please select a teacher and subject',
            life: 3000,
        })
        const simplifiedSubject = {
            subjectName: subject.subjectName,
            subjectId: subject.subjectId,
            subjectCode: subject.subjectCode
        }
        const simplifiedTeacher = {
            user_id: teacher.user_id,
            username: teacher.user_info.username,
            name: teacher.user_info.name,
        };
        const x = this.state?.periodSubstitutionData;
        // const alreadyAssigned = false;
        for (let i = 0; i < teacher?.user_info?.myTimeTable?.length; i++) {
            let curr = teacher.user_info.myTimeTable[i];
            if (curr.boardId == boardIds && curr.sectionId == sectionIds && curr.branchId == branchIds && curr.classId == gradeIds) {
                continue;
            } else {
                for (let i = 0; i < curr?.periodList.length; i++) {
                    const each = curr.periodList[i];
                    if (each.periodId + each.Day === x.periodId + x.Day) {
                        // alreadyAssigned = true;
                        return this.toast.show({
                            severity: 'error',
                            summary: 'Already Assigned',
                            detail: `Already assigned in ${curr.className} ${curr.sectionName}`,
                            life: 3000,
                        });
                        // break;
                    }
                }

            }
        }
        // if(alreadyAssigned) return;
        const updatedPeriodSubstitutionData = { ...this.state.periodSubstitutionData, Teacher: simplifiedTeacher, Subject: simplifiedSubject };
        delete updatedPeriodSubstitutionData.subjectName;
        const updatedTimeTable = this.state.timeTableData.TimeTable.map((dayEntry) => {
            if (dayEntry.day === this.state.periodSubstitutionData.Day) {
                return {
                    ...dayEntry,
                    Periods: dayEntry.Periods.map((period) => {
                        if (period.periodId === updatedPeriodSubstitutionData.periodId) {
                            return { ...period, ...updatedPeriodSubstitutionData };
                        }
                        return period;
                    })
                };
            }
            return dayEntry;
        });

        const updatedTimeTableData = { ...this.state.timeTableData, TimeTable: updatedTimeTable };
        this.setState(
            {
                timeTableData: updatedTimeTableData,
                isOpenCreateSubstitution: false,
                periodSubstitutionData: {}
            },
            () => this.dynamicColumns(updatedTimeTableData)
        );
    };
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value });
    };
    render() {
        const { timeTableData, dropdownsData } = this.state;

        return (
            <div className="poppins24">
                <div className="col-12 flex justify-content-end align-items-center">
                    <Button onClick={() => this.saveTimeTable()} style={{ borderRadius: 10, backgroundColor: '#076EFF' }} className="flex align-items-center gap-1">
                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        <p className="text-xl">Save</p>
                    </Button>
                </div>

                <div className="grid m-2">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    <div className=" col-3 mx-1">
                        <ClassetDropdown
                            required={true}
                            label={'TimeTableTemplate'}
                            icon={<MailIcon height={16.5} width={19.5} />}
                            placeholder={'Select TimeTable Template'}
                            options={this.state.templates}
                            value={this.state.selectedTemplate}
                            onChange={(e) => {
                                this.onChangeTemplate(e.value)
                            }}
                            optionLabel="title"
                            optionValue="_id"
                            disabled={this.state.isTimeTableExistsInDB}
                        />
                    </div>
                </div>
                <div className="flex align-items-center gap-5 mx-5">
                    <div className="flex mt-1">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(0)} checked={this.state.searchLevel === 0} />
                        </div>

                        <div className="field ml-4" style={{ fontWeight: 'bolder', color: 'black', fontSize: '15px' }}>
                            Manual Creation
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton disabled={true} inputId="ingredient2" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div className="field ml-4" style={{ fontWeight: 'bolder', color: 'black', fontSize: '15px' }}>
                            Automatic Creation
                        </div>
                    </div>
                </div>
                {timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={timeTableData.TimeTable}
                        dataKey="id"
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        // resizableColumns
                        // onPage={this.onPage}
                        size='large'

                    >
                        {this.dynamicColumns(timeTableData)}
                    </DataTable>
                )}
                {timeTableData.TimeTable === null ||
                    !dropdownsData?.branchId ||
                    !dropdownsData?.boardId ||
                    !dropdownsData?.classId ||
                    !dropdownsData?.sectionId ? (
                    <p className="mx-5" style={{ color: 'black', fontSize: '18px', fontWeight: 'bolder' }}>
                        Please select all fields
                    </p>
                ) : null}

                <Dialog
                    visible={this.state.isOpenCreateSubstitution}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }} className="">
                                    {this.state.periodSubstitutionData?.name}
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }} className="">
                                    Day:{this.state.periodSubstitutionData?.Day}
                                </p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onAccept();
                                }}
                            >
                                <p className="formSubmitButtonText">Accept</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isOpenCreateSubstitution: false, remarks: '' })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isOpenCreateSubstitution: false })}
                >
                    <div className="flex flex-column">
                        <div className=" col-12">
                            <ClassetDropdown
                                required={true}
                                label={'Select Teacher'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Teacher'}
                                options={this.state.users}
                                value={this.state.periodSubstitutionData.Teacher}
                                onChange={(e) =>
                                    this.onChangeTeacher(e.target.value)
                                }
                                optionLabel="username"
                                optionValue="user_id"
                            />
                        </div>
                        <div className=" col-12">
                            <ClassetDropdown
                                required={true}
                                label={'Select Subject'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Subject'}
                                options={this.state.subjects}
                                value={this.state.periodSubstitutionData.subjectName}
                                onChange={(e) =>
                                    this.setState({
                                        periodSubstitutionData: {
                                            ...this.state.periodSubstitutionData,
                                            subjectName: e.target.value
                                        }
                                    })
                                }
                                optionLabel="subjectName"
                                optionValue="subjectId"
                            />
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isOpenWarning}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle />
                                </div>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.handleTemplateChangeAcceptance()
                                }}
                            >
                                <p className="formSubmitButtonText">Accept</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isOpenWarning: false })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isOpenCreateSubstitution: false })}
                >
                    <h1>Your forcefully changing template ?????</h1>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(CreateTimeTable)));
