import React from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import { connect } from 'react-redux';
import withRouter from './components/lib/withRouter';
import Authentication from './session';
import { ProgressSpinner } from 'primereact/progressspinner';
const AppMenu = (props) => {
    return (
        <div style={{ width: 'auto' }} className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                {props?.logoSquare?.storageLocation.length ? <img id="app-logo"
                    src={`${props?.logoSquare?.storageLocation ? props?.logoSquare?.storageLocation : ''}`}
                    className={props.menuMode === 'slim' ? 'nav-logo-width-slim' : 'nav-logo-width'}
                /> : (<div style={{ width: '50px', height: '40px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" ></div>)
                }
                <span className="app-name"></span>
            </Link>
            <div className="layout-menu-container">
                <AppSubmenu
                    items={props.model}
                    menuMode={props.menuMode}
                    parentMenuItemActive
                    menuActive={props.active}
                    mobileMenuActive={props.mobileMenuActive}
                    root
                    onMenuitemClick={props.onMenuitemClick}
                    onRootMenuitemClick={props.onRootMenuitemClick}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    logoSquare: state.orgData.logoSquare,
});
export default connect(mapStateToProps, {})(Authentication(withRouter(AppMenu)));

